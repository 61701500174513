import React, { ChangeEvent, Component } from 'react';

import {
  IAction,
  IAlert,
  IDepartment,
  IDepartmentSetting,
  IOrganization,
  IPatient,
  IProvider,
  IRoom,
  ISite,
  IStaffMember,
  IUser,
  IView,
} from '../../interfaces/interfaces';

import { canDo } from '../../utils/permissionCheck';

import Confetti from 'react-confetti';

import { Navigate } from 'react-router-dom';
import { getObjectFromGzipEncodedData } from '../../utils/Compression';
import { lockScreen } from '../../utils/LockScreen';
import { checkRefreshToken, getQueryVariable } from '../../utils/utils';
// import { getCookie } from "../../utils/utils";
// import _ from "lodash";
import axios from 'axios';
import { detect } from 'detect-browser';
import moment from 'moment-timezone';
import AppSearch from './components/AppSearch';

import { ChromePicker } from 'react-color';
// import ColorPicker from "./components/ColorPicker";

import LogRocket from 'logrocket';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

// import LoadingBar from "react-redux-loading-bar";
// Redux
import { connect } from 'react-redux';
import {
  fetchDepartment,
  fetchDistinctOrgActions,
  fetchLoggedInUser,
  fetchOrganizations,
  fetchRoomsByOrgId,
  fetchSites,
  logAction,
  logout,
} from '../../actions/adminActions';
import {
  fetchAllFsDepartments,
  fetchGroup,
  fetchSiblingDepts,
  fetchStaffLocations,
  fetchVisibleFsDepartments,
  setDeptHistory,
  updateGroup,
  updateSiblingDepts,
} from '../../actions/flowstationActions';
// import FsNotificationSnack from "../../utils/FsNotificationSnack";

import Notifications from './components/notification/Notifications';

import Draggable from 'react-draggable';

import InfoSnack from '../../utils/InfoSnack';
import SuccessSnack from '../../utils/snack/SuccessSnack';

// Components
import Navigation from './components/Navigation';
import CycleTimesCard from './components/reporting/CycleTimesCard';
import VisitsFinishedCard from './components/reporting/VisitsFinishedCard';
import RoomItem from './components/roomItem/RoomItem';
// import TrendingCycleTimesCard from "./components/reporting/TrendingCycleTimesCard";
import PatientsCard from './components/PatientsCard';
import PowerReport from './components/PowerReport';
import ResetPasswordDialog from './components/ResetPasswordDialog';

// Material
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import {
  createTheme,
  MuiThemeProvider,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import QuestionIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/MoreVert';
import NotificationIcon from '@material-ui/icons/Notifications';
import NotificationOffIcon from '@material-ui/icons/NotificationsOff';
import PaintIcon from '@material-ui/icons/Palette';
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from '@material-ui/core/Grid';
// import SettingsIcon from "@material-ui/icons/FilterList";
import Badge from '@material-ui/core/Badge';

import Drawer from '@material-ui/core/Drawer';
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import CloseButton from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import CloseIcon from "@material-ui/icons/Close";
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import { alpha } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit';
import Error from '@material-ui/icons/Error';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import HelpIcon from '@material-ui/icons/HelpOutline';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import PublishIcon from '@material-ui/icons/Share';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// import Slider from "@material-ui/core/Slider";
// import HistoryIcon from "@material-ui/icons/History";
// import RewindIcon from "@material-ui/icons/FastRewind";
import Container from '@material-ui/core/Container';
import Modal from '@material-ui/core/Modal';
import DarkModeIcon from '@material-ui/icons/Brightness4';
import LightModeIcon from '@material-ui/icons/Brightness7';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Images
import addAction from '../../images/addaction.png';
import addedActions from '../../images/addedactions.png';
import addPatient from '../../images/addpatient.png';
import aloneComparison from '../../images/alonecomparison.png';
import emptyPatientHold from '../../images/emptypatienthold.png';
import holdPatient from '../../images/holdpatient.png';
import removePatient from '../../images/removepatient.png';
import roomWithProvider from '../../images/roomProviderWithArrow.png';
import synctimesLogo from '../../images/SyncTimes_Transparent.png';
import synctimesLogoDev from '../../images/SyncTimes_Transparent_Dev.png';
// import departmentCardImage from "../../images/DepartmentCard.png";
// import roomWithProviderTime from "../../images/roomProviderWithArrowToTime.png";
// import SynctimesIcon from "../../images/synctimesicon.png";
import Chip from '@material-ui/core/Chip';
import filterIcon from '../../images/filter.png';
import filterIconHollow from '../../images/filterhollow.png';
import SynctimesIcon from '../../images/syncicon.png';
import loadingIcon from '../../images/synctimesicon.png';
// import Pup from "../../images/dogbg.jpg";

// import binolight from '../../images/binolight.png';
// import binodark from '../../images/binodark.png';

// Halloween
// import Halloween from "../../images/pumpkintop.png";
// import Halloweendark from "../../images/pumpkindark.png"

// Christmas
// import Christmaslight from "../../images/christmaslight.jpg";
// import Christmasdark from "../../images/christmasdark.jpg";

//@ts-ignore
import noSound from '../../sounds/static.mp3';

import {
  allowSignalR,
  // socket,
  connection,
} from '../../socket-manager';

import {
  API,
  // REACT_APP_API_SOCKETURL,
  REACT_APP_API_BASE_URL,
} from '../../apiconfig';

import ReactGA from 'react-ga';
import LocateStaffPopoverContainer from './components/locateStaff/LocateStaffPopoverContainer';
import { checkIfIsDesktopApp } from '../../util';
import { isEmpty } from 'lodash';
import { PrettyData } from '../../common';
// import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

// let blankSound = new Audio(noSound);

// import { GetHoliday } from "../../utils/Holidays";

const _ = require('lodash');

const { Howl } = require('howler');

var uniqid = require('uniqid');

let gaInitialized = false;

let metaData = require('../../metadata.json');

const Browser = detect();

var staffLocationTimerVar: any;

let mockedOrgId: string | null = null;

let isOrientationChanged = false;

let previousOrientation = window.orientation;

let loadedPageWidth = window.innerWidth;

if (localStorage.getItem('lastMockedOrganizationId')) {
  // console.log("Mock orgId", mockedOrgId);
  mockedOrgId = localStorage.getItem('lastMockedOrganizationId');
}

let zoom: number | null = null;

let firefoxZoom: number | null = null;
let firefoxWidth: number | null = null;

let pageWidth: number | null = null;
let fsContainerHeight: number | null = null;
let allSounds = [];
let loadedSoundObjects: any[] = [];
let congratsSounds: string =
  'https://cesiumstorage.blob.core.windows.net/actionsounds/4a60b590-bc2a-4b73-8edc-df24e5f8a95fcrowd-cheer-ii-6263_Uui3yljt.mp3';

let soundsLoaded = false;

let lastCheckedTimeForNonSignalR: any = null;

let timeZone: string;
let serverTimeOffset: any = null;
let reportTimeOut: any;

let isMobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

let groupId = getQueryVariable('groupId');

let colorMap = [
  { backgroundColor: '#0096DB', roomColor: '#4cb5e5' },
  { backgroundColor: '#01B8AA', roomColor: '#4dcdc3' },
  { backgroundColor: '#8AD4EB', roomColor: '#ade0f1' },
  { backgroundColor: '#0096DB', roomColor: '#4cb5e5' },
  { backgroundColor: '#01B8AA', roomColor: '#4dcdc3' },
  { backgroundColor: '#8AD4EB', roomColor: '#ade0f1' },
  { backgroundColor: '#0096DB', roomColor: '#4cb5e5' },
  { backgroundColor: '#01B8AA', roomColor: '#4dcdc3' },
  { backgroundColor: '#8AD4EB', roomColor: '#ade0f1' },
  { backgroundColor: '#0096DB', roomColor: '#4cb5e5' },
  { backgroundColor: '#01B8AA', roomColor: '#4dcdc3' },
  { backgroundColor: '#8AD4EB', roomColor: '#ade0f1' },
  { backgroundColor: '#0096DB', roomColor: '#4cb5e5' },
  { backgroundColor: '#01B8AA', roomColor: '#4dcdc3' },
  { backgroundColor: '#8AD4EB', roomColor: '#ade0f1' },
  { backgroundColor: '#0096DB', roomColor: '#4cb5e5' },
  { backgroundColor: '#01B8AA', roomColor: '#4dcdc3' },
  { backgroundColor: '#8AD4EB', roomColor: '#ade0f1' },
];

// let isDesktop = false;

// if (getQueryVariable('desktop')) {
//   isDesktop = true;
//   localStorage.setItem('isDesktop', 'true');
// } else {
//   const savedIsDesktop = localStorage.getItem('isDesktop');
//   if (savedIsDesktop === 'true') {
//     isDesktop = true;
//   }
// }

// var timeZones = moment.tz.names();
// console.log("All available time zones", timeZones);

const theme = createTheme({
  palette: {
    secondary: {
      main: '#A7D6A4',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
});

const GreenRadio = withStyles({
  root: {
    color: '#4CB946',
    '&$checked': {
      color: '#4CB946',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const styles: {} = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },

  customBadge: {
    backgroundColor: '#4CB946',
    color: 'white',
  },
  newAlertModal: {
    outline: 'none',
    position: 'absolute',
    width: '650px',
    backgroundColor: '#FFFFFF',
    color: 'black',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
  },
  formControlRoot: {
    borderWidth: '1px',
    borderColor: 'white',
    color: '#ffffff',
    '& label.MuiInputLabel-outlined': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
  inputLabelRoot: {
    color: '#ffffff',
    fontFamily: 'Roboto Mono',
    '&.focused': {
      color: '#ffffff',
    },
  },

  paper: {
    backgroundColor: 'transparent',
    // boxShadow: "none",
    overflow: 'hidden',
  },

  grow: {
    flexGrow: 1,
  },
  smallSyncLogo: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  orgName: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    border: '1px solid #E8E8E8',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    borderRadius: 4,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    marginLeft: 16,
    display: 'flex',
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    boxShadow: '0px 0px 5px 0px #707070',
    // color: "#000000",
    marginBottom: 10,
    backgroundColor: '#ffffff',
  },
  headerImage: {
    maxheight: 45,
    paddingTop: 5,
    maxWidth: 200,
  },
  siteName: {
    fontSize: 25,
    paddingLeft: 20,
    // color: "#7e7e7e",
  },
  siteTime: {
    fontSize: 16,
    paddingLeft: 20,
    // color: "#7e7e7e",
  },
  // siteTime: {
  //   position: "fixed",
  //   bottom: 0,
  //   left: 0,
  //   fontSize: 20,
  //   paddingLeft: 20,
  //   color: "#7e7e7e",
  // },
  drawerTitle: {
    textAlign: 'center',
    fontSize: 25,
    padding: 20,
  },
  rightToolBar: {
    marginLeft: 'auto',
    marginRight: '-12px',
    display: 'flex',
    alignItems: 'center',
  },
  sortCheckbox: {
    color: '#50b848',
    '&$checked': {
      color: '#50b848',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  checked: {
    '&:hover': {
      background: '#fff',
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  zoomContainer: {
    textAlign: 'center',
    marginLeft: '-40px',
  },
  zoomInput: {
    width: '33%',
  },
  zoomButtons: {
    padding: 0,
    fontSize: 31,
  },
  drawerSupport: {
    paddingTop: 10,
    color: 'gray',
    fontSize: 15,
    textAlign: 'center',
  },
  logoutButton: {
    width: '100%',
    backgroundColor: '#EBEBEB',
  },
  loginButton: {
    marginTop: 25,
    width: '100%',
    backgroundColor: '#50b848',
    color: '#FFFFFF',
  },
  sortByProviderSwitch: {
    marginLeft: 0,
  },
  homeButton: {
    width: '100%',
    marginTop: 50,
    marginBottom: 25,
  },
  emptyParamText: {
    position: 'absolute',
    top: '35%',
    width: '100%',
    textAlign: 'center',
    fontSize: 24,
    verticalAlign: 'middle',
  },
  closeButton: {
    padding: 10,
    cursor: 'pointer',
    color: 'red',
    textAlign: 'right',
  },
  headerImageLarge: {
    width: 300,
    paddingBottom: 20,
  },
  loginTextButton: {
    textDecoration: 'none',
    color: '#4CB946',
  },
  fullHeight: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    top: 0,
    left: 0,
    position: 'absolute',
  },
  frontDeskSideBar: {
    marginTop: 35,
    backgroundColor: '#FCFCFC',
    border: '1px solid lightgray',
    // minHeight: "90vh",
    // maxHeight: "90vh",
    float: 'right',
    width: 250,
  },
  colorSwitchBase: {
    color: '#4FAC48',
    '&$colorChecked': {
      color: '#4FAC48',
      '& + $colorBar': {
        backgroundColor: '#4FAC48',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  expansionPanel: {
    marginLeft: 10,
    marginRight: 10,
  },
  expansionPanelDetails: {
    display: 'block',
  },
  updateSnack: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  updateMessage: {
    fontSize: 24,
    // display: "flex",
    // alignItems: "center",
  },
  updateIcon: {
    color: '#4CB946',
    fontSize: 34,
    opacity: 0.9,
  },
  flowstationContainer: {
    height: '100vh',
    backgroundSize: 'cover',
  },
  updateMoreDetails: {
    fontSize: 16,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 24,
    paddingLeft: 5,
    paddingRight: 5,
    color: 'red',
  },
  socketDisconnected: {
    zIndex: 10,
    padding: '10px 20px',
    margin: 10,
    borderRadius: 10,
    // backgroundColor: "orange",
    backgroundColor: 'rgba(255, 165, 0, 0.6)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    color: 'white',
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
  },
  deptName: {
    fontSize: 48,
    paddingLeft: 10,
    margin: '30px 0px 10px 0px',
    borderBottom: '1px solid lightgray',
  },
  locateStaffPopover: {
    maxHeight: '50vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    top: 35,
    right: 0,
    marginRight: 20,
    padding: '10px 20px 20px 20px',
    position: 'absolute',
    marginTop: 75,
    zIndex: 10,
    boxShadow: '0px 2px 6px -2px #707070',
    backgroundColor: 'white',
  },
  searchPopover: {
    // maxHeight: "50vh",
    // overflow: "scroll",
    // overflowX: "hidden",
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: 0,
    right: 0,
    // marginRight: 20,
    // padding: 20,
    position: 'absolute',
    marginTop: 72,
    backgroundColor: 'white',
    zIndex: 15,
    boxShadow: '0px 2px 6px -2px #707070',
  },
  browserAlertSnackbar: {
    backgroundColor: 'white',
    color: 'black',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  hide: {
    display: false,
  },
  homeIconContainer: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  colorPickModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  fadedAction: {
    opacity: 0.2,
  },
  popOverActions: {
    // height: 50,
    // width: 50,
    height: 55,
    width: 55,
    padding: 5,
    cursor: 'pointer',
  },
  popOverActionsGray: {
    // height: 50,
    // width: 50,
    height: 55,
    width: 55,
    padding: 5,
    filter: 'grayscale(100%)',
    opacity: '0.4',
  },
  twoStep: {
    position: 'fixed',
    left: 0,
    top: '40%',
    width: '100%',
    outline: 'none',
    // height: "100%",
    overflow: 'auto',
    // backgroundColor: "rgb(0,0,0)",
    // backgroundColor: "rgba(0,0,0,0.4)",
  },
  twoStepContent: {
    borderRadius: 10,
    textAlign: 'center',
    fontSize: 22,
    backgroundColor: '#fefefe',
    margin: 'auto',
    padding: 20,
    border: '1px solid #888',
    width: 250,
    maxWidth: '70%',
  },
  orgItem: {
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
});

function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggableArea"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface JoyRideProps {
  continuous: boolean;
  index: number;
  step: any;
  backProps: any;
  closeProps: any;
  primaryProps: any;
  tooltipProps: any;
  skipProps: any;
  isLastStep: boolean;
}

const JoyrideTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}: JoyRideProps) => (
  <div
    {...tooltipProps}
    style={{
      backgroundColor: 'white',
      borderRadius: 5,
      boxSizing: 'border-box',
      color: 'rgb(51, 51, 51)',
      fontSize: 16,
      maxWidth: '100%',
      padding: 20,
      position: 'relative',
      width: 550,
      lineHeight: '1.4',
      zIndex: 1500,
    }}
  >
    {step.title && (
      <h3 style={{ margin: '0 0 15px', textAlign: 'center' }}>{step.title}</h3>
    )}
    <div style={{ textAlign: 'center' }}>
      <div style={{ padding: '0 10px 20px' }}>{step.content}</div>

      {step.image && (
        <div
          style={{ margin: '0 auto', maxWidth: '100%', textAlign: 'center' }}
        >
          <img
            style={{
              maxWidth: '100%',
              maxHeight: '25vh',
              display: 'block',
              margin: '0 auto',
            }}
            src={step.image}
            alt="Step illustration"
          />
        </div>
      )}
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
      }}
    >
      <div>
        {!isLastStep && (
          <button
            {...skipProps}
            style={{
              backgroundColor: 'transparent',
              border: 0,
              borderRadius: 0,
              cursor: 'pointer',
              color: 'rgb(51, 51, 51)',
              fontSize: 14,
              padding: 8,
              appearance: 'none',
            }}
          >
            Skip
          </button>
        )}
      </div>
      {continuous && (
        <button
          {...primaryProps}
          style={{
            backgroundColor: 'rgb(80, 184, 72)',
            border: 0,
            borderRadius: 4,
            color: 'rgb(255, 255, 255)',
            cursor: 'pointer',
            fontSize: 16,
            padding: '8px 16px',
            appearance: 'none',
          }}
        >
          {isLastStep ? 'Finish' : 'Next'}
        </button>
      )}
    </div>
    {continuous && (
      <button
        {...closeProps}
        style={{
          background: 'none',
          border: 0,
          cursor: 'pointer',
          fontSize: 16,
          padding: 10,
          appearance: 'none',
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        ×
      </button>
    )}
  </div>
);

interface UserSubscriptionProps {
  userInfo: IUser;
}

interface UserSubscriptionState {
  intervalId: any;
}

class UserSubscription extends Component<
  UserSubscriptionProps,
  UserSubscriptionState
> {
  constructor(props: UserSubscriptionProps) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  componentDidMount() {
    if (connection.state === 'Connected') {
      // console.log("Subscribe UserId", this.props.userInfo.id);
      connection.send('userPing', this.props.userInfo.id);

      let sendUserPing = () => {
        console.log('9 Minutes, resend UserId');
        connection.send('userPing', this.props.userInfo.id);
      };

      let intervalId = setInterval(sendUserPing, 540000);
      this.setState({ intervalId: intervalId });
    }
  }

  componentWillUnmount() {
    console.log('Kill UserPing Interval');
    clearInterval(this.state.intervalId);
  }
  render() {
    return <></>;
  }
}

interface DeptSubscriptionProps {
  dept: IDepartment;
}

class DeptSubscription extends Component<DeptSubscriptionProps> {
  componentDidMount() {
    // console.log("SUBSCRIBE DEPT", this.props.dept.id);
    // console.log("SUBSCRIBE SITE", this.props.dept.mongoSiteId);
    // connection.send("subscribeToFlowstationGzip", this.props.dept.id);
    connection.send(
      'subscribeToFlowstationWithRoomUpdatesGzip',
      this.props.dept.id
    );
    connection.send('subscribeToSiteGzip', this.props.dept.mongoSiteId);
  }
  render() {
    return <></>;
  }
}

interface DeviceSubscriptionProps {
  deviceId: string;
}

interface DeviceSubscriptionState {
  intervalId: any;
}

class DeviceSubscription extends Component<
  DeviceSubscriptionProps,
  DeviceSubscriptionState
> {
  constructor(props: DeviceSubscriptionProps) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  componentDidMount() {
    if (connection.state === 'Connected') {
      console.log('Subscribe DeviceId', this.props.deviceId);
      connection.send('devicePing', this.props.deviceId);

      let sendDeviceId = () => {
        console.log('9 Minutes, resend DeviceId');
        connection.send('devicePing', this.props.deviceId);
      };

      let intervalId = setInterval(sendDeviceId, 540000);
      this.setState({ intervalId: intervalId });
    }
  }

  componentWillUnmount() {
    console.log('Kill DeviceId Interval');
    clearInterval(this.state.intervalId);
  }
  render() {
    return <></>;
  }
}

let settingsRequirements = [
  'zoom',
  'largeCards',
  'hideLargeCards',
  'mute',
  'hiddenRooms',
  'hideFrontDesk',
  'providerSwitch',
  'roomSwitch',
  'disableCards',
  'actionsToShow',
  'providersToShow',
  'mutedActions',
  'fsDepartmentsToShow',
  'autoZoom',
  'initialZoom',
  'savedSettings',
];

interface FlowstationProps {
  classes: any;
  authenticated: boolean;
  loggedInUserData: IUser;
  errorLoggedInUser: boolean;
  organization: IOrganization;
  siblingDepts: IDepartment[];
  groupData: IDepartment;
  department: IDepartment;
  site: ISite;
  // staffLocations: any;
  organizationDepts: IDepartment[];
  distinctOrgActions: IAction[];
  logAction: (logName: string, deptId: string, userId: string) => void;
  fetchLoggedInUser: (
    userEmail: string,
    token: string,
    mockedOrgId: string | null
  ) => void;
  fetchGroup: (deptId: string) => void;
  fetchSiblingDepts: (siblingDepts: string[]) => void;
  fetchDepartment: (deptId: string) => void;
  updateGroup: (updatedDept: IDepartment) => void;
  updateSiblingDepts: (updatedSiblingDepts: any) => void;
  logout: () => void;
  fetchStaffLocations: (userId: string, orgId: string) => void;
  setDeptHistory: (historyData: any) => void;
  fetchVisibleFsDepartments: (
    primaryDeptId: string,
    fsDepartmentsToShow: any
  ) => void;
  fetchAllFsDepartments: (primaryDeptId: string) => void;
  fetchRoomsByOrgId: (orgId: string) => void;
  fetchDistinctOrgActions: (orgId: string) => void;
  fetchSites: (orgId: string) => void;
  sites: ISite[];
  rooms: IRoom[];
  fetchOrganizations: () => void;
  organizations: IOrganization[];
}

interface FlowstationState {
  selectedSiteFilter: any;
  showAppSearch: boolean;
  fireLogin: boolean;
  orgId: string;
  viewEl: any;
  viewStepIndex: number;
  stepIndex: number;
  // providerInput: any;
  widthIncreased: boolean;
  stopZoom: boolean;
  right: boolean;
  smallFsNotification: boolean;
  smallFsNotificationDetails: any;
  fsNotification: boolean;
  fsNotificationDetails: any;
  roomSwitch: boolean;
  actionSwitch: boolean;
  savedSettings: any[];
  mutedActions: string[];
  gotDeptProviders: boolean;
  autoCompleteProvidersToView: any[];
  savedViewDetails: any;
  loaded: boolean;
  providerSwitch: boolean;
  username: string;
  password: string;
  zoom: number;
  hiddenRooms: string[];
  hideLogoutButton: boolean;
  mute: boolean;
  darkMode: boolean;
  disableCards: boolean;
  cardInterval: boolean;
  hideFrontDesk: boolean;
  hideFrontDeskContainer: boolean;
  allProviders: IProvider[];
  largeCards: boolean;
  showConnectionInfo: boolean;
  updateShowMore: boolean;
  featureRequestMade: boolean;
  hideLargeCards: boolean;
  fsDepartmentsToShow: any[];
  pageWidth: any;
  autoZoom: boolean;
  initialZoom: boolean;
  installChromeMessage: boolean;
  locateStaffPopover: boolean;
  staffSearchKeyword: string;
  gaInitialized: boolean;
  promptToEnableSounds: boolean;
  roomToFlash: string;
  showBrowserSoundError: boolean;
  run: boolean;
  showColorPicker: string;
  unsavedDeptColor: string;
  unsavedDeptColorDeptId: string;
  deptSettingsToModify: any;
  openDeptDialog: boolean;
  draggableKey: any;
  commonProviders: any[];
  showProviders: boolean;
  newViewDialog: boolean;
  showReadOnlyAlert: boolean;
  newViewStep: number;
  newViewName: string;
  viewName: string;
  showPromptToSaveView: boolean;
  viewDeptSettings: any[];
  providersToShow: string[];
  actionsToShow: string[];
  missingView: boolean;
  saveAsNewView: boolean;
  renameView: boolean;
  renameViewValue: string;
  renameViewId: any;
  viewSavedSuccess: boolean;
  startViewTour: boolean;
  preFilterDepartmentsToShow: any[];
  preFilteringDeptSettings: any[];
  openPatientHistory: boolean;
  patientHistoryTarget: any;
  deptHistory: any[];
  showHistory: boolean;
  selectedHistoryTime: any;
  selectedHistoryVal: any;
  selectedHistoryDate: any;
  viewTourSteps: any[];
  steps: any[];
  timeReportOpened: any;
  highlightMember: any;
  highlightMemberLocation: any;
  viewPowerReport: boolean;
  reportEmbedDetails: any;
  providerSearchKey: any;
  roomSearchKey: any;
  departmentSearchKey: any;
  activeAlerts: any[];
  showActiveAlerts: boolean;
  showUserNotifications: boolean;
  selectedRoom: any;
  availableActionsToSelect: IAction[];
  showActionsPopover: boolean;
  showTwoStep: boolean;
  twoStepAction: any;
  doubleConfirmationText: string;
  actionAddedSuccess: boolean;
  showNewViewDepartmentsSearch: boolean;
  playConfetti: boolean;
  confettiTime: any;
  playCongratulationsConfetti: boolean;
  congratData: any;
  showSounds: boolean;
  waitingRoomProvidersToShow: any[];
  showOrganizationList: boolean;
  orgFilterText: string;
}

class Flowstation extends Component<FlowstationProps, FlowstationState> {
  constructor(props: FlowstationProps) {
    super(props);

    // @ts-ignore
    this.providerInput = React.createRef();
    // @ts-ignore
    this.alertRef = React.createRef();
    // @ts-ignore
    this.soundsRef = React.createRef();

    this.state = {
      // providerInput: React.createRef(),
      orgFilterText: '',
      selectedSiteFilter: null,
      showAppSearch: false,
      playConfetti: false,
      confettiTime: null,
      viewEl: null,
      orgId: '',
      viewStepIndex: 0,
      stepIndex: 0,
      widthIncreased: false,
      stopZoom: true,
      right: false,
      smallFsNotification: false,
      smallFsNotificationDetails: null,
      fsNotification: false,
      fsNotificationDetails: null,
      roomSwitch: false,
      actionSwitch: false,
      providersToShow: [],
      mutedActions: [],
      savedSettings: [],
      fireLogin: false,
      gotDeptProviders: false,
      autoCompleteProvidersToView: [],
      savedViewDetails: {},
      loaded: false,
      providerSwitch: true,
      username: '',
      password: '',
      zoom: isMobile ? 50 : 100,
      hiddenRooms: [],
      hideLogoutButton: false,
      mute: false,
      darkMode: false,
      disableCards: false,
      cardInterval: false,
      hideFrontDesk: true,
      hideFrontDeskContainer: false,
      allProviders: [],
      largeCards: false,
      showConnectionInfo: false,
      updateShowMore: false,
      featureRequestMade: false,
      hideLargeCards: true,
      fsDepartmentsToShow: [],
      pageWidth: null,
      autoZoom: true,
      initialZoom: false,
      installChromeMessage: true,
      locateStaffPopover: false,
      staffSearchKeyword: '',
      gaInitialized: false,
      promptToEnableSounds: false,
      roomToFlash: '',
      showBrowserSoundError: false,
      run: false,
      showColorPicker: '',
      unsavedDeptColor: '',
      unsavedDeptColorDeptId: '',
      deptSettingsToModify: null,
      openDeptDialog: false,
      draggableKey: 0,
      commonProviders: [],
      showProviders: false,
      newViewDialog: false,
      showReadOnlyAlert: false,
      newViewStep: 0,
      newViewName: '',
      viewName: '',
      showPromptToSaveView: false,
      viewDeptSettings: [],
      actionsToShow: [],
      missingView: false,
      saveAsNewView: false,
      renameView: false,
      renameViewValue: '',
      renameViewId: null,
      viewSavedSuccess: false,
      startViewTour: false,
      preFilterDepartmentsToShow: [],
      preFilteringDeptSettings: [],
      openPatientHistory: false,
      patientHistoryTarget: null,
      deptHistory: [],
      showHistory: false,
      selectedHistoryTime: null,
      selectedHistoryVal: null,
      selectedHistoryDate: moment().local().format('YYYY-MM-DD'),
      viewPowerReport: false,
      reportEmbedDetails: null,
      timeReportOpened: null,
      highlightMember: null,
      highlightMemberLocation: null,
      providerSearchKey: 0,
      roomSearchKey: 0,
      departmentSearchKey: 0,
      activeAlerts: [],
      showActiveAlerts: false,
      showSounds: false,
      showUserNotifications: false,
      selectedRoom: '',
      availableActionsToSelect: [],
      showActionsPopover: false,
      showTwoStep: false,
      twoStepAction: null,
      doubleConfirmationText: '',
      actionAddedSuccess: false,
      showNewViewDepartmentsSearch: false,
      playCongratulationsConfetti: false,
      congratData: null,
      waitingRoomProvidersToShow: [],
      showOrganizationList: false,

      viewTourSteps: [
        {
          content: (
            <div>
              <div>
                <div>
                  You can make adjustments to each visible department on the
                  departments name card.
                </div>
                <br />
                <div>
                  <PaintIcon
                    style={{
                      fontSize: 28,
                      maxHeight: 24,
                      verticalAlign: 'middle',
                    }}
                  />
                  The palette icon allows you to change the department color.
                </div>
                <div>
                  <QuestionIcon
                    style={{
                      fontSize: 28,
                      maxHeight: 24,
                      verticalAlign: 'middle',
                    }}
                  />
                  The question mark icon will take you to your department
                  actions documentation.
                </div>
                <div>
                  {' '}
                  <img
                    src={filterIcon}
                    alt="="
                    style={{
                      cursor: 'pointer',
                      maxWidth: 20,
                      marginBottom: 2,
                      marginLeft: 4,
                      filter: 'invert(1)',
                      verticalAlign: 'middle',
                    }}
                  />{' '}
                  The funnel icon allows you to filter your department.
                </div>
              </div>
            </div>
          ),
          placement: 'center',
          target: 'body',
        },
      ],
      steps: [
        {
          disableBeacon: true,
          content:
            'Welcome to the Flowstation! This tutorial will show you how to use the Flowstation.',
          placement: 'center',
          target: 'body',
        },
        // START========================================================================================
        {
          content:
            'Rooms have three statuses, Empty (white), Patient (green) and Hold (orange).',
          placement: 'center',
          target: 'body',
          image: emptyPatientHold,
        },
        {
          content:
            "Cards display two timers. The left timer shows the total time that the patient has been in the room. The timer on the right reflects the rooms current condition. For example, If a provider or staff member is in the room with the patient, the timer will either say 'Provider', or 'Staff'. If the patient is left alone, the timer will show 'Alone'. When the room is on hold, the timer will show 'Hold'",
          placement: 'center',
          target: 'body',
          image: aloneComparison,
        },
        {
          content: 'To add a patient to the room, select the room name.',
          placement: 'center',
          target: 'body',
          image: addPatient,
        },
        {
          content:
            'To hold a room, press and hold the room name until the card turns orange.',
          placement: 'center',
          target: 'body',
          image: holdPatient,
        },
        {
          content:
            'To remove a patient, click on the room name while the card is green.',
          placement: 'center',
          target: 'body',
          image: removePatient,
        },

        {
          content: 'Room action are located near the center of the room card.',
          placement: 'center',
          target: 'body',
          image: addedActions,
        },
        {
          content:
            "To add an action, click on the '+' square located near the center of the card. Note: The room cannot be empty to add actions. To remove that action, simply click the added action.",
          placement: 'center',
          target: 'body',
          image: addAction,
        },
        {
          content:
            'You can see who is currently in the room by looking near the bottom of the room card.',
          placement: 'center',
          target: 'body',
          image: roomWithProvider,
        },
        {
          content: 'You can search for staff, providers, or equipment here.',
          target: '.step-search',
        },
        {
          content: 'To mute/unmute the Flowstation. Click the speaker icon.',
          target: '.step-mute',
        },
        {
          content:
            'You can also open the side drawer to explore filter options, such as department filtering and room filtering.',
          // target: ".step-drawer",
          placement: 'center',
          target: 'body',
        },
        {
          content: 'You can filter by departments here.',
          target: '.step-departments',
          placement: 'left',
        },
      ],
    };
  }

  componentDidMount() {
    if (getQueryVariable('newView')) {
      // this.state.newViewDialog = true;
      console.log('set it!');
      this.setState({
        // ...this.state,
        newViewDialog: true,
      });
    }

    if (getQueryVariable('startTour')) {
      console.log('Auto start tour');
      // this.state.run = true;
      this.setState({
        // ...this.state,
        run: true,
      });
    }

    if (getQueryVariable('viewCreated')) {
      console.log('Auto start view tour');
      // this.state.startViewTour = true;
      this.setState({
        // ...this.state,
        startViewTour: true,
      });
    }

    // let keepBrowserAwake = () => {
    //   blankSound.volume = 0.5;
    //   blankSound.play();
    //   console.log('keep awake')
    // };
    // setInterval(keepBrowserAwake, 20000);

    // console.log("USER", this.props.loggedInUserData);
    if (Browser) {
      console.log('Browser', Browser.name);
    }

    /*
     * Check if param has Username and Password
     * Example: http://localhost:3000/?groupId=55&username=user&password=1234
     */

    window.addEventListener('keydown', e => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();

        this.openLocateStaff();

        // Might need to be...

        // @ts-ignore
        this.providerInput.current.focus();
        // @ts-ignore
        this.providerInput.current.select();
      }
    });

    let userEmail = localStorage.getItem('email');
    let token = localStorage.getItem('token');

    if (this.props.authenticated === false) {
      this.setState({
        fireLogin: true,
        cardInterval: !this.state.cardInterval,
      });
    }

    let allViews = null;

    if (this.props.loggedInUserData) {
      if (this.props.loggedInUserData.mongoOrganizationId || mockedOrgId) {
        let orgIdForActions = mockedOrgId
          ? mockedOrgId
          : this.props.loggedInUserData.mongoOrganizationId;
        console.log('CHECK ORG ID FOR ACTIONS', orgIdForActions);
        if (orgIdForActions) {
          if (localStorage.getItem('token')) {
            this.props.fetchDistinctOrgActions(orgIdForActions);
            this.props.fetchSites(orgIdForActions);
          }
        }
      }

      if (getQueryVariable('newView') && getQueryVariable('groupId')) {
        this.props.logAction(
          'Creating New View',
          getQueryVariable('groupId')!,
          this.props.loggedInUserData.id
        );
      }
      if (getQueryVariable('viewId')) {
        let userViews = [];
        let publishedViews = [];
        console.log('loaded user', this.props.loggedInUserData);
        if (this.props.loggedInUserData.userSettings) {
          let savedSettings: any = {};
          savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
          if (savedSettings.userViews) {
            userViews = savedSettings.userViews;
          }
        }

        if (this.props.organization.publishedViews) {
          // Loop published views and assign to publishedViews
          if (this.props.organization.publishedViews.length > 0) {
            publishedViews = JSON.parse(this.props.organization.publishedViews);
          }
        }

        // console.log("published views", publishedViews);
        // console.log("organization?", this.props.organization);

        let found = false;

        userViews.forEach((view: any) => {
          // console.log("loop", view);
          if (view.viewId === getQueryVariable('viewId')) {
            // console.log("FOUND IT,  WOW", view);
            found = true;
            groupId = view.defaultDeptId;
          }
        });

        publishedViews.forEach((view: any) => {
          if (view.viewId === getQueryVariable('viewId')) {
            // console.log("FOUND IT");
            found = true;
            groupId = view.defaultDeptId;
          }
        });

        allViews = userViews.concat(publishedViews);

        // console.log("allViews", allViews);

        if (!found) {
          localStorage.removeItem('lastVisitedView');
          console.log('Missing view');
          this.setState({
            missingView: true,
          });
        }
      }
    }

    let isNewView = getQueryVariable('newView');

    // let userEmail = localStorage.getItem("email");
    // let token = localStorage.getItem("token");
    let darkMode = false;
    let isDark = localStorage.getItem('darkMode') || '{}';
    if (isDark === '{}') {
      isDark = 'false';
      localStorage.setItem('darkMode', 'false');
    }

    if (JSON.parse(isDark)) {
      darkMode = true;
    }
    this.setState({
      darkMode: darkMode,
    });

    let createSettings = (deptId: string) => {
      let settingsTemplate: any = {
        //TODO: copy this functionality to view?
        zoom: isMobile ? 50 : 100,
        largeCards: false,
        hideLargeCards: true,
        mute: false,
        hiddenRooms: [],
        hideFrontDesk: true,
        providerSwitch: true,
        roomSwitch: false,
        actionSwitch: false,
        disableCards: false,
        actionsToShow: [],
        providersToShow: [],
        mutedActions: [],
        fsDepartmentsToShow: [deptId],
        autoZoom: true,
        initialZoom: false,
        savedSettings: [],
        waitingRoomProvidersToShow: [],
      };
      // This object will be used to separate settings from different flowstations
      localStorage.setItem(deptId, JSON.stringify(settingsTemplate));

      this.setState({
        zoom: settingsTemplate.zoom,
        largeCards: settingsTemplate.largeCards,
        hideLargeCards: settingsTemplate.hideLargeCards,
        mute: settingsTemplate.mute,
        hiddenRooms: settingsTemplate.hiddenRooms,
        hideFrontDesk: settingsTemplate.hideFrontDesk,
        providerSwitch: settingsTemplate.providerSwitch,
        roomSwitch: settingsTemplate.roomSwitch,
        actionSwitch: settingsTemplate.actionSwitch,
        disableCards: settingsTemplate.disableCards,
        actionsToShow: settingsTemplate.actionsToShow,
        providersToShow: settingsTemplate.providersToShow,
        mutedActions: settingsTemplate.mutedActions,
        fsDepartmentsToShow: isNewView
          ? []
          : settingsTemplate.fsDepartmentsToShow,
        autoZoom: settingsTemplate.autoZoom,
        initialZoom: settingsTemplate.initialZoom,
        savedSettings: settingsTemplate.savedSettings,
        waitingRoomProvidersToShow: settingsTemplate.waitingRoomProvidersToShow,
      });

      this.props.fetchVisibleFsDepartments(
        deptId,
        settingsTemplate.fsDepartmentsToShow
      );
    };

    let viewId = getQueryVariable('viewId');
    if (viewId) {
      this.updateFlowstationURLView(viewId);
      // console.log("load view settings", viewId);
      // let userSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      // console.log("allViews here", allViews);
      if (_.some(allViews, ['viewId', viewId])) {
        // console.log("FOUND IT", viewId);
        // userSettings.userViews.
        allViews.forEach((view: any) => {
          if (view.viewId === viewId) {
            let savedViewDetails = view;

            // console.log("USER VIEW", view);
            // Set state with view data
            let vs = view.viewSettings;

            // if vs has darkMode property, set darkMode to that value
            if (vs.hasOwnProperty('darkMode')) {
              this.setState({
                darkMode: vs.darkMode,
              });
              localStorage.setItem('darkMode', vs.darkMode ? 'true' : 'false');
            }
            // console.log("vs", vs);
            let hiddenRooms: any = [];
            let providersToShow: any = [];
            let actionsToShow: any = [];
            let viewDeptSettings: any = [];
            let waitingRoomProvidersToShow: any = [];

            vs.deptSettings.forEach((dept: any) => {
              viewDeptSettings.push(dept);
              if (dept.hiddenRooms.length > 0) {
                dept.hiddenRooms.forEach((room: IRoom) => {
                  hiddenRooms.push(room);
                });
              }

              // This might be causing the issue since we're looping through each dept and combining all providers to show into one array without knowing the deptId they belong to
              // console.log("DEPT TEST", dept);
              if (dept.providersToShow.length > 0) {
                dept.providersToShow.forEach((provider: IUser) => {
                  // console.log("PUSH", provider);
                  providersToShow.push(provider); // This is being pushed to array of this.state.providersToShow (this is what we're referencing that is affecting every dept)
                });
              }
              if (dept.actionsToShow.length > 0) {
                dept.actionsToShow.forEach((action: IAction) => {
                  actionsToShow.push(action);
                });
              }
              if (dept.waitingRoomProvidersToShow?.length > 0) {
                dept.waitingRoomProvidersToShow.forEach((action: IUser) => {
                  waitingRoomProvidersToShow.push(action);
                });
              }
            });

            // console.log("hidden rooms", hiddenRooms);

            console.log(
              'Loaded all saved view dept settings.',
              viewDeptSettings
            );

            this.setState({
              viewName: view.viewName,
              hiddenRooms: _.uniq(hiddenRooms),
              providersToShow: _.uniq(providersToShow),
              waitingRoomProvidersToShow: _.uniq(waitingRoomProvidersToShow),
              actionsToShow: _.uniq(actionsToShow),
              autoZoom: vs.autoZoom,
              hideLargeCards: vs.hideLargeCards,
              mute: vs.mute,
              fsDepartmentsToShow: vs.fsDepartmentsToShow,
              viewDeptSettings: viewDeptSettings,
              savedViewDetails: savedViewDetails,
            });

            if (this.props.authenticated) {
              this.props.fetchVisibleFsDepartments(
                view.defaultDeptId,
                vs.fsDepartmentsToShow
              );
              this.props.fetchRoomsByOrgId(
                this.props.loggedInUserData.mongoOrganizationId
              );
            }
          }
        });
      } else {
        console.log('View ID doesnt exist in user data');
      }
      // console.log("user settings", userSettings);
    } else {
      if (groupId) {
        const flowStationSettings = localStorage.getItem(groupId);

        console.log(
          `*******************************************************************`
        );
        console.log(`****************${groupId}***********************`);
        console.log(
          `****************${flowStationSettings}***********************`
        );
        console.log(
          `*******************************************************************`
        );
        // this.updateFlowstationURLGroup(groupId);

        if (!isEmpty(flowStationSettings)) {
          let savedSettings = JSON.parse(flowStationSettings || '{}');
          const hasAllKeys = settingsRequirements.every(item =>
            savedSettings.hasOwnProperty(item)
          );
          // console.log("Has all keys?", hasAllKeys);

          if (hasAllKeys) {
            // All Keys Found, Set State
            // console.log('Saved settings!', savedSettings)
            this.setState({
              //TODO: check here? I don't think so...
              zoom: savedSettings.zoom,
              largeCards: savedSettings.largeCards,
              hideLargeCards: savedSettings.hideLargeCards,
              mute: savedSettings.mute,
              hiddenRooms: _.uniq(savedSettings.hiddenRooms),
              hideFrontDesk: savedSettings.hideFrontDesk,
              providerSwitch: savedSettings.providerSwitch,
              roomSwitch: savedSettings.roomSwitch,
              actionSwitch: savedSettings.actionSwitch,
              disableCards: savedSettings.disableCards,
              actionsToShow: _.uniq(savedSettings.actionsToShow),
              providersToShow: _.uniq(savedSettings.providersToShow),
              waitingRoomProvidersToShow: _.uniq(
                savedSettings.waitingRoomProvidersToShow
              ),
              mutedActions: savedSettings.mutedActions,
              fsDepartmentsToShow: isNewView
                ? []
                : savedSettings.fsDepartmentsToShow,
              autoZoom: savedSettings.autoZoom,
              initialZoom: savedSettings.initialZoom,
              savedSettings: savedSettings.savedSettings,
            });

            if (this.props.authenticated) {
              this.props.fetchVisibleFsDepartments(
                groupId,
                savedSettings.fsDepartmentsToShow
              );
              this.props.fetchRoomsByOrgId(
                this.props.loggedInUserData.mongoOrganizationId
              );
            }
          } else {
            createSettings(groupId);
          }
        } else {
          if (groupId) {
            createSettings(groupId);
          }
        }
      }
    }

    // console.log("Width: ", window.screen.availWidth);
    if (window.screen.availWidth < 550) {
      this.setState({
        hideFrontDeskContainer: true,
      });
    }

    let mute = getQueryVariable('mute');
    if (mute && groupId) {
      let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
      let isMute = mute === 'true';

      let newSettings = {
        ...savedSettings,
        mute: isMute,
      };
      localStorage.setItem(groupId, JSON.stringify(newSettings));

      this.setState({
        mute: isMute,
      });
    }

    // Sort by query param
    // let sortParam = getQueryVariable("sortby");
    // if (sortParam === "provider") {
    //   this.setState({ providerSwitch: true });
    // } else if (sortParam === "room") {
    //   this.setState({ providerSwitch: false });
    // }

    /*
     * Set param zoom (default is 100 or last local storage set zoom. This will override that)
     * Example: http://localhost:3000/?groupId=55&zoom=150
     */

    if (userEmail && token) {
      axios
        .get('https://api.synctimes.com/api/v3/fs/utctime')
        .then(response => {
          // serverTimeOffset = moment("2022-09-22T02:10:36.2894436Z").diff(new Date());
          serverTimeOffset = moment(response.data.data).diff(new Date());

          console.log('Millisecond Offset', serverTimeOffset);
        });

      // this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };
      axios
        .get(
          API.REACT_APP_API_ACTIVEALERTS + this.props.loggedInUserData.id,
          headers
        )
        .then(response => {
          console.log('res', response);
          if (response.data.data) {
            this.setState({
              activeAlerts: response.data.data.activeAlerts,
            });
          }
        })
        .catch(err => {
          console.log('err fetching active notifications', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });

      // settimeout after 5 minutes, fetch https://api.synctimes.com/api/v3/fs/utctime once again
      setTimeout(() => {
        console.log('fetching utctime at 5 seconds');
        axios
          .get(
            API.REACT_APP_API_ACTIVEALERTS + this.props.loggedInUserData.id,
            headers
          )
          .then(response => {
            console.log('res', response);
            if (response.data.data) {
              this.setState({
                activeAlerts: response.data.data.activeAlerts,
              });
            }
          })
          .catch(err => {
            console.log('err fetching active notifications', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }, 5000);

      setTimeout(() => {
        console.log('fetching utctime at 5 minutes');
        axios
          .get(
            API.REACT_APP_API_ACTIVEALERTS + this.props.loggedInUserData.id,
            headers
          )
          .then(response => {
            console.log('res', response);
            if (response.data.data) {
              this.setState({
                activeAlerts: response.data.data.activeAlerts,
              });
            }
          })
          .catch(err => {
            console.log('err fetching active notifications', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });
      }, 300000);
    }

    // console.log("Location " + window.location);
    // console.log("connected?", socket.connected);

    if (allowSignalR) {
      connection.onreconnected((connectionId: string) => {
        console.log('Reconnected SigalR', connectionId);
        if (groupId) {
          // this.props.fetchGroup(groupId);
          this.props.fetchVisibleFsDepartments(
            groupId,
            this.state.fsDepartmentsToShow
          );
        }
        // this.props.fetchSiblingDepts(this.props.siblingDepts);

        // Refetch alerts with reconnect
        if (userEmail && token) {
          // this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
          let headers = {
            headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
          };
          axios
            .get(
              API.REACT_APP_API_ACTIVEALERTS + this.props.loggedInUserData.id,
              headers
            )
            .then(response => {
              console.log('refetch active alerts reconnect', response);
              if (response.data.data) {
                this.setState({
                  activeAlerts: response.data.data.activeAlerts,
                });
              }
            })
            .catch(err => {
              console.log('err fetching active notifications', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      });
    }

    if (groupId && token) {
      // console.log("Fetching Group");

      // console.log("groupId", groupId);

      let currentViewId = getQueryVariable('viewId');

      console.log('currentViewId', currentViewId);
      if (currentViewId) {
        localStorage.setItem('lastVisitedView', currentViewId);
        localStorage.removeItem('lastVisitedFs');
      } else {
        localStorage.setItem('lastVisitedFs', groupId);
        localStorage.removeItem('lastVisitedView');
      }

      if (allowSignalR) {
        // Signal R message event
        connection.on('broadcastMessage', (name: string, message: string) => {
          console.log('Got message in FS', name, message);
        });

        connection.on('consoleWrite', (message: string) => {
          console.log('Received consoleWrite', message);
        });

        connection.on('congratulateStaff', (message: any) => {
          console.log('Congratulate message', message);

          this.setState({
            playCongratulationsConfetti: true,
            congratData: message,
          });

          if (!this.state.mute) {
            // play congrats sound
            let audio = new Audio(congratsSounds);
            audio.play();
          }

          setTimeout(() => {
            this.setState({
              playCongratulationsConfetti: false,
              congratData: null,
            });
          }, 60000);
        });

        connection.on('updateRoomGzip', (roomDataEncoded: any) => {
          console.log(
            '%c Updating Room ',
            'background: #4CB946; color: #ffffff'
          );

          let updatedRoomData = getObjectFromGzipEncodedData(roomDataEncoded);
          console.log('Updated room data', updatedRoomData);
          if (this.props.groupData.id === updatedRoomData.mongoDeptId) {
            // Update primary department
            let roomsToModify = [...this.props.groupData.rooms];

            roomsToModify.forEach((room, index) => {
              if (room.id === updatedRoomData.id) {
                roomsToModify[index] = updatedRoomData;
              }
            });

            let updatedDept = {
              ...this.props.groupData,
              rooms: roomsToModify,
            };
            this.props.updateGroup(updatedDept);
          } else {
            // Update sibling department
            let roomMongoDeptId = updatedRoomData.mongoDeptId;
            let siblingDepts = [...this.props.siblingDepts];
            let deptToModify: any;

            siblingDepts.forEach((dept: any) => {
              if (dept.id === roomMongoDeptId) {
                deptToModify = dept;
              }
            });

            if (deptToModify) {
              let roomsToModify = [...deptToModify.rooms];
              roomsToModify.forEach((room, index) => {
                if (room.id === updatedRoomData.id) {
                  roomsToModify[index] = updatedRoomData;
                }
              });

              let updatedSiblingDept = {
                ...deptToModify,
                rooms: roomsToModify,
              };

              this.props.updateSiblingDepts(updatedSiblingDept);
            }
          }
        });

        connection.on('updateWaitingRoomGzip', (updateDataEncoded: any) => {
          console.log(
            '%c Updating Waiting Room ',
            'background: #4CB946; color: #ffffff'
          );
          let updateData = getObjectFromGzipEncodedData(updateDataEncoded);
          console.log('Updated waiting room data', updateData);

          if (this.props.groupData.id === updateData.deptId) {
            // console.log("Update primary", updateData);
            // this.props.updateGroup(updateData.fsData);
            let updatedDept = {
              ...this.props.groupData,
              providerPatientsWaiting: updateData.providerPatientsWaiting,
            };
            this.props.updateGroup(updatedDept);
          } else {
            console.log('Update sibling');
            // update sibling depts

            let arr = this.props.siblingDepts;
            let index = _.findIndex(arr, { id: updateData.deptId });

            let updatedSiblingDept = {
              ...arr[index],
              providerPatientsWaiting: updateData.providerPatientsWaiting,
            };

            // console.log("update data", updateData);
            let newSiblings = arr.splice(index, 1, updatedSiblingDept);
            // console.log("new", newSiblings);

            this.props.updateSiblingDepts(newSiblings);
          }

          let viewId = getQueryVariable('viewId');
          let playPatientSound: boolean = false;
          let newPatientId = updateData.newPatientId;

          if (updateData.newPatientAdded && !this.state.mute) {
            if (viewId) {
              let deptSettingFound = false;
              this.state.viewDeptSettings.forEach(deptViewSetting => {
                if (deptViewSetting.deptId === updateData.deptId) {
                  deptSettingFound = true;
                  if (deptViewSetting.waitingRoomProvidersToShow?.length > 0) {
                    let waitingRoomProvidersToShow =
                      deptViewSetting.waitingRoomProvidersToShow;
                    let foundProviderId = '';
                    updateData.providerPatientsWaiting.forEach(
                      (provider: any) => {
                        provider.patients.forEach((patient: any) => {
                          if (patient.id === newPatientId) {
                            foundProviderId = patient.providerId;
                          }
                        });
                      }
                    );

                    if (
                      _.uniq(waitingRoomProvidersToShow).includes(
                        foundProviderId
                      )
                    ) {
                      console.log('NEW True by selection (VIEW)');
                      playPatientSound = true;
                    }
                  } else {
                    playPatientSound = true;
                  }
                }
              });

              // If not found in view settings, check if it's in the FS departments to show. This happens when no provider filtering has been applied to sibling dept in the view.
              if (!deptSettingFound) {
                // console.log("NOT FOUND IN VIEW SETTINGS", updateData.deptId)
                if (
                  this.state.fsDepartmentsToShow.includes(updateData.deptId)
                ) {
                  // console.log("FOUND IN FS DEPTS TO SHOW")
                  // console.log("State waiting room providers to show", this.state.waitingRoomProvidersToShow)
                  playPatientSound = true;
                }
              }
            } else {
              if (getQueryVariable('groupId')) {
                if (localStorage.getItem(updateData.deptId)) {
                  let departmentSettings = JSON.parse(
                    localStorage.getItem(updateData.deptId)!
                  );
                  if (departmentSettings) {
                    if (
                      departmentSettings.waitingRoomProvidersToShow?.length > 0
                    ) {
                      let waitingRoomProvidersToShow =
                        departmentSettings.waitingRoomProvidersToShow;
                      let foundProviderId = '';

                      updateData.providerPatientsWaiting.forEach(
                        (provider: any) => {
                          provider.patients.forEach((patient: any) => {
                            if (patient.id === newPatientId) {
                              foundProviderId = patient.providerId;
                            }
                          });
                        }
                      );

                      if (
                        _.uniq(waitingRoomProvidersToShow).includes(
                          foundProviderId
                        )
                      ) {
                        console.log('NEW True by selection (STANDALONE)');
                        playPatientSound = true;
                      }
                    } else {
                      playPatientSound = true;
                    }
                  }
                }
              }
            }
          }

          console.log('Play Patient Sound?', playPatientSound);

          if (playPatientSound) {
            console.log('Group', this.props.groupData);
            console.log('sibling', this.props.siblingDepts);
            let actionsList: IAction[] = [];

            if (this.props.groupData.id === updateData.deptId) {
              actionsList = this.props.groupData.actionsList;
            } else {
              this.props.siblingDepts.forEach(dept => {
                if (dept.id === updateData.deptId) {
                  actionsList = dept.actionsList;
                }
              });
            }

            actionsList.forEach((action: IAction) => {
              if (action.id === '6092bf9a394a8d85bfc7e50c') {
                console.log('found action', action);

                if (
                  action.soundUrl &&
                  !this.state.mutedActions.includes(action.id)
                ) {
                  let soundUrl = action.soundUrl;

                  let audio = new Audio(noSound);
                  audio.play();

                  setTimeout(() => {
                    let temporaryAudio = new Audio(soundUrl);
                    // console.log("Play backup sound");
                    temporaryAudio.play();
                  }, 800);
                }
              }
            });
          }
        });

        // Daily report cards
        connection.on('updateFsDailyReportsGzip', (updateDataEncoded: any) => {
          let updateData = getObjectFromGzipEncodedData(updateDataEncoded);
          console.log('data cards check', updateData);
          if (this.props.groupData.id === updateData.deptId) {
            let updatedDepts = {
              ...this.props.groupData,
              dailyData: updateData.flowstationDailyReports,
            };
            this.props.updateGroup(updatedDepts);
          } else {
            let arr = this.props.siblingDepts;
            let index = _.findIndex(arr, { id: updateData.deptId });
            let updatedSiblingDept = {
              ...arr[index],
              dailyData: updateData.flowstationDailyReports,
            };
            let newSiblings = arr.splice(index, 1, updatedSiblingDept);
            this.props.updateSiblingDepts(newSiblings);
          }
        });

        connection.on('lockFsGzip', (updateDataEncoded: any) => {
          var updateData = getObjectFromGzipEncodedData(updateDataEncoded);
          lockScreen(updateData.messageText);
          // Play TTS sound
          let tts = new Audio(updateData.audibleStringUrl);
          tts.play();
          console.log(updateData);
          console.log(
            '%c Locking Department ',
            'background: #4CB946; color: #ffffff'
          );
        });

        connection.on('unlockFsGzip', () => {
          console.log(
            '%c unlocking Department ',
            'background: #4CB946; color: #ffffff'
          );
          window.location.reload(); // Refresh the page
        });

        connection.on('updateFsGzip', async (updateDataEncoded: any) => {
          console.log(
            '%c Updating Department ',
            'background: #4CB946; color: #ffffff'
          );
          // console.log("encodedUpdateData", updateDataEncoded);

          var updateData = getObjectFromGzipEncodedData(updateDataEncoded);
          // Reset timers interval
          // clearInterval(this.interval);
          // clearInterval(this.cardTimer);
          this.setState({ cardInterval: !this.state.cardInterval });
          console.log('Update data', updateData);

          // Check to see if its an alert coming through FS endpoint
          if (updateData.message) {
            // console.log("UPDATE DATA", updateData);
            if (!this.state.mute) {
              if (updateData.ttsUrl) {
                // Play action sound
                let actionTime = moment.utc(updateData.startDateTime).local();
                let currentTime = moment().local();
                let timeDiff = currentTime.diff(actionTime, 'seconds');
                let timeDiffInMili = currentTime.diff(
                  actionTime,
                  'milliseconds'
                );
                console.log('timeDiff', timeDiff);

                console.log('timeDiffInMili', timeDiffInMili);

                if (timeDiff < 30) {
                  // Sound is less than 30 seconds old, play it.
                  // console.log("less than 30 seconds, play sound");
                  // this.playSound(this.props.cardData.roomActions[i].soundUrl);

                  // let audio = new Audio(updateData.soundUrl);
                  // console.log("SOUND PLAYING", updateData);
                  // audio.play();

                  // Play TTS sound
                  let tts = new Audio(updateData.ttsUrl);

                  var sound = new Howl({
                    src: [updateData.ttsUrl],
                  });

                  if (tts) {
                    let buffer = 5000;

                    async function getOffSet() {
                      let offset = 0;

                      let retryCount = 10;
                      let previousDifference = 500;
                      let tolerance = 15;

                      let getServerTimeUtc = async () => {
                        let response = await axios.get(
                          'https://api.synctimes.com/api/v3/fs/utcTimeObject'
                        );
                        return response.data.data.utcDateTime;
                      };

                      for (let i = 0; i < retryCount; i++) {
                        console.log('retry', i);
                        // get utc now and add offset milliseconds
                        let bestGuess = moment
                          .utc()
                          .local()
                          .add(offset, 'milliseconds');

                        let serverDateTime = await getServerTimeUtc();
                        console.log('serverDateTime', serverDateTime);

                        let difference = moment(serverDateTime).diff(
                          bestGuess,
                          'milliseconds'
                        );
                        console.log('difference', difference);

                        if (
                          difference <= tolerance &&
                          previousDifference <= tolerance
                        ) {
                          return offset;
                        }

                        offset += moment(serverDateTime).diff(
                          bestGuess,
                          'milliseconds'
                        );
                        previousDifference = difference;
                      }
                    }

                    let offset = await getOffSet();
                    console.log('offset?', offset);

                    let serverTime = moment
                      .utc()
                      .local()
                      .add(offset, 'milliseconds');
                    let timeToWait = moment(actionTime)
                      .add(buffer, 'milliseconds')
                      .diff(serverTime, 'milliseconds');
                    console.log('timeToWait', timeToWait);

                    if (timeToWait > 0) {
                      setTimeout(() => {
                        console.log('Play TTS');
                        // tts.play();

                        sound.play();
                      }, timeToWait);
                    }
                  }
                }
              }
            }

            this.setState({
              fsNotification: true,
              fsNotificationDetails: updateData,
            });

            // Auto close Toast Notification
            setTimeout(() => {
              this.setState({
                fsNotification: false,
              });
            }, 60000);

            this.props.logAction(
              'Alert Received',
              this.props.department.id,
              this.props.loggedInUserData.id
            );

            if (updateData.showPopup) {
              this.userNotification(updateData);
            }

            let headers = {
              headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
            };
            axios
              .get(
                API.REACT_APP_API_ACTIVEALERTS + this.props.loggedInUserData.id,
                headers
              )
              .then(response => {
                console.log('res', response);
                if (response.data.data) {
                  this.setState({
                    activeAlerts: response.data.data.activeAlerts,
                  });

                  let allowPopover = true;

                  if (
                    localStorage.getItem('disableNotificationPopover') ===
                    'true'
                  ) {
                    allowPopover = false;
                  }

                  response.data.data.activeAlerts.forEach(
                    (activeAlert: any) => {
                      if (activeAlert.overrideMute) {
                        allowPopover = true;
                      }
                    }
                  );

                  let hidePopup = false;

                  let filteredActiveAlerts: any[] = [];
                  response.data.data.activeAlerts.forEach(
                    (activeAlert: any) => {
                      let showAction = true;
                      let siteExists = false;
                      this.state.fsDepartmentsToShow.forEach(
                        (deptIdToShow: string) => {
                          if (
                            this.props.groupData.id === deptIdToShow &&
                            this.props.groupData.mongoSiteId ===
                              activeAlert.siteId
                          ) {
                            siteExists = true;
                          } else {
                            this.props.siblingDepts.forEach(
                              (siblingDept: any) => {
                                if (
                                  siblingDept.id === deptIdToShow &&
                                  siblingDept.mongoSiteId === activeAlert.siteId
                                ) {
                                  siteExists = true;
                                }
                              }
                            );
                          }
                        }
                      );

                      if (
                        activeAlert.isSubscribedAction === false &&
                        siteExists === false
                      ) {
                        showAction = false;
                      }
                      if (showAction) {
                        filteredActiveAlerts.push(activeAlert);
                      }
                    }
                  );

                  if (filteredActiveAlerts.length === 0) {
                    hidePopup = true;
                  }

                  if (hidePopup) {
                    this.setState({
                      showActiveAlerts: false,
                    });
                  } else if (updateData.showPopup && allowPopover) {
                    // console.log("OPEN ACTIVE ALERTS");
                    this.setState({
                      showActiveAlerts: true,
                    });
                  }
                }
              })
              .catch(err => {
                console.log('err fetching active notifications', err);
                if (err.response?.status === 401) {
                  checkRefreshToken();
                }
              });
          }
          // Standard FS update procedure
          else {
            // console.log('standard update')
            if (this.props.groupData.id === updateData.id) {
              // console.log("Update primary", updateData);
              this.props.updateGroup(updateData.fsData);
            } else {
              console.log('Update sibling');
              // update sibling depts

              let arr = this.props.siblingDepts;
              let index = _.findIndex(arr, { id: updateData.id });

              // console.log("update data", updateData);
              let newSiblings = arr.splice(index, 1, updateData.fsData);
              // console.log("new", newSiblings);

              this.props.updateSiblingDepts(newSiblings);
            }
          }
        });
      }
    }

    if (allowSignalR) {
      connection.on('forceWebAppRefresh', (data: { build: string }) => {
        if (metaData.build !== data.build) {
          // window.location.reload(true);
          window.location.reload();
        }
        // console.log("check to force refresh", data);
      });
      connection.on('notifyUser', (data: any) => {
        console.log('New notify', data);
        console.log('%c Notify User', 'background: #4CB946; color: #ffffff');

        let exists = this.state.fsDepartmentsToShow.includes(data.deptId);
        if (!exists) {
          // console.log("Doesnt exist");

          if (data.soundUrl) {
            // console.log("SOUND!", data.soundUrl);
            if (!this.state.mute) {
              let actionTime = moment.utc(data.startDateTime).local();
              let currentTime = moment().local();
              let timeDiff = currentTime.diff(actionTime, 'seconds');
              console.log('timeDiff', timeDiff);
              if (timeDiff < 30) {
                // Sound is less than 30 seconds old, play it.
                // console.log("less than 30 seconds, play sound");
                // this.playSound(this.props.cardData.roomActions[i].soundUrl);
                let audio = new Audio(data.soundUrl);
                audio.volume = 0.2;
                audio.play();
              }
            }
          }
        }

        // Show HTML5 Notification
        if (data.showPopup) {
          this.userNotification(data);
        }

        let headers = {
          headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
        };
        axios
          .get(
            API.REACT_APP_API_ACTIVEALERTS + this.props.loggedInUserData.id,
            headers
          )
          .then(response => {
            console.log('res', response);
            if (response.data.data) {
              let allowPopover = true;

              if (
                localStorage.getItem('disableNotificationPopover') === 'true'
              ) {
                allowPopover = false;
              }

              response.data.data.activeAlerts.forEach((activeAlert: any) => {
                if (activeAlert.overrideMute) {
                  allowPopover = true;
                }
              });

              let hidePopup = false;

              let filteredActiveAlerts: any[] = [];
              response.data.data.activeAlerts.forEach((activeAlert: any) => {
                let showAction = true;
                let siteExists = false;
                this.state.fsDepartmentsToShow.forEach(
                  (deptIdToShow: string) => {
                    if (
                      this.props.groupData.id === deptIdToShow &&
                      this.props.groupData.mongoSiteId === activeAlert.siteId
                    ) {
                      siteExists = true;
                    } else {
                      this.props.siblingDepts.forEach((siblingDept: any) => {
                        if (
                          siblingDept.id === deptIdToShow &&
                          siblingDept.mongoSiteId === activeAlert.siteId
                        ) {
                          siteExists = true;
                        }
                      });
                    }
                  }
                );

                if (
                  activeAlert.isSubscribedAction === false &&
                  siteExists === false
                ) {
                  showAction = false;
                }
                if (showAction) {
                  filteredActiveAlerts.push(activeAlert);
                }
              });

              if (filteredActiveAlerts.length === 0) {
                hidePopup = true;
              }

              if (hidePopup || !allowPopover) {
                this.setState({
                  showActiveAlerts: false,
                });
              } else {
                this.setState({
                  showActiveAlerts: true,
                });
              }

              // if (data.showPopup && allowPopover) {
              // this.setState({
              //   showActiveAlerts: hidePopup ? false : true,
              //   // activeAlerts: response.data.data.activeAlerts,
              // });
              // }

              this.setState({
                activeAlerts: response.data.data.activeAlerts,
              });
            }
          })
          .catch(err => {
            console.log('err fetching active notifications', err);
            if (err.response?.status === 401) {
              checkRefreshToken();
            }
          });

        // Show Toast Notification

        // console.log("CHECK", data);
        this.setState({
          smallFsNotification: true,
          smallFsNotificationDetails: data,
        });

        // Auto close Toast Notification
        setTimeout(() => {
          this.setState({
            smallFsNotification: false,
          });
        }, 15000);
      });

      connection.on('lockUser', (updateData: any) => {
        lockScreen(updateData.messageText);
        console.log(updateData);
        console.log('%c Lock User', 'background: #4CB946; color: #ffffff');
      });

      connection.on('unlockUser', () => {
        console.log('%c Unlock User', 'background: #4CB946; color: #ffffff');
        window.location.reload(); // Refresh the page
      });

      connection.on('refetchFsGzip', () => {
        // data is ignored, no need to convert to object
        console.log(
          '%c Flowstation Forced Update ',
          'background: #0095DA; color: #ffffff'
        );
        if (groupId) {
          // this.props.fetchGroup(groupId);
          // this.props.fetchDepartment(groupId);
          this.props.fetchVisibleFsDepartments(
            groupId,
            this.state.fsDepartmentsToShow
          );

          // Reset cached sounds to re-cache
          soundsLoaded = false;
          allSounds = [];

          this.forceUpdate();
        }
      });
    }

    let incrementTime = () => {
      // console.log("SOCKET STATUS", socket.connected);
      // console.log(socket);
      this.setState({
        cardInterval: !this.state.cardInterval,
      });

      if (!localStorage.getItem('token')) {
        // Token gone, send to login
        // If its a FS and the username and password is included in the URL, then auto login again.
        let username = getQueryVariable('username');
        let password = getQueryVariable('password');
        if (username && password) {
          this.setState({
            fireLogin: true,
            hideLogoutButton: true,
            cardInterval: !this.state.cardInterval,
          });
        } else {
          // otherwise, just send to login
          this.setState({
            fireLogin: true,
          });
        }
      }

      // Check last time saved time, if it's greater than 15 seconds, refetch depts, and set new time

      if (!allowSignalR) {
        // console.log('SignalR not allowed, auto fetch every 15 seconds.')
        if (!lastCheckedTimeForNonSignalR) {
          lastCheckedTimeForNonSignalR = moment();
        } else {
          // console.log('diff', moment().diff(lastCheckedTimeForNonSignalR, "seconds"))
          if (moment().diff(lastCheckedTimeForNonSignalR, 'seconds') > 15) {
            lastCheckedTimeForNonSignalR = moment();
            console.log('No SignalR, auto refetch...');
            // if (groupId) {
            //   this.props.fetchGroup(groupId);
            // }
            // if (this.props.siblingDepts.length > 0) {
            //   this.props.fetchSiblingDepts(this.props.siblingDepts);
            // }
            if (groupId) {
              this.props.fetchVisibleFsDepartments(
                groupId,
                this.state.fsDepartmentsToShow
              );
              if (userEmail && token) {
                let headers = {
                  headers: {
                    Authorization: 'Bearer ' + token,
                    Pragma: 'no-cache',
                  },
                };
                axios
                  .get(
                    API.REACT_APP_API_ACTIVEALERTS +
                      this.props.loggedInUserData.id,
                    headers
                  )
                  .then(response => {
                    // console.log("res active", response);
                    if (response.data.data) {
                      this.setState({
                        activeAlerts: response.data.data.activeAlerts,
                      });
                    }
                  })
                  .catch(err => {
                    console.log('err fetching active notifications', err);
                    if (err.response?.status === 401) {
                      checkRefreshToken();
                    }
                  });
              }
            }

            // this.props.fetchDepartment(groupId)
          }
        }
      }
    };

    // if (groupId || this.state.viewName) {
    setInterval(incrementTime, 1000);
    // }

    // let isMobileDevice = () => {
    //   if (typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1) {
    //     this.setState({
    //       disableCards: true,
    //     });
    //   }
    // };
    // isMobileDevice();

    if (Browser) {
      if (
        Browser.name === 'chrome' ||
        Browser.name === 'firefox' ||
        Browser.name === 'safari'
      ) {
        // THIS WAS AN ANDROID BREAKING ISSUE, SINCE CATCH WASNT SUPPORTED OR HANDLE ON OLD ANDROID
        // let audio = new Audio(noSound);
        // audio.play().catch((err) => {
        //   this.setState({
        //     promptToEnableSounds: true,
        //   });
        //   console.log("SOUND ERR", err);
        // });

        if (
          Browser.name === 'safari'
          // || Browser.name === "ios" ||
          //  Browser.name === "crios" ||
          //  Browser.name === "ios-webview"
        ) {
          console.log('Dont check sound promise');
        } else {
          // console.log("playing static")
          let audio = new Audio(noSound);
          let promise = audio.play();
          if (promise !== undefined) {
            promise
              .then(_ => {
                // Autoplay started!
                // console.log("GOOD");
              })
              .catch(error => {
                // Autoplay was prevented.
                // Show a Prompt so that user can start playback.
                this.setState({
                  promptToEnableSounds: true,
                });
              });
          }
        }
      }
    }

    // Flash a room for 10 secons when roomId is passed through the URL query
    let roomToFlash = getQueryVariable('roomId');
    if (roomToFlash) {
      this.setState({
        roomToFlash: roomToFlash,
      });

      setTimeout(() => {
        this.setState({
          roomToFlash: '',
        });
      }, 10000);
    }

    this.addWindowListenersForOrientationChange();
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  // This toggles hamburger drawer to slide in and out.
  toggleDrawer = (open: boolean) => () => {
    this.setState({ right: open, selectedSiteFilter: null });
  };

  toggleWaitingRoomProvider = (
    provider: any,
    visibleWaitingRoomProviders: any[],
    deptId: string
  ) => {
    // console.log('dept id to change', deptId)
    // console.log('Toggle waiting room provider', provider);

    let newWaitingRoomProvidersToShow: any[] = [];
    if (visibleWaitingRoomProviders) {
      newWaitingRoomProvidersToShow = [...visibleWaitingRoomProviders];
    }

    if (newWaitingRoomProvidersToShow.includes(provider)) {
      console.log('delete it');
      newWaitingRoomProvidersToShow = newWaitingRoomProvidersToShow.filter(
        (obj: any) => {
          return obj !== provider;
        }
      );
    } else {
      newWaitingRoomProvidersToShow.push(provider);
    }

    // this.setState({
    //   waitingRoomProvidersToShow: newWaitingRoomProvidersToShow,
    // });

    let viewId = getQueryVariable('viewId');
    if (viewId) {
      // save to view
      let found = false;
      let viewDeptSettingsToModify = this.state.viewDeptSettings;
      viewDeptSettingsToModify.map((dept, index) => {
        if (dept.deptId === deptId) {
          viewDeptSettingsToModify[index] = {
            ...dept,
            waitingRoomProvidersToShow: newWaitingRoomProvidersToShow,
          };
          found = true;
        }
        return null;
      });

      if (!found) {
        console.log('doesnt exist, add a new dept');
        let newDeptSetting: any = {
          deptId: deptId,
          hiddenRooms: [],
          providersToShow: [],
          actionsToShow: [],
          actionSwitch: false,
          roomSwitch: false,
          providerSwitch: true,
          hideLargeCards: true,
          waitingRoomProvidersToShow: newWaitingRoomProvidersToShow,
        };
        viewDeptSettingsToModify.push(newDeptSetting);
      }

      this.setState({
        viewDeptSettings: viewDeptSettingsToModify,
        showPromptToSaveView: true,
      });
    } else {
      // standard storage save
      let savedSettings = JSON.parse(localStorage.getItem(deptId) || '{}');
      console.log('saved settings', savedSettings);
      let newSettings = {
        ...savedSettings,
        waitingRoomProvidersToShow: newWaitingRoomProvidersToShow,
      };
      console.log('should now be', newSettings);
      localStorage.setItem(deptId, JSON.stringify(newSettings));
    }
  };

  // This toggles whether or not we should sort by provider
  handleProviderSwitch = (allDepts: IDepartment[], deptId: string) => {
    console.log('filter by provider');

    if (getQueryVariable('newView')) {
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          console.log('Found pre dept to modify');
          preFilteringDeptSettingsToModify[i].roomSwitch = false;
          preFilteringDeptSettingsToModify[i].providerSwitch = true;
          preFilteringDeptSettingsToModify[i].actionSwitch = false;
        }
      });
      console.log('updated', preFilteringDeptSettingsToModify);
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
      this.resizeFlowstation(allDepts);
    } else {
      if (this.state.viewName) {
        // Save to view
        let found = false;
        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === deptId) {
            viewDeptSettingsToModify[index] = {
              ...dept,
              roomSwitch: false,
              providerSwitch: true,
              actionSwitch: false,
            };
            found = true;
          }
          return null;
        });

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: [],
            providersToShow: [],
            actionsToShow: [],
            waitingRoomProvidersToShow: [],
            actionSwitch: false,
            roomSwitch: false,
            providerSwitch: true,
            hideLargeCards: true,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState({
          viewDeptSettings: viewDeptSettingsToModify,
          showPromptToSaveView: true,
        });
      } else {
        // Just save to local storage
        let savedSettings = JSON.parse(localStorage.getItem(deptId) || '{}');
        console.log('saved settings', savedSettings);
        let newSettings = {
          ...savedSettings,
          roomSwitch: false,
          providerSwitch: true,
          actionSwitch: false,
        };
        console.log('should now be', newSettings);
        localStorage.setItem(deptId, JSON.stringify(newSettings));
      }
      this.forceUpdate();
      this.resizeFlowstation(allDepts);
    }
  };

  handleRoomSwitch = (allDepts: IDepartment[], deptId: string) => {
    console.log('filter by room');

    if (getQueryVariable('newView')) {
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          console.log('Found pre dept to modify');
          preFilteringDeptSettingsToModify[i].roomSwitch = true;
          preFilteringDeptSettingsToModify[i].providerSwitch = false;
          preFilteringDeptSettingsToModify[i].actionSwitch = false;
        }
      });
      console.log('updated', preFilteringDeptSettingsToModify);
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
      this.resizeFlowstation(allDepts);
    } else {
      if (this.state.viewName) {
        console.log(
          'View details',
          this.state.savedViewDetails.viewSettings.deptSettings
        );

        let viewToUpdate = { ...this.state.savedViewDetails };
        let found = false;
        viewToUpdate.viewSettings.deptSettings.forEach(
          (dept: IDepartmentSetting) => {
            if (dept.deptId === deptId) {
              dept.roomSwitch = true;
              dept.providerSwitch = false;
              dept.actionSwitch = false;
              found = true;
              console.log('Found dept to modify', dept);
            }
          }
        );

        if (!found) {
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: true,
            providerSwitch: false,
            hideLargeCards: true,
          };
          viewToUpdate.viewSettings.deptSettings.push(newDeptSetting);
        }
        console.log('after', viewToUpdate);

        this.setState({
          showPromptToSaveView: true,
          viewDeptSettings: viewToUpdate.viewSettings.deptSettings,
        });
      } else {
        console.log('saving to local storage');
        // Just save to local storage
        let savedSettings = JSON.parse(localStorage.getItem(deptId) || '{}');
        let newSettings = {
          ...savedSettings,
          roomSwitch: true,
          providerSwitch: false,
          actionSwitch: false,
        };
        // console.log("should now be", newSettings);
        localStorage.setItem(deptId, JSON.stringify(newSettings));
        // this.forceUpdate();
      }
      this.resizeFlowstation(allDepts);
    }
  };

  handleHideNonPatientRoomsSwitch = (
    allDepts: IDepartment[],
    deptId: string,
    hideNonPatientRooms: boolean
  ) => {
    console.log('Toggle, its at', hideNonPatientRooms);

    if (getQueryVariable('newView')) {
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          // console.log("Found pre dept to modify");
          preFilteringDeptSettingsToModify[i].hideNonPatientRooms =
            !hideNonPatientRooms;
        }
      });
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });

      console.log('Now this', preFilteringDeptSettingsToModify);
      this.resizeFlowstation(allDepts);
    } else {
      if (this.state.viewName) {
        let found = false;
        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === deptId) {
            viewDeptSettingsToModify[index] = {
              ...dept,
              hideNonPatientRooms: !hideNonPatientRooms,
            };
            found = true;
          }
          return null;
        });

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: false,
            providerSwitch: false,
            hideLargeCards: true,
            hideNonPatientRooms: !hideNonPatientRooms,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState({
          showPromptToSaveView: true,
          viewDeptSettings: viewDeptSettingsToModify,
        });
      } else {
        // save to local storage
        // Just save to local storage
        let savedSettings = JSON.parse(localStorage.getItem(deptId) || '{}');
        let newSettings = {
          ...savedSettings,
          // roomSwitch: false,
          // providerSwitch: false,
          // actionSwitch: false,
          hideNonPatientRooms: !hideNonPatientRooms,
        };
        // console.log("should now be", newSettings);
        localStorage.setItem(deptId, JSON.stringify(newSettings));
        // this.forceUpdate();
      }
      this.resizeFlowstation(allDepts);
    }
  };

  handleActionSwitch = (allDepts: IDepartment[], deptId: string) => {
    console.log('filter by action');

    if (getQueryVariable('newView')) {
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          console.log('Found pre dept to modify');
          preFilteringDeptSettingsToModify[i].roomSwitch = false;
          preFilteringDeptSettingsToModify[i].providerSwitch = false;
          preFilteringDeptSettingsToModify[i].actionSwitch = true;
        }
      });
      console.log('updated', preFilteringDeptSettingsToModify);
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
      this.resizeFlowstation(allDepts);
    } else {
      if (this.state.viewName) {
        // Save to view
        let found = false;
        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === deptId) {
            viewDeptSettingsToModify[index] = {
              ...dept,
              roomSwitch: false,
              providerSwitch: false,
              actionSwitch: true,
            };
            found = true;
          }
          return null;
        });

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: true,
            roomSwitch: false,
            providerSwitch: false,
            hideLargeCards: true,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState({
          showPromptToSaveView: true,
          viewDeptSettings: viewDeptSettingsToModify,
        });
      } else {
        // Just save to local storage
        let savedSettings = JSON.parse(localStorage.getItem(deptId) || '{}');
        let newSettings = {
          ...savedSettings,
          roomSwitch: false,
          providerSwitch: false,
          actionSwitch: true,
        };
        // console.log("should now be", newSettings);
        localStorage.setItem(deptId, JSON.stringify(newSettings));
        // this.forceUpdate();
      }
      this.resizeFlowstation(allDepts);
    }
  };

  checkOrientationAndReloadIfChanged = () => {
    if (window.orientation !== previousOrientation) {
      console.log('Orientation changed to ' + window.orientation + ' degrees');
      console.log(
        'window width is ' +
          window.innerWidth +
          ' and height is ' +
          window.innerHeight
      );
      previousOrientation = window.orientation;
      isOrientationChanged = true;
    }
  };

  addWindowListenersForOrientationChange = () => {
    window.addEventListener(
      'resize',
      this.checkOrientationAndReloadIfChanged,
      false
    );
    window.addEventListener(
      'orientationchange',
      this.checkOrientationAndReloadIfChanged,
      false
    );
  };

  handleAutoZoom = () => {
    if (!this.state.autoZoom) {
      this.setState({
        autoZoom: !this.state.autoZoom,
      });
    } else {
      this.setState({
        autoZoom: !this.state.autoZoom,
      });
    }

    if (groupId) {
      let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
      let newSettings = {
        ...savedSettings,
        autoZoom: !this.state.autoZoom,
      };
      localStorage.setItem(groupId, JSON.stringify(newSettings));
    }
  };

  toggleAction =
    (action: string, groupId: string, allDepts: IDepartment[]) => () => {
      console.log('toggle action', action);
      if (getQueryVariable('newView')) {
        console.log('prefilter toggle room');

        // let updatedDeptSettingsToModify = {...this.state.deptSettingsToModify};
        let preFilteringDeptSettingsToModify = [
          ...this.state.preFilteringDeptSettings,
        ];
        preFilteringDeptSettingsToModify.forEach((preDept, i) => {
          if (preDept.deptId === groupId) {
            // console.log("room clicked", room);
            // console.log("Found pre dept to modify", preDept);
            let array = [...preDept.actionsToShow];
            if (array.includes(action)) {
              console.log('remove action');
              array = array.filter(function (el) {
                return ![action].includes(el);
              });
            } else {
              console.log('add action');
              array.push(action);
            }
            preFilteringDeptSettingsToModify[i].actionsToShow = array;
          }
        });
        console.log('updated', preFilteringDeptSettingsToModify);
        this.setState({
          preFilteringDeptSettings: preFilteringDeptSettingsToModify,
        });
      }

      if (this.state.viewName) {
        let updatedActionsToShow: string[] = [];
        let deptSettingsToModify: any = null;

        this.state.viewDeptSettings.forEach(deptSetting => {
          if (deptSetting.deptId === groupId) {
            console.log('modify this', deptSetting);
            deptSettingsToModify = deptSetting;
          }
        });

        if (deptSettingsToModify) {
          if (deptSettingsToModify.actionsToShow.indexOf(action) > -1) {
            // exists, remove it
            let array = deptSettingsToModify.actionsToShow;
            let index = array.indexOf(action);
            if (index !== -1) {
              array.splice(index, 1);
              updatedActionsToShow = array;
            }
          } else {
            // add it
            updatedActionsToShow = [
              ...deptSettingsToModify.actionsToShow,
              action,
            ];
          }
        }

        let found = false;

        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === groupId) {
            viewDeptSettingsToModify[index] = {
              ...dept,
              actionsToShow: updatedActionsToShow,
            };
            found = true;
          }
          return null;
        });

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: groupId,
            hiddenRooms: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: true,
            providerSwitch: false,
            hideLargeCards: true,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState(
          {
            showPromptToSaveView: true,
            viewDeptSettings: viewDeptSettingsToModify,
          },
          () => {
            console.log('state viewDeptSettings', this.state.viewDeptSettings);
          }
        );
        // ===========================
      } else {
        if (
          JSON.parse(
            localStorage.getItem(groupId) || '{}'
          ).actionsToShow.indexOf(action) > -1
        ) {
          let array = JSON.parse(
            localStorage.getItem(groupId) || '{}'
          ).actionsToShow;
          // console.log("array", array);
          let index = array.indexOf(action);
          if (index !== -1) {
            array.splice(index, 1);

            let savedSettings = JSON.parse(
              localStorage.getItem(groupId) || '{}'
            );
            let newSettings = {
              ...savedSettings,
              actionsToShow: array,
            };
            localStorage.setItem(groupId, JSON.stringify(newSettings));
          }
        } else {
          let array = JSON.parse(
            localStorage.getItem(groupId) || '{}'
          ).actionsToShow;
          array.push(action);

          let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
          let newSettings = {
            ...savedSettings,
            actionsToShow: array,
          };
          localStorage.setItem(groupId, JSON.stringify(newSettings));
        }
      }
      this.forceUpdate();
      this.resizeFlowstation(allDepts);
    };

  // Add and remove rooms from array in state

  // Zoom handlers
  handleZoom = () => (event: ChangeEvent<HTMLInputElement>) => {
    if (groupId) {
      let newZoom = parseInt(event.target.value);
      let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
      let newSettings = {
        ...savedSettings,
        zoom: newZoom,
      };
      localStorage.setItem(groupId, JSON.stringify(newSettings));
      this.setState({ zoom: newZoom });
    }
  };

  handleZoomIncrement = () => {
    console.log('increment zoom');
    if (groupId) {
      let currentZoom = this.state.zoom;
      let increment = 10;
      let newZoom = currentZoom + increment;

      let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
      let newSettings = {
        ...savedSettings,
        zoom: newZoom,
      };
      localStorage.setItem(groupId, JSON.stringify(newSettings));

      this.setState({ zoom: newZoom });
    }
  };

  handleZoomDecrement = () => {
    console.log('decrement zoom');
    if (groupId) {
      let currentZoom = this.state.zoom;
      let increment = 10;
      let newZoom = currentZoom - increment;
      let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
      let newSettings = {
        ...savedSettings,
        zoom: newZoom,
      };
      localStorage.setItem(groupId, JSON.stringify(newSettings));
      this.setState({ zoom: newZoom });
    }
  };

  // Whether to mute sound or not
  toggleSound = () => {
    if (groupId) {
      if (this.state.viewName) {
        this.setState({
          mute: !this.state.mute,
          showPromptToSaveView: true,
        });
      } else {
        let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
        let newSettings = {
          ...savedSettings,
          mute: !this.state.mute,
        };
        localStorage.setItem(groupId, JSON.stringify(newSettings));

        this.setState({
          mute: !this.state.mute,
        });
      }
    }
  };

  toggleActionSound = (action: IAction) => {
    let mutedActions = JSON.parse(localStorage.getItem('mutedActions') || '[]');
    if (mutedActions.includes(action.id)) {
      console.log('remove action');
      mutedActions = mutedActions.filter(function (el: any) {
        return ![action.id].includes(el);
      });
    } else {
      console.log('add action');
      mutedActions.push(action.id);
    }

    // console.log('muted actions', mutedActions)

    localStorage.setItem('mutedActions', JSON.stringify(mutedActions));
  };

  muteAllActions = () => {
    let allActionIds: string[] = [];

    this.props.distinctOrgActions.forEach((action: IAction) => {
      allActionIds.push(action.id);
    });

    localStorage.setItem('mutedActions', JSON.stringify(allActionIds));
  };

  removeAllMutedActions = () => {
    localStorage.setItem('mutedActions', JSON.stringify([]));
  };

  hideLargeCards = (allDepts: IDepartment[]) => {
    if (groupId) {
      if (this.state.viewName) {
        this.setState(
          {
            hideLargeCards: !this.state.hideLargeCards,
            showPromptToSaveView: true,
          },
          () => {
            this.resizeFlowstation(allDepts);
          }
        );
      } else {
        let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
        let newSettings = {
          ...savedSettings,
          hideLargeCards: !this.state.hideLargeCards,
        };
        localStorage.setItem(groupId, JSON.stringify(newSettings));

        this.setState(
          {
            hideLargeCards: !this.state.hideLargeCards,
          },
          () => {
            this.resizeFlowstation(allDepts);
          }
        );
      }
    }
  };

  returnHome = () => {
    console.log('Return home called, deviceId', getQueryVariable('deviceId'));
    if (!getQueryVariable('username')) {
      localStorage.removeItem('lastVisitedFs');
      localStorage.removeItem('lastVisitedView');
      if (getQueryVariable('deviceId')) {
        window.location.replace('/?deviceId=' + getQueryVariable('deviceId'));
      } else {
        window.location.replace('/');
      }
    }
  };

  sendToManageNotifications = () => {
    window.location.replace('/?manageNotifications');
  };

  showConnectionInfo = () => {
    this.setState({
      showConnectionInfo: true,
    });
  };

  hideConnectionInfo = () => {
    this.setState({
      showConnectionInfo: false,
    });
  };

  updateShowMore = () => {
    this.setState({
      updateShowMore: !this.state.updateShowMore,
    });
  };

  togglePreFilterDepartment = (department: string, allDepts: IDepartment[]) => {
    if (this.state.preFilterDepartmentsToShow.includes(department)) {
      console.log('Remove preFilter dept');
      let updatedPreFilterDepartmentsToShow = [
        ...this.state.preFilterDepartmentsToShow,
      ];
      updatedPreFilterDepartmentsToShow =
        updatedPreFilterDepartmentsToShow.filter(function (el) {
          return ![department].includes(el);
        });

      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify =
        preFilteringDeptSettingsToModify.filter(function (el) {
          return ![department].includes(el.deptId);
        });

      this.setState({
        preFilterDepartmentsToShow: updatedPreFilterDepartmentsToShow,
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
        fsDepartmentsToShow: updatedPreFilterDepartmentsToShow,
      });
    } else {
      console.log('Add preFilter dept');
      let updatedPreFilterDepartmentsToShow = [
        ...this.state.preFilterDepartmentsToShow,
      ];
      updatedPreFilterDepartmentsToShow.push(department);

      // Add preFilterDeptSetting if doesn't exist
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      let found = false;
      preFilteringDeptSettingsToModify.forEach(dept => {
        if (dept.deptId === department) {
          found = true;
        }
      });
      if (!found) {
        preFilteringDeptSettingsToModify.push({
          deptId: department,
          hiddenRooms: [],
          providersToShow: [],
          waitingRoomProvidersToShow: [],
          actionsToShow: [],
          actionSwitch: false,
          roomSwitch: false,
          providerSwitch: true,
          hideLargeCards: true,
          hideNonPatientRooms: false,
        });
      }

      this.setState({
        preFilterDepartmentsToShow: updatedPreFilterDepartmentsToShow,
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
        fsDepartmentsToShow: updatedPreFilterDepartmentsToShow,
      });

      this.resizeFlowstation(allDepts);
      console.log('preFilteringDeptSettings', preFilteringDeptSettingsToModify);
      if (groupId) {
        this.props.fetchVisibleFsDepartments(
          groupId,
          updatedPreFilterDepartmentsToShow
        );
      }
    }
  };

  toggleDepartment = (department: string, forceAdd: boolean) => {
    console.log('toggle dept', department);
    let viewId = getQueryVariable('viewId');

    // Check if deptId or id exists in fsDepartments to show to determine to add or remove from storage.
    if (this.state.fsDepartmentsToShow.includes(department) && !forceAdd) {
      // console.log("REMOVE THEM");

      // Now splice/remove both deptId and id from storage and update state
      let updatedDepartmentsToShow = this.state.fsDepartmentsToShow;
      updatedDepartmentsToShow = updatedDepartmentsToShow.filter(function (el) {
        return ![department].includes(el);
      });

      if (viewId) {
        // Modifying view, make state change and prompt option to save changes
        console.log('1');
        this.setState({
          showPromptToSaveView: true,
          fsDepartmentsToShow: updatedDepartmentsToShow,
        });
      } else {
        console.log('2');
        // Not modifying view, save normally to localStorage
        if (groupId) {
          let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
          let newSettings = {
            ...savedSettings,
            fsDepartmentsToShow: updatedDepartmentsToShow,
          };
          localStorage.setItem(groupId, JSON.stringify(newSettings));
        }

        this.setState({
          fsDepartmentsToShow: updatedDepartmentsToShow,
        });
      }
    } else if (!this.state.fsDepartmentsToShow.includes(department)) {
      console.log('3');
      // console.log("ADD THEM");
      let updatedDepartmentsToShow = this.state.fsDepartmentsToShow;
      // if (deptId) {
      //   updatedDepartmentsToShow.push(deptId);
      // }
      if (department) {
        updatedDepartmentsToShow.push(department);
      }

      if (viewId) {
        this.setState({
          showPromptToSaveView: true,
          fsDepartmentsToShow: updatedDepartmentsToShow,
          widthIncreased: true,
          stopZoom: false,
        });
      } else {
        console.log('4');
        // console.log("NEW", updatedDepartmentsToShow);
        if (groupId) {
          let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
          let newSettings = {
            ...savedSettings,
            fsDepartmentsToShow: updatedDepartmentsToShow,
          };
          // console.log("New Settings", newSettings);
          localStorage.setItem(groupId, JSON.stringify(newSettings));
        }

        this.setState({
          fsDepartmentsToShow: updatedDepartmentsToShow,
          widthIncreased: true,
          stopZoom: false,
        });
      }
      if (groupId) {
        this.props.fetchVisibleFsDepartments(
          groupId,
          this.state.fsDepartmentsToShow
        );
      }
    }
    // this.resizeFlowstation(allDepts);
    zoom = null;
    // if (groupId) {
    //   this.props.fetchGroup(groupId);
    // }
    // this.props.fetchSiblingDepts(this.props.siblingDepts);
  };

  showOrganizations = () => {
    this.props.fetchOrganizations();
    this.setState({
      showOrganizationList: true,
    });
  };

  mockOrganization = (orgId: string) => {
    // let userEmail = localStorage.getItem("email");
    // let token = localStorage.getItem("token");
    localStorage.setItem('lastMockedOrganizationId', orgId);

    let token = localStorage.getItem('token');

    let URL = API.REACT_APP_API_GETORGDEPARTMENT + orgId;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    if (getQueryVariable('groupId')) {
      axios
        .get(URL, headers)
        .then(response => {
          console.log('fetchOrganizationDepartments', response);
          if (response.data.data) {
            //check if url contains groupId

            let firstActiveDepartment: any = null;
            response.data.data.forEach((dept: IDepartment) => {
              if (dept.isActive && !firstActiveDepartment) {
                firstActiveDepartment = dept;
              }
            });

            window.location.replace('/?groupId=' + firstActiveDepartment.id);
          }
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    } else {
      window.location.reload();
    }

    // window.location.reload();
  };

  hideAllRooms = (
    allRooms: IRoom[],
    deptId: string,
    allDepts: IDepartment[]
  ) => {
    // let roomsToHide = allRooms.filter((room: IRoom) => {
    //   return room.mongoDeptId === this.state.deptSettingsToModify.id;
    // });
    // let roomIds: string[] = [];
    // roomsToHide.forEach((room: IRoom) => roomIds.push(room.id));

    // console.log('check it', roomsToHide)

    let deptRooms = allRooms.filter((room: IRoom) => {
      return room.mongoDeptId === deptId;
    });
    let roomIds: string[] = [];
    deptRooms.forEach((room: IRoom) => roomIds.push(room.id));

    if (getQueryVariable('newView')) {
      // New view

      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          preFilteringDeptSettingsToModify[i].hiddenRooms = [...roomIds];
        }
      });
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
    } else {
      if (this.state.viewName) {
        // Existing View

        console.log('existing view to save');
        let hiddenRooms: string[] = [...roomIds];

        console.log('hidden rooms', hiddenRooms);

        this.setState(
          {
            hiddenRooms: hiddenRooms,
          },
          () => {
            this.resizeFlowstation(allDepts);
          }
        );

        // Save to view
        let found = false;
        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        let roomIdsToRemoveFromAllDepts: string[] = [];

        allDepts.forEach((dept, deptIndex) => {
          if (dept.id === deptId) {
            // console.log("All rooms", dept)
            dept.rooms.forEach((room, roomIndex) => {
              roomIdsToRemoveFromAllDepts.push(room.id);
            });
          }
        });

        // console.log("We need to remove all these", roomIdsToRemoveFromAllDepts)

        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === deptId) {
            console.log('Found the dept!!!!', viewDeptSettingsToModify[index]);
            // dept.hiddenRooms = hiddenRooms;
            viewDeptSettingsToModify[index] = {
              ...dept,
              //  hiddenRooms: [],
              hiddenRooms: hiddenRooms,
            };
            found = true;
          } else {
            let array = [...viewDeptSettingsToModify[index].hiddenRooms];
            array = _.pullAll(
              [...viewDeptSettingsToModify[index].hiddenRooms],
              roomIdsToRemoveFromAllDepts
            );
            viewDeptSettingsToModify[index].hiddenRooms = array;
          }
          return null;
        });

        console.log('updated', viewDeptSettingsToModify);

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: true,
            providerSwitch: false,
            hideLargeCards: true,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState(
          {
            showPromptToSaveView: true,
            viewDeptSettings: viewDeptSettingsToModify,
          },
          () => {
            console.log('state viewDeptSettings', this.state.viewDeptSettings);
            this.resizeFlowstation(allDepts);
          }
        );
      } else {
        // Standard FS - Update localstorage
        let savedSettings = JSON.parse(localStorage.getItem(deptId) || '{}');

        let newSettings = {
          ...savedSettings,
          hiddenRooms: [...roomIds],
        };
        localStorage.setItem(deptId, JSON.stringify(newSettings));

        this.setState(
          {
            hiddenRooms: [...roomIds],
          },
          () => {
            this.resizeFlowstation(allDepts);
          }
        );
      }
    }
  };

  viewAllRooms = (
    allRooms: IRoom[],
    deptId: string,
    allDepts: IDepartment[]
  ) => {
    let roomsToHide = allRooms.filter((room: IRoom) => {
      return room.mongoDeptId === this.state.deptSettingsToModify.id;
    });
    let roomIds: string[] = [];
    roomsToHide.forEach((room: IRoom) => roomIds.push(room.id));

    if (getQueryVariable('newView')) {
      // New view
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          preFilteringDeptSettingsToModify[i].hiddenRooms = [];
        }
      });
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
    } else {
      if (this.state.viewName) {
        // Existing view

        console.log('existing view to save');
        let hiddenRooms: string[] = [];
        hiddenRooms = _.pullAll([...this.state.hiddenRooms], roomIds);

        console.log('hidden rooms', hiddenRooms);

        this.setState(
          {
            hiddenRooms: hiddenRooms,
          },
          () => {
            this.resizeFlowstation(allDepts);
          }
        );

        // Save to view
        let found = false;
        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        let roomIdsToRemoveFromAllDepts: string[] = [];

        allDepts.forEach((dept, deptIndex) => {
          if (dept.id === deptId) {
            // console.log("All rooms", dept)
            dept.rooms.forEach((room, roomIndex) => {
              roomIdsToRemoveFromAllDepts.push(room.id);
            });
          }
        });

        // console.log("We need to remove all these", roomIdsToRemoveFromAllDepts)

        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === deptId) {
            console.log('Found the dept!!!!', viewDeptSettingsToModify[index]);
            // dept.hiddenRooms = hiddenRooms;
            viewDeptSettingsToModify[index] = {
              ...dept,
              hiddenRooms: [],
            };
            found = true;
          } else {
            let array = [...viewDeptSettingsToModify[index].hiddenRooms];
            array = _.pullAll(
              [...viewDeptSettingsToModify[index].hiddenRooms],
              roomIdsToRemoveFromAllDepts
            );
            viewDeptSettingsToModify[index].hiddenRooms = array;
          }
          return null;
        });

        console.log('updated', viewDeptSettingsToModify);

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: true,
            providerSwitch: false,
            hideLargeCards: true,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState(
          {
            showPromptToSaveView: true,
            viewDeptSettings: viewDeptSettingsToModify,
          },
          () => {
            console.log('state viewDeptSettings', this.state.viewDeptSettings);
            this.resizeFlowstation(allDepts);
          }
        );
      } else {
        // Standard FS - Update localstorage
        console.log('saving room selectiong to local storage');

        let hiddenRooms: string[] = [];
        hiddenRooms = _.pullAll([...this.state.hiddenRooms], roomIds);

        let deptSettings = JSON.parse(localStorage.getItem(deptId) || '{}');

        let newSettings = {
          ...deptSettings,
          hiddenRooms: [],
        };

        localStorage.setItem(deptId, JSON.stringify(newSettings));

        this.setState(
          {
            hiddenRooms: hiddenRooms,
          },
          () => {
            this.resizeFlowstation(allDepts);
          }
        );
      }
    }
  };

  toggleRoom = (
    room: string,
    deptId: string,
    allDepts: IDepartment[],
    removeRoom: boolean
  ) => {
    if (getQueryVariable('newView')) {
      console.log('prefilter toggle room');

      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === deptId) {
          let array = [...preDept.hiddenRooms];
          if (array.includes(room)) {
            console.log('remove room');
            array = array.filter(function (el) {
              return ![room].includes(el);
            });
          } else {
            if (removeRoom) {
              console.log('add room');
              array.push(room);
            }
          }
          preFilteringDeptSettingsToModify[i].hiddenRooms = array;
        }
      });
      console.log('updated', preFilteringDeptSettingsToModify);
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
    } else {
      if (this.state.viewName) {
        // console.log("hidden rooms", this.state.hiddenRooms);
        // MODIFY VIEW, SO JUST MODIFY STATE OF HIDDEN ROOMS
        let hiddenRooms: string[] = [];
        let deleted: boolean = false;

        // console.log("Before", this.state.hiddenRooms)
        if (this.state.hiddenRooms.indexOf(room) > -1) {
          // console.log("Already Exists");
          let array = [...this.state.hiddenRooms];

          let index = array.indexOf(room);
          if (index !== -1) {
            array.splice(index, 1);
            hiddenRooms = array;
            deleted = true;
            this.setState({ hiddenRooms: array }, () => {
              this.resizeFlowstation(allDepts);
            });
          }
        } else {
          // console.log("Doesn't exist");
          if (removeRoom) {
            hiddenRooms = [...this.state.hiddenRooms, room];
            this.setState(
              {
                hiddenRooms: [...this.state.hiddenRooms, room],
              },
              () => {
                // console.log("Toggle view room, state", this.state.hiddenRooms);
                this.resizeFlowstation(allDepts);
              }
            );
          }
        }

        // console.log("After", this.state.hiddenRooms)

        // console.log("done", hiddenRooms);
        // Save to view
        let found = false;
        let viewDeptSettingsToModify = this.state.viewDeptSettings;
        let roomIdsToRemoveFromAllDepts: string[] = [room];

        // console.log("We need to remove all these", roomIdsToRemoveFromAllDepts)

        viewDeptSettingsToModify.map((dept, index) => {
          if (dept.deptId === deptId) {
            // console.log("Found the dept!!!!", viewDeptSettingsToModify[index])

            // check if room already exists in viewDeptSettingsToModify[index].hiddenRooms
            // console.log("Already Exists");
            let array = [...viewDeptSettingsToModify[index].hiddenRooms];

            // remove room from array
            let roomIndex: number = array.indexOf(room);
            if (roomIndex !== -1) {
              // console.log("Delete Room")
              array.splice(roomIndex, 1);
              viewDeptSettingsToModify[index].hiddenRooms = array;
            } else {
              // add room to hiddenRooms
              // console.log("Add room")
              if (!deleted) {
                viewDeptSettingsToModify[index].hiddenRooms = [
                  ...viewDeptSettingsToModify[index].hiddenRooms,
                  room,
                ];
              }
            }
            found = true;
          } else {
            let array = [...viewDeptSettingsToModify[index].hiddenRooms];
            array = _.pullAll(
              [...viewDeptSettingsToModify[index].hiddenRooms],
              roomIdsToRemoveFromAllDepts
            );
            viewDeptSettingsToModify[index].hiddenRooms = array;
          }

          return null;
        });

        // console.log("done", viewDeptSettingsToModify)

        if (!found) {
          console.log('doesnt exist, add a new dept');
          let newDeptSetting: any = {
            deptId: deptId,
            hiddenRooms: hiddenRooms,
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: true,
            providerSwitch: false,
            hideLargeCards: true,
          };
          viewDeptSettingsToModify.push(newDeptSetting);
        }

        this.setState(
          {
            showPromptToSaveView: true,
            viewDeptSettings: viewDeptSettingsToModify,
          },
          () => {
            console.log('state viewDeptSettings', this.state.viewDeptSettings);
            this.resizeFlowstation(allDepts);
          }
        );
      } else {
        // NO VIEW, SIMPLY MODIFY LOCAL STORAGE
        console.log('saving room selectiong to local storage');
        let deptSettings = JSON.parse(localStorage.getItem(deptId) || '{}');
        if (deptSettings.hiddenRooms.indexOf(room) > -1) {
          let array = [...deptSettings.hiddenRooms];

          let index = array.indexOf(room);
          if (index !== -1) {
            array.splice(index, 1);

            let savedSettings = JSON.parse(
              localStorage.getItem(deptId) || '{}'
            );
            let newSettings = {
              ...savedSettings,
              hiddenRooms: array,
            };
            localStorage.setItem(deptId, JSON.stringify(newSettings));

            this.setState({ hiddenRooms: array }, () => {
              this.resizeFlowstation(allDepts);
            });
          }
        } else {
          if (removeRoom) {
            let savedSettings = JSON.parse(
              localStorage.getItem(deptId) || '{}'
            );
            let newSettings = {
              ...savedSettings,
              hiddenRooms: [...deptSettings.hiddenRooms, room],
            };
            localStorage.setItem(deptId, JSON.stringify(newSettings));
            this.setState(
              {
                hiddenRooms: [...this.state.hiddenRooms, room],
              },
              () => {
                this.resizeFlowstation(allDepts);
              }
            );
          }
        }
      }
    }
  };

  closeInstallChromeMessage = () => {
    console.log('Close message');
    this.setState({
      installChromeMessage: false,
    });
  };

  myTimer = () => {
    this.props.fetchStaffLocations(
      this.props.loggedInUserData.id,
      this.props.groupData.orgId
    );
  };

  openLocateStaff = () => {
    if (this.props.groupData.orgId) {
      console.log('Good id', this.props.groupData.orgId);
      ReactGA.event({
        category: 'Flowstation',
        action: 'Locating staff',
        label: 'Locating staff',
      });

      if (this.props.department.id && this.props.loggedInUserData.id) {
        this.props.logAction(
          'Locating Staff',
          this.props.department.id,
          this.props.loggedInUserData.id
        );
      }

      clearInterval(staffLocationTimerVar);
      this.setState({
        locateStaffPopover: true,
      });

      this.props.fetchStaffLocations(
        this.props.loggedInUserData.id,
        this.props.groupData.orgId
      );

      let staffLocationStartTimer = () => {
        staffLocationTimerVar = setInterval(this.myTimer, 5000);
      };
      staffLocationStartTimer();
    }
  };

  closeLocateStaff = () => {
    clearInterval(staffLocationTimerVar);
    this.setState({
      locateStaffPopover: false,
    });
  };

  locateStaff = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      staffSearchKeyword: event.target.value,
    });
  };

  userNotification = (data: {
    message: string;
    imageUrl: string;
    roomId: string;
    deptId: string;
  }) => {
    // Check if mobile.
    console.log('SHOW NOTIFICATION!', data);
    let mobile =
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1;

    // Only display on desktop
    if (!mobile) {
      // Function to fire notification
      let fireNotification = () => {
        console.log('Firing notification.');
        // https://developer.mozilla.org/en-US/docs/Web/API/notification
        let myNotification = new Notification('SyncTimes', {
          body: data.message,
          // silent: true,
          icon: data.imageUrl,
        });

        myNotification.onclick = function (event) {
          if (data.roomId) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            // window.open("https://app.synctimes.com/?groupId=" + data.deptId + "&roomId=" + data.roomId, "_blank");
            window.location.replace(
              'https://app.synctimes.com/?groupId=' +
                data.deptId +
                '&roomId=' +
                data.roomId
            );
          } else {
            if (data.deptId) {
              window.location.replace(
                'https://app.synctimes.com/?groupId=' + data.deptId
              );
            } else {
              myNotification.close();
            }
          }
        };
      };

      if (!('Notification' in window)) {
        alert('This browser does not support desktop notification');
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        fireNotification();
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            fireNotification();
          }
        });
      }
    } else {
      // alert("Notification Error");
      console.log('Error showing notification, potential mobile device');
    }
  };

  reloadPage = () => {
    window.location.reload();
  };

  resizeFlowstation = (allDepts: IDepartment[]) => {
    if (this.state.fsDepartmentsToShow.length > 0) {
      if (this.state.autoZoom) {
        let visibleDepts: IDepartment[] = [];

        allDepts.forEach((dept: IDepartment) => {
          if (
            // Checks if dept is visible, add to list of visible depts.
            _.includes(this.state.fsDepartmentsToShow, dept.id.toString())
          ) {
            visibleDepts.push(dept);
          }
        });

        if (
          // Ensure JSX elements are rendered prior
          document.getElementById('fsMainContainer') &&
          document.getElementById('fsAppBar') &&
          document.getElementById('deptName') &&
          document.getElementById('flowstation')
        ) {
          let windowHeight = window.innerHeight;
          let windowWidth = window.innerWidth;
          // @ts-ignore
          let fsAppBarHeight =
            document.getElementById('fsAppBar').clientHeight + 10;
          let zoomPercent = 1;
          let isIncreasing;
          let isEscapeLoop = false;
          let incrementAmount = 0.02;
          let deptNameCardPerDept = 1;
          let reportingCardsPerDept = 0;

          // Total card container height
          windowHeight = windowHeight - fsAppBarHeight;

          let visibleRooms = 0;

          visibleDepts.forEach((dept: IDepartment) => {
            if (this.state.hideLargeCards) {
              reportingCardsPerDept = 0;
            } else {
              if (dept.dailyData && dept.dailyData.length > 0) {
                reportingCardsPerDept = 2;
              } else {
                reportingCardsPerDept = 0;
              }
            }

            visibleRooms +=
              dept.rooms.length + deptNameCardPerDept + reportingCardsPerDept;

            if (dept.isFrontDeskIntegration) {
              // Add lobby card
              visibleRooms++;
            }

            let deptSettings: any;

            let viewId = getQueryVariable('viewId');

            // Fetching the dept settings condionally from either State or Local Storage
            if (viewId) {
              // console.log("viewSettings", this.state.viewDeptSettings);
              let found = false;
              // console.log("VIEW DEPT SETTINGS", this.state.viewDeptSettings);
              this.state.viewDeptSettings.forEach(deptViewSetting => {
                if (deptViewSetting.deptId === dept.id) {
                  // console.log("FOUND");
                  deptSettings = deptViewSetting;
                  found = true;
                }
              });

              if (!found) {
                // console.log("NOT FOUND", dept);
                // create setting if doesn't exist in local storage?
                deptSettings = JSON.parse(
                  localStorage.getItem(dept.id) || '{}'
                );
              }
            } else {
              deptSettings = JSON.parse(localStorage.getItem(dept.id) || '{}');
            }

            // Filtering by Provider
            // console.log("deptSettings", deptSettings);
            if (
              deptSettings.providerSwitch &&
              deptSettings.providersToShow.length > 0
            ) {
              dept.rooms.forEach(room => {
                let showRoom = false;
                deptSettings.providersToShow.forEach((provider: string) => {
                  room.roomAssignments.forEach(
                    (providerAssignment: { userId: string }) => {
                      if (providerAssignment.userId === provider) {
                        showRoom = true;
                      }
                    }
                  );
                  if (!room.isPatientRoom) {
                    showRoom = true;
                  }
                });
                if (!showRoom) {
                  visibleRooms--;
                }
              });
            }

            // Filtering by Room
            if (deptSettings.roomSwitch) {
              let uniqRooms = _.uniq(deptSettings.hiddenRooms);
              // console.log("uniq", uniqRooms);
              uniqRooms.forEach((id: string) => {
                if (_.some(dept.rooms, ['id', id])) {
                  visibleRooms--;
                  // console.log("ding", _.some(dept.rooms, ["id", id]));
                }
              });
            }

            // Filtering by Action
            if (deptSettings.actionSwitch) {
              dept.rooms.forEach(room => {
                if (room.isPatientRoom) {
                  let found = false;
                  room.roomActions.forEach((action: IAction) => {
                    if (
                      deptSettings.actionsToShow.indexOf(action.id) > -1 &&
                      !found
                    ) {
                      found = true;
                    }
                  });
                  if (found === false) {
                    visibleRooms--;
                  }
                }
              });
            }
          });

          // console.log("visible rooms", visibleRooms);

          let cardWidth = 262;
          let cardHeight = 349;

          while (!isEscapeLoop) {
            let cardsHorizontal = Math.floor(
              windowWidth / (zoomPercent * cardWidth)
            );
            let currentVerticalRows = Math.ceil(visibleRooms / cardsHorizontal);
            let totalHeight = zoomPercent * cardHeight * currentVerticalRows;

            // Checking if webapp FS view is greater than the browser height (Scrollbar visible)
            if (totalHeight > windowHeight) {
              //zoom goes down
              if (isIncreasing === null || !isIncreasing) {
                isIncreasing = false;
                zoomPercent = zoomPercent - incrementAmount;
              } else {
                //escape the loop, leave zoom
                isEscapeLoop = true;
              }
            } else {
              //zoom goes up
              isIncreasing = true;
              zoomPercent = zoomPercent + incrementAmount;
            }
          }

          zoom = zoomPercent * 95;
          // console.log('zoom', zoom)

          // firefox zoom values
          firefoxZoom = zoom / 100;
          // console.log("firefox zoom", firefoxZoom)
          firefoxWidth = window.innerWidth / firefoxZoom;

          firefoxWidth = firefoxWidth - 35;
          // console.log("firefox width", firefoxWidth)
        }
      }
    }
  };

  startInteraction = () => {
    if (this.state.promptToEnableSounds) {
      this.setState({
        promptToEnableSounds: false,
      });
    }
  };

  testForceUpdate = () => {
    console.log(
      '%c Flowstation Forced Update ',
      'background: #0095DA; color: #ffffff'
    );
    if (groupId) {
      // this.props.fetchGroup(groupId);
      // this.props.fetchDepartment(groupId);
      this.props.fetchVisibleFsDepartments(
        groupId,
        this.state.fsDepartmentsToShow
      );

      // Reset cached sounds to re-cache
      soundsLoaded = false;
      allSounds = [];
    }
  };

  handleViewTourCallback = (data: any) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({
        viewStepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('DONE!');

      let view = getQueryVariable('viewId');

      if (window.history.pushState) {
        let deviceId = null;
        if (getQueryVariable('deviceId')) {
          deviceId = getQueryVariable('deviceId');
        }

        if (deviceId && view) {
          var deviceIdUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            '?viewId=' +
            view +
            '&deviceId=' +
            deviceId;
          window.history.pushState({ path: deviceIdUrl }, '', deviceIdUrl);
        } else {
          var newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            '?viewId=' +
            view;
          window.history.pushState({ path: newurl }, '', newurl);
        }
      }

      if (status === 'finished') {
        // if (getQueryVariable("startTour") && this.props.loggedInUserData.isAdmin) {
        //   // this.setState({ run: false });
        //   window.location.replace("/admin?startTour=true");
        // } else {
        //   // this.setState({ run: false });
        //   window.location.replace("/?finishTour=true");
        // }
        this.setState({ run: false });
      } else {
        this.setState({ run: false });
      }
    }
  };

  handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    // console.log("type", type);

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      // console.log("INDEX", index);

      // this.setState({
      //   stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      //   open: true,
      // });

      if (index === 10) {
        this.setState({
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
          right: true,
        });
      } else {
        this.setState({
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        });
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      if (status === 'finished') {
        if (
          getQueryVariable('startTour') &&
          this.props.loggedInUserData.isAdmin
        ) {
          // this.setState({ run: false });
          window.location.replace('/admin?startTour=true');
        } else {
          // this.setState({ run: false });
          window.location.replace('/?finishTour=true');
        }
      } else {
        this.setState({ run: false });
      }
    }

    // if (type === "beacon") {
    //   // console.log("KILL");
    //   this.setState({
    //     run: false,
    //   });
    // }

    // console.groupCollapsed(type);
    // console.log(data);
    // console.groupEnd();
  };

  handleColorChange = (color: { hex: string }, dept: IDepartment) => {
    // this.setState({ background: color.hex });
    // console.log("new hex", color.hex, dept.id);

    this.setState({
      unsavedDeptColor: color.hex,
      unsavedDeptColorDeptId: dept.id,
    });
  };

  saveDepartmentColor = (deptToReset: string | null) => {
    let settingsToUpdate: { deptColors: { deptId: string; color: string }[] } =
      { deptColors: [] };

    if (this.props.loggedInUserData.userSettings) {
      // console.log("settings exist");
      settingsToUpdate = JSON.parse(this.props.loggedInUserData.userSettings);
    } else {
      // console.log("no settings found");
      settingsToUpdate = { deptColors: [] };
    }

    // settingsToUpdate = {
    //   ...settingsToUpdate,
    //   userSettings: { deptColors: [{ deptId: this.state.unsavedDeptColorDeptId, color: this.state.unsavedDeptColor }] },
    // };

    // console.log("settingsToUpdate", settingsToUpdate);

    if (!deptToReset) {
      if (settingsToUpdate.deptColors) {
        // deptColors arr already exists, modify existing dept / add to

        // Find existing index
        let deptToModifyIndex = settingsToUpdate.deptColors.findIndex(
          (dept: { deptId: string }) =>
            dept.deptId === this.state.unsavedDeptColorDeptId
        );
        if (deptToModifyIndex >= 0) {
          // found it
          // console.log("exists");
          settingsToUpdate.deptColors[deptToModifyIndex] = {
            deptId: this.state.unsavedDeptColorDeptId,
            color: this.state.unsavedDeptColor,
          };
        } else {
          // dept doesn't exist in settings, just add it
          // console.log("doesn't exist, add it");

          settingsToUpdate.deptColors.push({
            deptId: this.state.unsavedDeptColorDeptId,
            color: this.state.unsavedDeptColor,
          });
        }
      } else {
        // Missing deptColors from user settings, add it with selected colors
        // console.log("no deptcolors have been assigned, create the arr");
        settingsToUpdate = {
          ...settingsToUpdate,
          deptColors: [
            {
              deptId: this.state.unsavedDeptColorDeptId,
              color: this.state.unsavedDeptColor,
            },
          ],
        };
      }
    } else {
      // Remove
      // console.log("remove color");
      settingsToUpdate.deptColors = _.reject(
        settingsToUpdate.deptColors,
        (d: { deptId: string }) => {
          return d.deptId === deptToReset;
        }
      );

      // console.log("removed", settingsToUpdate);
    }

    // console.log("check", settingsToUpdate);

    let payload = {
      ...this.props.loggedInUserData,
      userSettings: JSON.stringify(settingsToUpdate),
    };

    // console.log("payload", payload);

    // console.log("string payload", JSON.stringify(payload));

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SETUSERSETTINGS, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        // console.log("RES!", res.json());
        return res.json();
      })
      .then(data => {
        // console.log("res", data);
        let userEmail = localStorage.getItem('email');
        let token = localStorage.getItem('token');
        if (userEmail && token) {
          this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
        }

        if (deptToReset) {
          this.setState({
            unsavedDeptColor: '',
            unsavedDeptColorDeptId: '',
            showColorPicker: '',
          });
        } else {
          this.setState({
            // unsavedDeptColor: "",
            // unsavedDeptColorDeptId: "",
            showColorPicker: '',
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  moveDeptUp = (deptId: string, allDepts: IDepartment[]) => {
    // console.log("depts to show", this.state.fsDepartmentsToShow);

    let deptsToAdjust: string[] = [];
    allDepts.forEach((dept: IDepartment) => {
      if (this.state.fsDepartmentsToShow.indexOf(dept.id) > -1) {
        deptsToAdjust.push(dept.id);
      }
    });

    // console.log("depts to adjust", deptsToAdjust);

    let from = deptsToAdjust.indexOf(deptId);

    // console.log(deptId + " is at index " + from);
    // console.log("To", from - 1);

    if (from !== 0) {
      let el = deptsToAdjust[from];
      deptsToAdjust.splice(from, 1);
      deptsToAdjust.splice(from - 1, 0, el);

      if (deptsToAdjust.length > 0) {
        let deptIdOrder = [...deptsToAdjust];
        let settingsToUpdate = {};

        if (this.props.loggedInUserData.userSettings) {
          // console.log("settings exist");
          let existingSettings = JSON.parse(
            this.props.loggedInUserData.userSettings
          );
          settingsToUpdate = {
            ...existingSettings,
            deptOrder: deptIdOrder,
          };
        } else {
          // console.log("no settings found");
          settingsToUpdate = { deptOrder: deptIdOrder };
        }

        let payload = {
          ...this.props.loggedInUserData,
          userSettings: JSON.stringify(settingsToUpdate),
        };

        console.log('payload', payload);
        let token = localStorage.getItem('token');
        fetch(API.REACT_APP_API_SETUSERSETTINGS, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(res => {
            // console.log("RES!", res.json());
            return res.json();
          })
          .then(data => {
            // console.log("res", data);
            let userEmail = localStorage.getItem('email');
            let token = localStorage.getItem('token');
            if (userEmail && token) {
              this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
            }
          });

        if (getQueryVariable('viewId')) {
          this.setState({
            showPromptToSaveView: true,
          });
        }
      }
    }
  };

  moveDeptDown = (deptId: string, allDepts: IDepartment[]) => {
    // console.log("depts to show", this.state.fsDepartmentsToShow);

    let deptsToAdjust: string[] = [];
    allDepts.forEach((dept: IDepartment) => {
      if (this.state.fsDepartmentsToShow.indexOf(dept.id) > -1) {
        deptsToAdjust.push(dept.id);
      }
    });

    // console.log("depts to adjust", deptsToAdjust);

    let from = deptsToAdjust.indexOf(deptId);

    // console.log(deptId + " is at index " + from);
    // console.log("To", from + 1);

    if (from !== deptsToAdjust.length) {
      let el = deptsToAdjust[from];
      deptsToAdjust.splice(from, 1);
      deptsToAdjust.splice(from + 1, 0, el);

      if (deptsToAdjust.length > 0) {
        let deptIdOrder = [...deptsToAdjust];
        let settingsToUpdate = {};

        if (this.props.loggedInUserData.userSettings) {
          // console.log("settings exist");
          let existingSettings = JSON.parse(
            this.props.loggedInUserData.userSettings
          );
          settingsToUpdate = {
            ...existingSettings,
            deptOrder: deptIdOrder,
          };
        } else {
          // console.log("no settings found");
          settingsToUpdate = { deptOrder: deptIdOrder };
        }

        let payload = {
          ...this.props.loggedInUserData,
          userSettings: JSON.stringify(settingsToUpdate),
        };

        console.log('payload', payload);
        let token = localStorage.getItem('token');
        fetch(API.REACT_APP_API_SETUSERSETTINGS, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
          .then(res => {
            // console.log("RES!", res.json());
            return res.json();
          })
          .then(data => {
            // console.log("res", data);
            let userEmail = localStorage.getItem('email');
            let token = localStorage.getItem('token');
            if (userEmail && token) {
              this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
            }
          });

        if (getQueryVariable('viewId')) {
          this.setState({
            showPromptToSaveView: true,
          });
        }
      }
    }
  };

  moveDept = (from: number, to: number, allDepts: IDepartment[]) => {
    console.log('f:' + from, 'to' + to);
    if (to >= 0) {
      let deptsToReorder = allDepts;

      let f = deptsToReorder.splice(from, 1)[0];

      deptsToReorder.splice(to, 0, f);

      let deptIdOrder: string[] = [];
      deptsToReorder.forEach((dept: any) => {
        deptIdOrder.push(dept.id);
      });

      // now save it

      console.log('save this', deptIdOrder);

      let settingsToUpdate = {};

      if (this.props.loggedInUserData.userSettings) {
        // console.log("settings exist");
        let existingSettings = JSON.parse(
          this.props.loggedInUserData.userSettings
        );
        settingsToUpdate = {
          ...existingSettings,
          deptOrder: deptIdOrder,
        };
      } else {
        // console.log("no settings found");
        settingsToUpdate = { deptOrder: deptIdOrder };
      }

      let payload = {
        ...this.props.loggedInUserData,
        userSettings: JSON.stringify(settingsToUpdate),
      };

      console.log('payload', payload);
      let token = localStorage.getItem('token');
      fetch(API.REACT_APP_API_SETUSERSETTINGS, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("RES!", res.json());
          return res.json();
        })
        .then(data => {
          // console.log("res", data);
          let userEmail = localStorage.getItem('email');
          let token = localStorage.getItem('token');
          if (userEmail && token) {
            this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
          }
        });

      if (getQueryVariable('viewId')) {
        this.setState({
          showPromptToSaveView: true,
        });
      }
    }
  };

  closeColorPicker = () => {
    // this.setState({ showColorPicker: false, unsavedDeptColor: "", unsavedDeptColorDeptId: "" });
    this.setState({
      showColorPicker: 'none',
      unsavedDeptColor: '',
      unsavedDeptColorDeptId: '',
    });
  };

  openDepartmentSettingsDialog = (dept: IDepartment) => {
    this.props.logAction(
      'Filtering Flowstation',
      dept.id,
      this.props.loggedInUserData.id
    );

    if (this.state.viewName) {
      // console.log("view name found");
      let found = false;
      let viewDeptSettingsToModify = this.state.viewDeptSettings;
      viewDeptSettingsToModify.map((viewDept, index) => {
        if (viewDept.deptId === dept.id) {
          // console.log("found it");
          found = true;
        }
        return null;
      });

      if (!found) {
        console.log('open department settings. doesnt exist, add a new dept');
        let newDeptSetting: any = {
          deptId: dept.id,
          hiddenRooms: [],
          providersToShow: [],
          waitingRoomProvidersToShow: [],
          actionsToShow: [],
          actionSwitch: false,
          roomSwitch: false,
          providerSwitch: true,
          hideLargeCards: true,
        };
        viewDeptSettingsToModify.push(newDeptSetting);
        this.setState({
          viewDeptSettings: viewDeptSettingsToModify,
        });
      }
    } else {
      console.log('No existing view found');
    }

    this.setState({
      deptSettingsToModify: dept,
      openDeptDialog: true,
      draggableKey: dept.id,
    });
    let URL = API.REACT_APP_API_PROVIDERASSIGNMENTUSERS + dept.orgId;
    console.log('url', URL);
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        console.log('response!', response.data.data);

        let allProviders = response.data.data.allProviders;

        let pArr: IProvider[] = [];

        if (getQueryVariable('newView')) {
          // get default value autocomplete provider when pre filtering
          if (this.state.preFilteringDeptSettings) {
            this.state.preFilteringDeptSettings.forEach(deptSetting => {
              if (deptSetting.deptId === dept.id) {
                deptSetting.providersToShow.forEach(
                  (providerToShow: string) => {
                    allProviders.forEach((p: IProvider) => {
                      if (providerToShow === p.id) {
                        pArr.push(p);
                      }
                    });
                  }
                );
              }
            });
          }
        } else {
          let viewId = getQueryVariable('viewId');
          if (viewId) {
            // Figure out providers to view based on view data?
            console.log('view fetched', this.state.savedViewDetails);
            if (this.state.savedViewDetails) {
              this.state.savedViewDetails.viewSettings.deptSettings.forEach(
                (savedDeptSetting: IDepartmentSetting) => {
                  if (savedDeptSetting.deptId === dept.id) {
                    savedDeptSetting.providersToShow.forEach(
                      (savedProvider: string) => {
                        allProviders.forEach((p: IProvider) => {
                          if (savedProvider === p.id) {
                            pArr.push(p);
                          }
                        });
                      }
                    );
                  }
                }
              );
            }
          } else {
            let savedProvidersToView = JSON.parse(
              localStorage.getItem(dept.id) || '{}'
            ).providersToShow;
            savedProvidersToView.forEach((savedProvider: string) => {
              allProviders.forEach((p: IProvider) => {
                if (savedProvider === p.id) {
                  pArr.push(p);
                }
              });
            });
          }
        }

        this.setState({
          commonProviders: response.data.data.frequentProviders,
          allProviders: response.data.data.allProviders,
          showProviders: true,
          autoCompleteProvidersToView: pArr,
          gotDeptProviders: true,
        });
        // console.log("sent!");
        // console.log('new state', this.state);
      })
      .catch(err => {
        console.log('Error fetching providers: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  showReadOnlyAlert = () => {
    if (!this.state.showReadOnlyAlert) {
      console.log('showReadOnlyAlert');
      setTimeout(() => {
        this.setState({
          showReadOnlyAlert: false,
        });
      }, 5000);
    }

    this.setState({
      showReadOnlyAlert: true,
    });
  };

  updateFlowstationURLView = async (viewId: string) => {
    console.log('update flowstation url');

    const deviceId = getQueryVariable('deviceId');
    console.log('deviceId', deviceId);

    if (!deviceId) return;

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(API.REACT_APP_API_UPDATEFLOWSTATIONURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          deviceId: deviceId,
          newViewId: viewId,
        }),
      });

      if (response.ok) {
        console.log('URL saved successfully');
      } else {
        console.error('Error saving URL:', response.status);
      }
    } catch (error) {
      console.error('Error saving URL:', error);
    }
  };

  updateFlowstationURLGroup = async (groupId: string) => {
    console.log('updateFlowstationURLGroup', 'update flowstation url');

    const deviceId = getQueryVariable('deviceId');
    console.log('updateFlowstationURLGroup', {
      deviceId,
      url: API.REACT_APP_API_UPDATEFLOWSTATIONURL,
    });

    if (!deviceId) return;

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(API.REACT_APP_API_UPDATEFLOWSTATIONURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          deviceId: deviceId,
          newGroupId: groupId,
        }),
      });

      if (response.ok) {
        console.log('URL saved successfully');
      } else {
        console.error('Error saving URL:', response.status);
      }
    } catch (error) {
      console.error('Error saving URL:', error);
    }
  };

  // Create a new view.
  createView = () => {
    console.log('create view');

    let settingsToUpdate: any = {};
    if (this.props.loggedInUserData.userSettings) {
      // console.log("settings exist");

      let savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      if (savedSettings.userViews) {
        settingsToUpdate = savedSettings;
      } else {
        settingsToUpdate = { ...savedSettings, userViews: [] };
      }
    }

    let viewId = uniqid();

    let name;
    if (this.state.newViewName.length === 0) {
      name =
        'View created at ' + moment().tz(timeZone).format('MM/DD/YY, h:mm a');
    } else {
      name = this.state.newViewName;
    }

    let viewData = {
      viewId: viewId,
      defaultDeptId: groupId,
      viewName: name,
      viewSettings: {
        zoom: isMobile ? 50 : 100,
        mute: false,
        autoZoom: true,
        hideLargeCards: true,
        fsDepartmentsToShow: this.state.preFilterDepartmentsToShow,
        deptSettings: this.state.preFilteringDeptSettings,
      },
    };

    console.log('View data', viewData);

    if (settingsToUpdate.userViews) {
      settingsToUpdate.userViews.push(viewData);
    } else {
      settingsToUpdate.userViews = [viewData];
    }

    console.log('check it', settingsToUpdate);

    let payload = {
      ...this.props.loggedInUserData,
      userSettings: JSON.stringify(settingsToUpdate),
    };

    // console.log("payload", payload);

    let token = localStorage.getItem('token');
    fetch(API.REACT_APP_API_SETUSERSETTINGS, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        // console.log("RES!", res.json());
        return res.json();
      })
      .then(data => {
        console.log('res', data);
        // let deptId = groupId;

        if (getQueryVariable('deviceId')) {
          let deviceId = getQueryVariable('deviceId');
          window.location.replace(
            '?viewId=' + viewId + '&deviceId=' + deviceId
          );
        } else {
          window.location.replace('?viewId=' + viewId + '&viewCreated=true');
        }
      })
      .catch(err => {
        console.log('err', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  // Save the new view
  saveAsNewView = (currentViewInfo: IView) => {
    if (currentViewInfo.publisherId) {
      let newViewId = uniqid();

      // console.log("currentViewInfo", currentViewInfo);

      let newViewData = {
        ...currentViewInfo,
        viewId: newViewId,
        viewName: this.state.newViewName,
        viewSettings: {
          ...currentViewInfo.viewSettings,
          mute: this.state.mute,
          autoZoom: true,
          hideLargeCards: this.state.hideLargeCards,
          fsDepartmentsToShow: this.state.fsDepartmentsToShow,
          deptSettings: this.state.viewDeptSettings,
        },
      };

      delete newViewData.publisherId;
      delete newViewData.publisher;

      // console.log("done", newViewData);

      let settingsToUpdate = null;

      // console.log("USER:", this.props.loggedInUserData);
      if (this.props.loggedInUserData.userSettings.length > 0) {
        // User settings exist.

        settingsToUpdate = JSON.parse(this.props.loggedInUserData.userSettings);
        if (settingsToUpdate.userViews) {
        } else {
          // userViews field missing, create it.
          settingsToUpdate = { ...settingsToUpdate, userViews: [] };
        }
      } else {
        // User settings missing, create with empty userViews
        settingsToUpdate = { userViews: [] };
      }

      settingsToUpdate.userViews.push(newViewData);
      console.log('latest', settingsToUpdate);

      let updatedUserSettings = JSON.stringify(settingsToUpdate);

      let payload = {
        ...this.props.loggedInUserData,
        userSettings: updatedUserSettings,
      };

      console.log('payload', payload);

      let token = localStorage.getItem('token');
      fetch(API.REACT_APP_API_SETUSERSETTINGS, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("RES!", res.json());
          return res.json();
        })
        .then(data => {
          console.log('Updated res', data);
          window.location.replace('/?viewId=' + newViewId);
        })
        .catch(err => {
          console.log('err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    } else {
      // console.log("Update existing view");
      let savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      let viewId = getQueryVariable('viewId');

      let newViewId = uniqid();

      // console.log("State hidden rooms", this.state.hiddenRooms);

      let settingsToUpdate = savedSettings;
      // Update the existing view
      let newViewData = null;
      settingsToUpdate.userViews.forEach((view: IView) => {
        if (view.viewId === viewId) {
          newViewData = {
            ...view,
            viewId: newViewId,
            viewName: this.state.newViewName,
            viewSettings: {
              ...view.viewSettings,
              mute: this.state.mute,
              autoZoom: true,
              hideLargeCards: this.state.hideLargeCards,
              fsDepartmentsToShow: this.state.fsDepartmentsToShow,
              deptSettings: this.state.viewDeptSettings,
            },
          };
        }
      });

      settingsToUpdate.userViews.push(newViewData);
      console.log('latest', settingsToUpdate.userViews);
      let updatedUserSettings = JSON.stringify(settingsToUpdate);

      let payload = {
        ...this.props.loggedInUserData,
        userSettings: updatedUserSettings,
      };

      let token = localStorage.getItem('token');
      fetch(API.REACT_APP_API_SETUSERSETTINGS, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("RES!", res.json());
          return res.json();
        })
        .then(data => {
          console.log('Updated res', data);
          // window.location.replace("/?viewId=" + newViewId);
          if (getQueryVariable('deviceId')) {
            let deviceId = getQueryVariable('deviceId');
            window.location.replace(
              '/?viewId=' + newViewId + '&deviceId=' + deviceId
            );
          } else {
            window.location.replace('/?viewId=' + newViewId);
          }
        })
        .catch(err => {
          console.log('err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  updateView = (viewId: any) => {
    let currentViewId = getQueryVariable('viewId');

    let allViews = [];
    let userViews = [];
    if (this.props.loggedInUserData.userSettings) {
      let savedSettings: any = {};
      savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      if (savedSettings.userViews) {
        userViews = savedSettings.userViews;
      }
    }
    let publishedViews = [];
    if (this.props.organization.publishedViews.length > 0) {
      publishedViews = JSON.parse(this.props.organization.publishedViews);
    }

    allViews = userViews.concat(publishedViews);

    allViews.forEach((view: IView) => {
      if (view.viewId === viewId) {
        if (view.publisherId) {
          // UPDATE ORGANIZATION
          console.log('ITS A PUBLISHED VIEW, MODIFY ORG');
          let currentOrgViews = JSON.parse(
            this.props.organization.publishedViews
          );
          let latestViewName: string | null = null;
          let orgViewsToUpdate = currentOrgViews;
          currentOrgViews.forEach((view: IView) => {
            if (view.viewId === viewId) {
              if (this.state.renameView) {
                if (currentViewId === viewId) {
                  latestViewName = this.state.renameViewValue;
                }
                view.viewName = this.state.renameViewValue;
              }

              view.viewSettings = {
                ...view.viewSettings,
                viewName: this.state.renameView
                  ? this.state.renameViewValue
                  : view.viewName,
                mute: this.state.mute,
                darkMode: this.state.darkMode,
                autoZoom: true,
                hideLargeCards: this.state.hideLargeCards,
                fsDepartmentsToShow: this.state.fsDepartmentsToShow,
                deptSettings: this.state.viewDeptSettings,
              };
            }
          });

          let updatedOrgViews = JSON.stringify(orgViewsToUpdate);

          console.log('org views to update', orgViewsToUpdate);

          let modifiedOrganization = {
            ...this.props.organization,
            publishedViews: updatedOrgViews,
            // publishedViews: "",
          };

          console.log('payload', modifiedOrganization);

          let payload = JSON.stringify(modifiedOrganization);
          // let token = localStorage.getItem("token");
          console.log('SENDING', modifiedOrganization);

          let token = localStorage.getItem('token');
          fetch(API.REACT_APP_API_PUBLISHEDVIEWS, {
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              console.log('res', res);
              return res.json();
            })
            .then(data => {
              console.log('Final updated view', data);
              this.setState(
                {
                  viewName: latestViewName
                    ? latestViewName
                    : this.state.viewName,
                  renameView: false,
                  renameViewValue: '',
                  showPromptToSaveView: false,
                  viewSavedSuccess: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({ viewSavedSuccess: false });
                  }, 3000);
                }
              );
              let userEmail = localStorage.getItem('email');
              let token = localStorage.getItem('token');

              if (userEmail && token) {
                this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
              }
            })
            .catch(err => {
              console.log('err updating published views', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        } else {
          // UPDATE USER DATA
          console.log('NOT A PUBLISHED VIEW');

          let savedSettings = JSON.parse(
            this.props.loggedInUserData.userSettings
          );
          let latestViewName: any = null;
          let settingsToUpdate = savedSettings;
          if (settingsToUpdate.userViews) {
            // console.log("User saved views", settingsToUpdate);
            settingsToUpdate.userViews.forEach((view: any) => {
              if (view.viewId === viewId) {
                console.log('Update view', view);
                if (this.state.renameView) {
                  if (currentViewId === viewId) {
                    latestViewName = this.state.renameViewValue;
                  }

                  view.viewName = this.state.renameViewValue;
                }
                view.viewSettings = {
                  ...view.viewSettings,
                  viewName: this.state.renameView
                    ? this.state.renameViewValue
                    : view.viewName,
                  mute: this.state.mute,
                  darkMode: this.state.darkMode,
                  autoZoom: true,
                  hideLargeCards: this.state.hideLargeCards,
                  fsDepartmentsToShow: this.state.fsDepartmentsToShow,
                  deptSettings: this.state.viewDeptSettings,
                };

                // console.log("BEFORE", view.viewSettings);

                view.viewSettings.deptSettings.forEach((dept: any) => {
                  dept.hiddenRooms = _.uniq(dept.hiddenRooms);
                  dept.providersToShow = _.uniq(dept.providersToShow);
                  dept.actionsToShow = _.uniq(dept.actionsToShow);
                });

                console.log('UPDATED VIEW DATA', view.viewSettings);
              }
            });

            let updatedUserSettings = JSON.stringify(settingsToUpdate);

            let payload = {
              ...this.props.loggedInUserData,
              userSettings: updatedUserSettings,
            };

            let token = localStorage.getItem('token');
            fetch(API.REACT_APP_API_SETUSERSETTINGS, {
              method: 'POST',
              body: JSON.stringify(payload),
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
            })
              .then(res => {
                // console.log("RES!", res.json());
                return res.json();
              })
              .then(data => {
                // console.log("Updated res", data);
                this.setState(
                  {
                    viewName: latestViewName
                      ? latestViewName
                      : this.state.viewName,
                    renameView: false,
                    renameViewValue: '',
                    showPromptToSaveView: false,
                    viewSavedSuccess: true,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({ viewSavedSuccess: false });
                    }, 3000);
                  }
                );
                let userEmail = localStorage.getItem('email');
                let token = localStorage.getItem('token');
                if (userEmail && token) {
                  this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
                }
              })
              .catch(err => {
                console.log('err', err);
                if (err.response?.status === 401) {
                  checkRefreshToken();
                }
              });
          } else {
            console.log("Didn't find any views?");
          }
        }
      }
    });
  };

  handleView = (event: any) => {
    let userEmail = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    if (userEmail && token) {
      this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
    }

    this.setState({ viewEl: event.currentTarget });
  };

  revertView = (allDepts: IDepartment[]) => {
    let viewId = getQueryVariable('viewId');
    if (viewId) {
      console.log('load view settings', viewId);
      let userSettings = JSON.parse(this.props.loggedInUserData.userSettings);

      if (_.some(userSettings.userViews, ['viewId', viewId])) {
        // console.log("FOUND IT", viewId);
        userSettings.userViews.forEach((view: any) => {
          if (view.viewId === viewId) {
            // Set state with view data
            let vs = view.viewSettings;

            console.log('vs', vs);
            let hiddenRooms: string[] = [];
            let providersToShow: string[] = [];
            let actionsToShow: string[] = [];
            let viewDeptSettings: any = [];
            vs.deptSettings.forEach((dept: IDepartmentSetting) => {
              viewDeptSettings.push(dept);
              if (dept.hiddenRooms.length > 0) {
                dept.hiddenRooms.forEach((room: string) => {
                  hiddenRooms.push(room);
                });
              }
              if (dept.providersToShow.length > 0) {
                dept.providersToShow.forEach((provider: string) => {
                  providersToShow.push(provider);
                });
              }
              if (dept.actionsToShow.length > 0) {
                dept.actionsToShow.forEach((action: string) => {
                  actionsToShow.push(action);
                });
              }
            });

            this.setState(
              {
                viewName: view.viewName,
                hiddenRooms: hiddenRooms,
                providersToShow: providersToShow,
                actionsToShow: actionsToShow,
                autoZoom: vs.autoZoom,
                hideLargeCards: vs.hideLargeCards,
                mute: vs.mute,
                fsDepartmentsToShow: vs.fsDepartmentsToShow,
                viewDeptSettings: viewDeptSettings,
                showPromptToSaveView: false,
                openDeptDialog: false,
              },
              () => {
                this.resizeFlowstation(allDepts);
              }
            );
          }
        });
      } else {
        console.log('View ID doesnt exist in user data');
        window.location.reload();
      }
      console.log('user settings', userSettings);
    }
  };

  goToCreateView = (deptId: string) => {
    // this.props.logAction("Creating New View", deptId, this.props.loggedInUserData.id);
    console.log('goToCreateview');
    if (deptId && this.props.loggedInUserData.id) {
      let deviceId = null;
      if (getQueryVariable('deviceId')) {
        deviceId = getQueryVariable('deviceId');
      }

      if (deviceId) {
        window.location.replace(
          '/?groupId=' + deptId + '&newView=true&deviceId=' + deviceId
        );
      } else {
        window.location.replace('/?groupId=' + deptId + '&newView=true');
      }
    }
  };

  deleteView = (viewToDelete: IView) => {
    console.log('Delete view', viewToDelete);

    if (viewToDelete.publisherId) {
      let publishedViews = [];

      if (this.props.organization.publishedViews) {
        if (this.props.organization.publishedViews.length > 0) {
          publishedViews = JSON.parse(this.props.organization.publishedViews);
        }
      }

      publishedViews = publishedViews.filter((savedView: IView) => {
        return savedView.viewId !== viewToDelete.viewId;
      });

      let modifiedOrganization = {
        ...this.props.organization,
        publishedViews: JSON.stringify(publishedViews),
      };

      // console.log("payload", payload);
      let payload = JSON.stringify(modifiedOrganization);
      let token = localStorage.getItem('token');
      console.log('SENDING', modifiedOrganization);

      fetch(API.REACT_APP_API_PUBLISHEDVIEWS, {
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          console.log('res', res);
          return res.json();
        })
        .then(data => {
          console.log('Final delete', data);
          let userEmail = localStorage.getItem('email');
          let token = localStorage.getItem('token');
          if (userEmail && token) {
            this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
          }
        })
        .catch(err => {
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
          console.log('err updating published views', err);
        });
    } else {
      let userViews = [];
      if (this.props.loggedInUserData.userSettings) {
        let savedSettings: any = {};
        savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
        if (savedSettings.userViews) {
          userViews = savedSettings.userViews;
        }
      }

      userViews = userViews.filter((savedView: IView) => {
        return savedView.viewId !== viewToDelete.viewId;
      });

      // console.log("updated:", userViews);

      let savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
      let settingsToUpdate = savedSettings;

      settingsToUpdate.userViews = userViews;
      let updatedUserSettings = JSON.stringify(settingsToUpdate);

      // console.log("settingsToUdate.userViews", settingsToUpdate);

      let payload = {
        ...this.props.loggedInUserData,
        userSettings: updatedUserSettings,
      };

      let token = localStorage.getItem('token');
      fetch(API.REACT_APP_API_SETUSERSETTINGS, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          // console.log("RES!", res.json());
          return res.json();
        })
        .then(data => {
          console.log('Updated res', data);
          let userEmail = localStorage.getItem('email');
          let token = localStorage.getItem('token');
          if (userEmail && token) {
            this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
          }
        })
        .catch(err => {
          console.log('err', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  publishView = (view: IView, allDepts: IDepartment[]) => {
    // console.log("view", view);
    // console.log("allDepts", allDepts);
    let URL = API.REACT_APP_API_ORGANIZATION + this.props.organization.id;
    let token = localStorage.getItem('token');
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    // console.log("lets get", orgId);
    axios
      .get(URL, headers)
      .then(response => {
        console.log('Good!', response.data.data);

        let latestOrgDetails = response.data.data;

        let currentPublishedViews = null;
        if (latestOrgDetails.publishedViews === '') {
          currentPublishedViews = [];
        } else {
          currentPublishedViews = JSON.parse(latestOrgDetails.publishedViews);
        }

        let duplicateFound = false;
        let belongsToOrg = false;

        currentPublishedViews.forEach((publishedView: IView) => {
          if (publishedView.viewId === view.viewId) {
            duplicateFound = true;
            console.log('duplicate found!');
          }
        });

        allDepts.forEach((dept: IDepartment) => {
          if (dept.id === view.defaultDeptId) {
            belongsToOrg = true;
          }
        });

        // console.log("belongs to org?", belongsToOrg);

        if (!duplicateFound && belongsToOrg) {
          view.publisher = this.props.loggedInUserData.fullName;
          view.publisherId = this.props.loggedInUserData.id;

          let generatedId = uniqid();
          view.viewId = generatedId;
          currentPublishedViews.push(view);

          let modifiedOrganization = {
            ...latestOrgDetails,
            publishedViews: JSON.stringify(currentPublishedViews),
            // publishedViews: "",
          };

          // console.log("modifiedOrgDetails", modifiedOrganization);

          let payload = JSON.stringify(modifiedOrganization);
          let token = localStorage.getItem('token');
          // console.log("SENDING", modifiedOrganization);

          fetch(API.REACT_APP_API_PUBLISHEDVIEWS, {
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })
            .then(res => {
              // console.log("res", res);
              return res.json();
            })
            .then(() => {
              // console.log("Final publish", data);
              let userEmail = localStorage.getItem('email');
              let token = localStorage.getItem('token');
              if (userEmail && token) {
                this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
              }
            })
            .catch(err => {
              console.log('err updating published views', err);
              if (err.response?.status === 401) {
                checkRefreshToken();
              }
            });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  fetchHistoricalDept = (timeZone: string) => {
    this.props.logAction(
      'FsHistory',
      this.props.department.id,
      this.props.loggedInUserData.id
    );
    // /api/v3/fs/history/deptId=5f43f0a7e0a5d550acd74bc4/localStartDateTime=2021-10-19 14:10:30/localEndDateTime=2021-10-19 14:10:40
    // this.props.setDeptHistory({ itWorked: true });

    // let oldURL = API.REACT_APP_API_GETDEPARTMENTHISTORY + groupId + "/localStartDateTime=2021-10-02 16:20:30/localEndDateTime=2021-11-02 16:40:30";

    // console.log("Now", moment().tz(timeZone).format("YYYY-MM-DD H:mm:ss"));
    // console.log("NOW local", moment().tz(timeZone).local().format("YYYY-MM-DD H:mm:ss"));

    // console.log("utah:", moment().format("YYYY-MM-DD H:mm:ss"));
    let now = moment()
      .tz(timeZone)
      .subtract('1', 'seconds')
      .format('YYYY-MM-DD H:mm:ss');
    let hourAgo = moment()
      .tz(timeZone)
      .subtract('4', 'hours')
      .subtract('2', 'minute')
      .format('YYYY-MM-DD H:mm:ss');

    // console.log("act", moment().tz(timeZone).format("YYYY-MM-DD h:mm:ss"));
    console.log('now:', now);
    console.log('hour ago:', hourAgo);

    let URL =
      API.REACT_APP_API_GETDEPARTMENTHISTORY +
      groupId +
      '/localStartDateTime=' +
      hourAgo +
      '/localEndDateTime=' +
      now;

    // console.log("1", oldURL);
    // console.log("2", URL);

    console.log('url', URL);
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        console.log('response!', response.data);
        if (response.data.data) {
          this.props.setDeptHistory(response.data.data[0].flowstation);
          this.setState({
            selectedHistoryTime: response.data.data[0].utcDateTime,
            deptHistory: response.data.data,
            selectedHistoryVal: 0,
            showHistory: true,
          });
        } else {
          console.log('No history found.');
        }
      })
      .catch(err => {
        console.log('Error fetching historical data ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  // selectHistory = (e: any, val: any) => {
  //   let selectedVal = val - 1;
  //   if (val === 0) {
  //     selectedVal = 0;
  //   } else {
  //     selectedVal = val - 1;
  //   }
  //   this.props.setDeptHistory(this.state.deptHistory[selectedVal].flowstation);
  //   this.setState({
  //     selectedHistoryTime: this.state.deptHistory[selectedVal].utcDateTime,
  //     selectedHistoryVal: selectedVal,
  //     showHistory: true,
  //   });
  // };

  // returnFromHistory = () => {
  //   window.location.reload();
  // };

  // handleHistoryDateChange = (event: any, timeZone: string) => {
  //   console.log("Done: ", moment().utc(event.target.value).tz(timeZone).format("MM/DD/YY H:mm:ss"));
  //   let prev30 = moment().utc(event.target.value).tz(timeZone).subtract("30", "minutes").format("MM/DD/YY H:mm:ss");
  //   let forward30 = moment().utc(event.target.value).tz(timeZone).add("30", "minutes").format("MM/DD/YY H:mm:ss");
  //   console.log("past", prev30);
  //   console.log("future", forward30);
  // };

  manuallyRefetchFsData = () => {
    if (groupId) {
      this.props.fetchVisibleFsDepartments(
        groupId,
        this.state.fsDepartmentsToShow
      );
    }
  };

  playConfetti = (confettiTime: any) => {
    this.setState({
      playConfetti: true,
      confettiTime: confettiTime,
    });
  };

  toggleDarkMode = () => {
    let isDark = this.state.darkMode;
    this.setState({
      darkMode: !isDark,
    });
    localStorage.setItem('darkMode', isDark ? 'false' : 'true');
    this.forceUpdate();

    if (this.state.viewName) {
      this.setState({
        showPromptToSaveView: true,
      });
    }

    this.props.logAction(
      'Toggled Dark Mode',
      this.props.department.id,
      this.props.loggedInUserData.id
    );
  };

  toggleProvider = (provider: IProvider, groupId: string, allDepts: any) => {
    let newProvidersToShow: any = [...this.state.providersToShow];

    if (newProvidersToShow.includes(provider)) {
      console.log('delete it');
      newProvidersToShow = newProvidersToShow.filter((obj: any) => {
        return obj !== provider;
      });
    } else {
      newProvidersToShow.push(provider.id);
    }

    this.setState({
      providersToShow: newProvidersToShow,
    });
    // =================================
    if (getQueryVariable('newView')) {
      console.log('prefilter toggle provider');
      let preFilteringDeptSettingsToModify = [
        ...this.state.preFilteringDeptSettings,
      ];
      preFilteringDeptSettingsToModify.forEach((preDept, i) => {
        if (preDept.deptId === groupId) {
          preFilteringDeptSettingsToModify[i].providersToShow =
            newProvidersToShow;
        }
      });
      console.log('updated', preFilteringDeptSettingsToModify);
      this.setState({
        preFilteringDeptSettings: preFilteringDeptSettingsToModify,
      });
      this.resizeFlowstation(allDepts);
    } else {
      if (this.state.viewName) {
        // console.log("View", this.state.savedViewDetails);

        let viewToUpdate = { ...this.state.savedViewDetails };
        let found = false;
        viewToUpdate.viewSettings.deptSettings.forEach((dept: any) => {
          if (dept.deptId === groupId) {
            dept.providersToShow = [...newProvidersToShow];
            found = true;
            console.log('Found dept to modify', dept);
          }
        });

        if (!found) {
          let newDeptSetting: any = {
            deptId: groupId,
            hiddenRooms: [],
            providersToShow: [...newProvidersToShow],
            actionsToShow: [],
            actionSwitch: false,
            roomSwitch: false,
            providerSwitch: true,
            hideLargeCards: true,
          };
          viewToUpdate.viewSettings.deptSettings.push(newDeptSetting);
        }
        console.log('after', viewToUpdate);

        this.setState({
          showPromptToSaveView: true,
          viewDeptSettings: viewToUpdate.viewSettings.deptSettings,
        });
      } else {
        let savedSettings = JSON.parse(localStorage.getItem(groupId) || '{}');
        let newSettings = {
          ...savedSettings,
          providersToShow: newProvidersToShow,
        };

        localStorage.setItem(groupId, JSON.stringify(newSettings));
      }
      this.resizeFlowstation(allDepts);
    }
  };

  handleDepartmentPreFiltering = (dept: IDepartment) => {
    this.openDepartmentSettingsDialog(dept);
  };

  closeReport = () => {
    clearTimeout(reportTimeOut);
    this.setState({
      viewPowerReport: false,
    });
  };

  highlightMember = (staffMember: IStaffMember) => {
    console.log('Find', staffMember);
    this.setState({
      highlightMember: staffMember.id,
      highlightMemberLocation: staffMember,
    });
    setTimeout(() => {
      this.setState({
        highlightMember: null,
        highlightMemberLocation: null,
      });
    }, 5000);
  };

  viewDocumentation = (dept: string) => {
    this.props.logAction(
      'Viewed Documentation',
      'https://app.synctimes.com/documentation?deptId=' + dept,
      this.props.loggedInUserData.id
    );
    window.open('/documentation?deptId=' + dept);
  };

  openPowerReport = () => {
    // this.props.logAction("Viewed Report", getQueryVariable("groupId")!, this.props.loggedInUserData.id);

    let URL = API.REACT_APP_API_EMBEDDEDREPORTS;
    let token = localStorage.getItem('token');
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };

    axios
      .get(URL, headers)
      .then(response => {
        let data = response.data.data;
        console.log('data!', data);
        this.setState({
          viewPowerReport: true,
          reportEmbedDetails: data,
        });
        // this.setState({ viewPowerReport: true });
      })
      .catch(err => {
        console.log('Error fetching report embedd: ', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  clearActiveAlert = (activeAlert: IAlert) => {
    let token = localStorage.getItem('token');

    let data = {
      roomId: activeAlert.roomId,
      actionId: activeAlert.actionId,
    };

    axios({
      method: 'post',
      url: API.REACT_APP_API_CLEARICON,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        // console.log("Success", response.data.data);
        // if (!allowSignalR) {
        //   this.props.refetchFsData();
        // }
      })
      .catch(err => {
        if (err.response) {
          console.log('check it', err.response?.status);
          if (err.response?.status === 401) {
            checkRefreshToken();
            // localStorage.removeItem("token");
            // localStorage.removeItem("email");
            // window.location.replace("/login");
          }
        }
        console.log('Error removing action', err);
      });
  };

  setSoundsAnchor = (event: any) => {
    this.setState({ showSounds: true });
  };

  setActiveAlertsAnchor = (event: any) => {
    let userEmail = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    if (userEmail && token) {
      this.props.fetchLoggedInUser(userEmail, token, mockedOrgId);
    }

    let preSelectedRoom: any = null;
    let availableActionsToSelect: any = [];

    this.props.rooms.forEach(room => {
      if (
        room.id === this.props.loggedInUserData.lastLocationRoomId
        // room.id === "5f43f0a7e0a5d550acd74e12"
      ) {
        preSelectedRoom = room;
      }
    });

    if (preSelectedRoom) {
      this.props.organizationDepts.forEach((dept: IDepartment) => {
        if (dept.id === preSelectedRoom.mongoDeptId) {
          availableActionsToSelect = dept.actionsList;
        }
      });
    }

    // console.log("lastLocation", this.props.loggedInUserData.lastLocationRoomId);
    // console.log("preSelected", preSelectedRoom);

    this.setState({
      showActiveAlerts: true,
      selectedRoom: preSelectedRoom ? preSelectedRoom : '',
      availableActionsToSelect: availableActionsToSelect,
    });
  };

  toggleDisableNotificationPopover = () => {
    let currentStatus = localStorage.getItem('disableNotificationPopover');

    if (currentStatus === 'true') {
      localStorage.setItem('disableNotificationPopover', 'false');
    } else {
      localStorage.setItem('disableNotificationPopover', 'true');
      this.setState({
        showActiveAlerts: false,
      });
    }
  };

  selectRoom = (room: IRoom | null) => {
    if (room) {
      console.log('selected', room);
      this.setState({
        selectedRoom: room,
      });

      this.props.organizationDepts.forEach((dept: IDepartment) => {
        if (dept.id === room.mongoDeptId) {
          this.setState({
            availableActionsToSelect: dept.actionsList,
          });
        }
      });
    }
  };

  addAction = (action: IAction) => {
    console.log('Add action', action);

    this.setState({
      showTwoStep: true,
      twoStepAction: action,
    });
  };

  handleDoubleConfirmationText = (value: any) => {
    if (value) {
      if (value.toLowerCase() === 'yes') {
        this.sendAction(this.state.twoStepAction);
      } else {
        this.setState({
          doubleConfirmationText: value,
        });
      }
    } else {
      this.setState({
        doubleConfirmationText: value,
      });
    }
  };

  sendAction = (action: IAction) => {
    console.log('Add action', action);

    let data = {
      roomId: this.state.selectedRoom.id,
      actionId: action.id,
      // isFlashing: false,
      // timerMinutes: countdownTime,
    };

    let token = localStorage.getItem('token');

    axios({
      method: 'post',
      url: API.REACT_APP_API_ADDACTION,
      data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        // if (!allowSignalR) {
        //   this.manuallyRefetchFsData;
        // }
        console.log('GOOD!', response);
        this.setState(
          {
            showActionsPopover: false,
            doubleConfirmationText: '',
            showTwoStep: false,
            twoStepAction: null,
            actionAddedSuccess: true,
          },
          () => {
            setTimeout(() => {
              this.setState({ actionAddedSuccess: false });
            }, 3000);
          }
        );
      })
      .catch(err => {
        console.log('Error sending action', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  openActionsPopover = () => {
    this.setState({ showActionsPopover: true });

    this.props.logAction(
      'FS Quick Room Action',
      getQueryVariable('groupId')!,
      this.props.loggedInUserData.id
    );
  };

  // testCongrats = () => {
  //   this.setState({
  //     playCongratulationsConfetti: true,
  //   });
  //   let audio = new Audio(congratsSounds);
  //   audio.play();
  //   setTimeout(() => {
  //     this.setState({
  //       playCongratulationsConfetti: false,
  //     });
  //   }, 60000);
  // };

  handleManageNotifications = () => {
    this.setState({
      showUserNotifications: true,
    });
  };

  render() {
    const { classes } = this.props;

    const isDesktop = checkIfIsDesktopApp();
    // let holiday = GetHoliday();
    // console.log("Holiday?", holiday)

    // console.log('view dept settings', this.state.viewDeptSettings)

    // console.log("hidden rooms", this.state.hiddenRooms)
    // console.log('view dept settings TO MODIFY', this.state.deptSettingsToModify)

    // let stat = localStorage.getItem("disableNotificationPopover");

    // console.log("stat?", stat);

    // console.log("CURRENT STATUS", JSON.parse(localStorage.getItem("disableNotificationPopover") || "{}"));
    // console.log("tick");

    // console.log("active alerts", this.state.activeAlerts);

    // console.log("SignalR State:", connection.state);

    // Loop through sibling depts and make sure they have saved settings

    if (this.props.groupData) {
      if (this.props.groupData.id) {
        if (!localStorage.getItem(this.props.groupData.id)) {
          console.log('create missing parent group id');
          let settingsTemplate: any = {
            zoom: isMobile ? 50 : 100,
            largeCards: false,
            hideLargeCards: true,
            mute: false,
            hiddenRooms: [],
            hideFrontDesk: true,
            providerSwitch: true,
            roomSwitch: false,
            actionSwitch: false,
            disableCards: false,
            actionsToShow: [],
            providersToShow: [],
            waitingRoomProvidersToShow: [],
            mutedActions: [],
            fsDepartmentsToShow: [this.props.groupData.id],
            autoZoom: true,
            initialZoom: false,
            savedSettings: [],
          };

          localStorage.setItem(
            this.props.groupData.id,
            JSON.stringify(settingsTemplate)
          );
        }
      }
    }

    this.props.siblingDepts.forEach((siblingDept: any) => {
      // console.log("FOUND IT", siblingDept.id === "622f7c989a272d216047e5cc");
      // console.log("going through siblings!", siblingDept);
      let createSettings = () => {
        console.log('create settings!');
        let settingsTemplate: any = {
          zoom: isMobile ? 50 : 100,
          largeCards: false,
          hideLargeCards: true,
          mute: false,
          hiddenRooms: [],
          hideFrontDesk: true,
          providerSwitch: true,
          roomSwitch: false,
          actionSwitch: false,
          disableCards: false,
          actionsToShow: [],
          providersToShow: [],
          waitingRoomProvidersToShow: [],
          mutedActions: [],
          fsDepartmentsToShow: [siblingDept.id],
          autoZoom: true,
          initialZoom: false,
          savedSettings: [],
        };
        localStorage.setItem(siblingDept.id, JSON.stringify(settingsTemplate));
      };

      if (localStorage.getItem(siblingDept.id)) {
        let savedSettings = JSON.parse(
          localStorage.getItem(siblingDept.id) || '{}'
        );
        const hasAllKeys = settingsRequirements.every(item =>
          savedSettings.hasOwnProperty(item)
        );
        // console.log("Has all keys?", hasAllKeys);

        if (hasAllKeys) {
          // All Keys Found, All good
          // console.log("All keys for ", siblingDept.id, " are good!");
        } else {
          // console.log("MISSING KEY");
          createSettings();
        }
      } else {
        console.log('SIBLING DOESNT EXIST', siblingDept);
        if (siblingDept.id) {
          createSettings();
        }
      }
    });

    let token = localStorage.getItem('token');
    let email = localStorage.getItem('email');

    let sendToLogin = false;
    if (!token && !email) {
      if (!groupId) {
        console.log('No token or email');
        // window.location.replace("/login");
        sendToLogin = true;
      }
    }

    let allowedToViewFS = false;
    if (
      this.props.loggedInUserData.userEmail === 'examroomlogin@synclogin.com'
    ) {
      allowedToViewFS = true;
    } else {
      if (
        this.props.groupData.orgId &&
        this.props.loggedInUserData.mongoOrganizationId
      ) {
        if (canDo(['View Flowstations'], this.props.loggedInUserData)) {
          allowedToViewFS = true;
        } else {
          localStorage.removeItem('lastVisitedFs');
          localStorage.removeItem('lastVisitedView');
          window.location.replace('/');
        }
      }
    }

    if (this.props.loggedInUserData.id && gaInitialized === false) {
      console.log(
        '%c Setting GA userId ',
        'background: #F9AB00; color: #ffffff'
      );
      // ReactGA.set({ userId: 123 });
      // ReactGA.set({ userId: this.props.loggedInUserData.id });
      // ReactGA.pageview(window.location.pathname + window.location.search);

      // LOG ROCKET
      if (
        window.location.hostname === 'app.synctimes.com' ||
        window.location.hostname === 'dev.synctimes.com' ||
        window.location.hostname === 'cesium.azurewebsites.net'
      ) {
        if (this.props.loggedInUserData.mongoOrganizationId) {
          let URL =
            API.REACT_APP_API_ORGANIZATION +
            this.props.loggedInUserData.mongoOrganizationId;
          let token = localStorage.getItem('token');
          let headers = {
            headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
          };

          axios
            .get(URL, headers)
            .then(response => {
              if (response.data.data) {
                console.log('LogRocket Identified');
                LogRocket.identify(this.props.loggedInUserData.id, {
                  name: this.props.loggedInUserData.fullName,
                  email: this.props.loggedInUserData.userEmail,
                  orgName: response.data.data.orgName,
                  userType: this.props.loggedInUserData.userTypeId,
                  admin: this.props.loggedInUserData.isAdmin,
                });
              }
            })
            .catch(err => {
              if (err.response) {
                if (err.response?.status === 401) {
                  // FORCE LOGOUT, UNAUTHORIZED
                  console.log('UNAUTHORIZED, KICK EM OUT');
                  checkRefreshToken();
                  // localStorage.removeItem("token");
                  // localStorage.removeItem("email");

                  // if (getQueryVariable("username") && getQueryVariable("password")) {
                  //   // Reload FS with login params
                  //   let location = window.location.href;
                  //   window.location.replace(location);
                  // } else {
                  //   window.location.replace("/");
                  // }
                }
              }
              console.log('Error: ', err);
            });
        }
      }
      // END LOG ROCKET

      // // HUBSPOT CHAT INDENTIFY
      // let onConversationsAPIReady = () => {
      //   //@ts-ignore
      //   var _hsq = (window._hsq = window._hsq || []);
      //   // console.log("ready");
      //   _hsq.push([
      //     "identify",
      //     {
      //       name: this.props.loggedInUserData.fullName,
      //       email: this.props.loggedInUserData.userEmail,
      //     },
      //   ]);
      //   _hsq.push(["trackPageView"]);
      //   setTimeout(() => {
      //     // Refresh hubspot widget to take changes
      //     console.log("Hubspot Chat Updated");
      //     //@ts-ignore
      //     window.HubSpotConversations.widget.refresh();
      //   }, 1000);
      // };

      // //@ts-ignore
      // if (window.HubSpotConversations) {
      //   // console.log("The api is ready already");
      //   onConversationsAPIReady();
      // } else {
      //   //@ts-ignore
      //   window.hsConversationsOnReady = [
      //     () => {
      //       // console.log("Now the api is ready");
      //       onConversationsAPIReady();
      //     },
      //   ];
      // }
      // // END HUBSPOT CHAT INDENTIFY

      // IN HOUSE LOGGING
      if (
        groupId &&
        this.props.department.id &&
        this.props.loggedInUserData.id
      ) {
        this.props.logAction(
          'Viewed Flowstation',
          this.props.department.id,
          this.props.loggedInUserData.id
        );
      }

      gaInitialized = true;
    }
    // console.log("check timeZone", this.props.groupData.timeZoneId);

    switch (this.props.groupData.timeZoneId) {
      case 3:
        timeZone = 'Pacific/Honolulu';
        break;
      case 4:
        timeZone = 'America/Anchorage';
        break;
      case 6:
        timeZone = 'America/Los_Angeles';
        break;
      case 7:
        timeZone = 'America/Phoenix';
        break;
      case 9:
        timeZone = 'America/Denver';
        break;
      case 10:
        timeZone = 'America/Chicago';
        break;
      case 15:
        timeZone = 'America/New_York';
        break;
      case 16:
        timeZone = 'America/Puerto_Rico';
        break;
      default:
        timeZone = 'America/Denver';
    }

    // console.log("timeZone", this.props.groupData.timeZoneId);

    let allProviders: any = [];
    let providersToSort: any = [];
    // providersToSort = _.uniqBy(this.props.groupData.rooms, "providerName");

    let rooms: any[] = [];
    let allDepts: IDepartment[] = [];
    let allRooms: IRoom[] = [];

    // console.log("SIBLING DEPTS", this.props.siblingDepts);

    // Determine what to filter options to show when filtering a department
    let deptFilterBy = null;
    let preFilteringDeptSettings: any = null;
    let hideNonPatientRooms: boolean = false;

    let mutedActions = JSON.parse(localStorage.getItem('mutedActions') || '[]');

    if (this.state.deptSettingsToModify) {
      if (getQueryVariable('newView')) {
        deptFilterBy = 'room';
        this.state.preFilteringDeptSettings.forEach(dept => {
          if (dept.deptId === this.state.deptSettingsToModify.id) {
            preFilteringDeptSettings = dept;
            if (dept.roomSwitch) {
              deptFilterBy = 'room';
            }
            if (dept.providerSwitch) {
              deptFilterBy = 'provider';
            }
            if (dept.actionSwitch) {
              deptFilterBy = 'action';
            }
            if (dept.hideNonPatientRooms) {
              hideNonPatientRooms = true;
            }
          }
        });
        // console.log("Pre filter dept settings", preFilteringDeptSettings);
      } else {
        if (this.state.viewName) {
          // console.log("viewDeptSettings", this.state.viewDeptSettings);
          // console.log("deptSettingsToModify", this.state.deptSettingsToModify);

          // console.log("viewDeptSettings", this.state.viewDeptSettings);

          if (
            this.state.viewDeptSettings.find(
              v => v.deptId === this.state.deptSettingsToModify.id
            )
          ) {
            if (
              this.state.viewDeptSettings.find(
                v => v.deptId === this.state.deptSettingsToModify.id
              ).roomSwitch
            ) {
              deptFilterBy = 'room';
            }
            if (
              this.state.viewDeptSettings.find(
                v => v.deptId === this.state.deptSettingsToModify.id
              ).providerSwitch
            ) {
              deptFilterBy = 'provider';
            }
            if (
              this.state.viewDeptSettings.find(
                v => v.deptId === this.state.deptSettingsToModify.id
              ).actionSwitch
            ) {
              deptFilterBy = 'action';
            }
            if (
              this.state.viewDeptSettings.find(
                v => v.deptId === this.state.deptSettingsToModify.id
              ).hideNonPatientRooms
            ) {
              hideNonPatientRooms = true;
            }
          }
        } else {
          if (
            JSON.parse(
              localStorage.getItem(this.state.deptSettingsToModify.id) || '{}'
            ).roomSwitch
          ) {
            deptFilterBy = 'room';
          }
          if (
            JSON.parse(
              localStorage.getItem(this.state.deptSettingsToModify.id) || '{}'
            ).providerSwitch
          ) {
            // console.log("2");
            deptFilterBy = 'provider';
          }
          if (
            JSON.parse(
              localStorage.getItem(this.state.deptSettingsToModify.id) || '{}'
            ).actionSwitch
          ) {
            deptFilterBy = 'action';
          }
          if (
            JSON.parse(
              localStorage.getItem(this.state.deptSettingsToModify.id) || '{}'
            ).hideNonPatientRooms
          ) {
            hideNonPatientRooms = true;
          }
        }
      }
    }

    if (this.props.groupData.rooms) {
      let roomData = allRooms;

      allDepts.push(this.props.groupData);

      this.props.groupData.rooms.forEach(room => {
        allRooms.push(room);
      });

      this.props.siblingDepts.forEach((siblingDept: IDepartment) => {
        allDepts.push(siblingDept);
        siblingDept?.rooms?.forEach((room: IRoom) => {
          // Do some filteirng here with JSON.parse(localStorage.getItem(siblingDept.id))
          allRooms.push(room);
        });
      });

      // console.log("all rooms", allRooms);

      allRooms.sort((a, b) => {
        // console.log("sorting?");
        if (a.roomName < b.roomName) {
          return -1;
        }
        if (a.roomName > b.roomName) {
          return 1;
        }
        return 0;
      });

      let newArray: any = [];
      allDepts.forEach(dept => {
        let deptSettings: any = null;

        if (getQueryVariable('newView')) {
          // let found = false;
          this.state.preFilteringDeptSettings.forEach(
            (preDept: IDepartmentSetting) => {
              if (preDept.deptId === dept.id) {
                // found = true;
                // console.log("FOUND THE PRE FILTERING DEPT", preDept);
                deptSettings = preDept;
              }
            }
          );
        } else {
          if (this.state.viewName) {
            let found = false;
            if (this.state.savedViewDetails) {
              // console.log("use this instead", this.state.savedViewDetails);
              this.state.savedViewDetails.viewSettings.deptSettings.forEach(
                (savedDept: IDepartmentSetting) => {
                  if (savedDept.deptId === dept.id) {
                    // console.log("found it");
                    deptSettings = savedDept;
                    found = true;
                  }
                }
              );
            }

            if (!found) {
              // console.log("MISSING");
              deptSettings = JSON.parse(localStorage.getItem(dept.id) || '{}');
            }
          } else {
            // console.log("Got dept by local storage");
            deptSettings = JSON.parse(localStorage.getItem(dept.id) || '{}');
          }
        }

        let viewId = getQueryVariable('viewId');

        // console.log("View Details", this.state.savedViewDetails);

        if (deptSettings) {
          // console.log("still got it", deptSettings);
          if (dept.rooms) {
            dept.rooms.forEach(room => {
              // if (deptSettings.hideNonPatientRooms ? room.isPatientRoom : true) {
              if (true) {
                // Filter by Provider
                if (deptSettings.providerSwitch) {
                  if (!room.isPatientRoom) {
                    newArray.push(room);
                  }
                  if (viewId) {
                    // newArray.push(room);
                    if (
                      deptSettings.providersToShow.length === 0 &&
                      room.isPatientRoom
                    ) {
                      newArray.push(room);
                    } else {
                      let found = false;
                      deptSettings.providersToShow.forEach(
                        (provider: string) => {
                          room.roomAssignments.forEach(
                            (providerAssignment: { userId: string }) => {
                              if (providerAssignment.userId === provider) {
                                // newArray.push(room);
                                found = true;
                              }
                            }
                          );
                        }
                      );
                      if (found) {
                        newArray.push(room);
                      }
                    }
                  } else {
                    if (
                      deptSettings.providersToShow.length === 0 &&
                      room.isPatientRoom
                    ) {
                      newArray.push(room);
                    } else {
                      let found = false;
                      deptSettings.providersToShow.forEach(
                        (provider: string) => {
                          room.roomAssignments.forEach(
                            (providerAssignment: { userId: string }) => {
                              if (providerAssignment.userId === provider) {
                                found = true;
                              }
                            }
                          );
                        }
                      );

                      if (found) {
                        newArray.push(room);
                      }
                    }
                  }
                }

                // Filter by Room
                if (deptSettings.roomSwitch) {
                  if (viewId) {
                    if (this.state.hiddenRooms.indexOf(room.id) > -1) {
                      //
                    } else {
                      // console.log("push it");
                      newArray.push(room);
                    }
                  } else {
                    if (deptSettings.hiddenRooms.indexOf(room.id) > -1) {
                      //
                    } else {
                      newArray.push(room);
                    }
                  }
                }

                // Filter by Action
                if (deptSettings.actionSwitch) {
                  newArray.push(room);
                }
              }
            });
          }
        }
      });

      // roomData = newArray;
      // filter out any potential duplicates
      roomData = _.uniqBy(newArray, 'id');

      // console.log("all rooms", allRooms);
      // console.log("roomData", roomData);
      // console.log("Depts to show", this.state.fsDepartmentsToShow);

      // Sort Depts alphabetically UNLESS a sort or present

      if (this.props.loggedInUserData.userSettings) {
        // console.log(JSON.parse(this.props.loggedInUserData.userSettings));
        let savedUserSettings = JSON.parse(
          this.props.loggedInUserData.userSettings
        );

        if (savedUserSettings.deptOrder) {
          var sortedCollection = _.sortBy(allDepts, (item: { id: string }) => {
            return savedUserSettings.deptOrder.indexOf(item.id);
          });

          // console.log("sorted collection", sortedCollection);
          allDepts = sortedCollection;
        } else {
          allDepts = _.orderBy(allDepts, 'deptName', 'asc');
        }
      } else {
        // console.log("this shouldnt be here, should also check if userSettings.deptOrder doesnt exist, then order normally");
        allDepts = _.orderBy(allDepts, 'deptName', 'asc');
      }

      if (allDepts.length > 0) {
        allDepts.forEach(dept => {
          // console.log("DEPT", dept.rooms);
          // console.log("TOSHOW", this.state.fsDepartmentsToShow);
          if (this.state.fsDepartmentsToShow.indexOf(dept.id) > -1) {
            let providers = _.uniqBy(dept.rooms, 'providerName');
            allProviders.push(providers);
          }
        });
      }

      let joinedProviders = [].concat.apply([], allProviders);
      providersToSort = _.uniqBy(joinedProviders, 'providerName');

      // Alphabetize Providers
      providersToSort.sort((a: any, b: any) => {
        if (a.providerName < b.providerName) {
          return -1;
        }
        if (a.providerName > b.providerName) {
          return 1;
        }
        return 0;
      });

      // Display "Unassigned" provider first of the array
      providersToSort.forEach(
        (provider: { providerName: string }, i: number) => {
          if (provider.providerName === 'Unassigned') {
            providersToSort.splice(i, 1);
            providersToSort.unshift(provider);
          }
        }
      );

      if (this.props.loggedInUserData) {
        // console.log("check it", this.state.fsDepartmentsToShow);
        // console.log("ALL DEPTS", allDepts);

        allDepts.forEach(dept => {
          // console.log("here", colorMap[this.state.fsDepartmentsToShow.findIndex((i) => i === "5f43f0a7e0a5d550acd74bc5")].backgroundColor);
          let foundFirstRoomOfDept: any = null;

          if (
            _.includes(this.state.fsDepartmentsToShow, dept.id.toString()) ||
            // _.includes(this.state.fsDepartmentsToShow, dept.deptId.toString()) ||
            _.includes(this.state.fsDepartmentsToShow, dept.id) ||
            // _.includes(this.state.fsDepartmentsToShow, dept.deptId) ||
            this.state.showHistory
          ) {
            let deptColorIndex = this.state.fsDepartmentsToShow.findIndex(
              i => i === dept.id
            );
            let roomColorIndex = this.state.fsDepartmentsToShow.findIndex(
              i => i === dept.id
            );

            function hex2(c: any) {
              c = Math.round(c);
              if (c < 0) c = 0;
              if (c > 255) c = 255;

              var s = c.toString(16);
              if (s.length < 2) s = '0' + s;

              return s;
            }

            function color(r: any, g: any, b: any) {
              return '#' + hex2(r) + hex2(g) + hex2(b);
            }

            function shade(col: any, light: any) {
              var r = parseInt(col.substr(1, 2), 16);
              var g = parseInt(col.substr(3, 2), 16);
              var b = parseInt(col.substr(5, 2), 16);

              if (light < 0) {
                r = (1 + light) * r;
                g = (1 + light) * g;
                b = (1 + light) * b;
              } else {
                r = (1 - light) * r + light * 255;
                g = (1 - light) * g + light * 255;
                b = (1 - light) * b + light * 255;
              }

              return color(r, g, b);
            }

            let deptColor = colorMap[0].backgroundColor;
            let roomColor = colorMap[0].roomColor;

            // console.log("see", deptColorIndex);

            let userSavedDeptColor = null;
            if (this.props.loggedInUserData.userSettings) {
              let userSavedSettings = JSON.parse(
                this.props.loggedInUserData.userSettings
              );
              if (userSavedSettings.deptColors) {
                userSavedSettings.deptColors.forEach((colorDept: any) => {
                  if (colorDept.deptId === dept.id) {
                    userSavedDeptColor = colorDept.color;
                  }
                });
              }
            }

            // console.log("check", userSavedSettings);
            if (deptColorIndex >= 0) {
              if (this.state.unsavedDeptColorDeptId === dept.id) {
                deptColor = this.state.unsavedDeptColor;
              } else if (userSavedDeptColor) {
                deptColor = userSavedDeptColor;
              } else {
                deptColor = colorMap[deptColorIndex].backgroundColor;
              }
            }

            if (roomColorIndex >= 0) {
              if (this.state.unsavedDeptColorDeptId === dept.id) {
                roomColor = shade(this.state.unsavedDeptColor, 0.5);
              } else if (userSavedDeptColor) {
                roomColor = shade(deptColor, 0.5);
              } else {
                roomColor = colorMap[roomColorIndex].roomColor;
              }
            }

            let filteringApplied = false;
            // let viewId = getQueryVariable("viewId");

            let deptSettings: any = null;

            if (this.state.viewName) {
              // console.log("UMM", this.state.viewDeptSettings);
              let found = false;
              this.state.viewDeptSettings.forEach(deptViewSetting => {
                if (deptViewSetting.deptId === dept.id) {
                  // console.log("Found the VIEW dept setting", deptViewSetting);
                  deptSettings = deptViewSetting;
                  found = true;
                }
              });

              if (!found) {
                // console.log("MISSING");
                if (!getQueryVariable('viewId')) {
                  deptSettings = JSON.parse(
                    localStorage.getItem(dept.id) || '{}'
                  );
                }
              }
            } else if (getQueryVariable('newView')) {
              let preFilteringDeptSettingsToModify = [
                ...this.state.preFilteringDeptSettings,
              ];
              // console.log("Made it", preFilteringDeptSettingsToModify);
              preFilteringDeptSettingsToModify.forEach((preDept, i) => {
                if (preDept.deptId === dept.id) {
                  // console.log("Found pre dept to modify");
                  deptSettings = preDept;
                }
              });
            } else {
              // console.log("Standard dept settings, no view or new view");
              deptSettings = JSON.parse(localStorage.getItem(dept.id) || '{}');
            }

            if (this.state.deptHistory.length > 0) {
              deptSettings = null;
            }

            // console.log("deptSettings", deptSettings);
            if (deptSettings) {
              if (deptSettings.roomSwitch) {
                filteringApplied = true;
              }

              if (deptSettings.hideNonPatientRooms) {
                filteringApplied = true;
              }

              if (deptSettings.providerSwitch) {
                if (deptSettings.providersToShow.length > 0) {
                  filteringApplied = true;
                }
              }
              if (deptSettings.actionSwitch) {
                filteringApplied = true;
              }
            }

            // let deptRoomCount = 0;

            let deptRooms: any = [];

            // console.log("Room data", roomData);

            roomData.forEach(room => {
              if (room.mongoDeptId === dept.id) {
                // deptRoomCount++;
                deptRooms.push(room);
              }
            });

            deptRooms.sort((a: any, b: any) => {
              // console.log("sorting?");
              if (a.roomName < b.roomName) {
                return -1;
              }
              if (a.roomName > b.roomName) {
                return 1;
              }
              return 0;
            });

            let deptCard = (
              <div style={{ position: 'relative' }}>
                {allowSignalR ? (
                  <>
                    {this.props.loggedInUserData.id &&
                    connection.state === 'Connected' ? (
                      <DeptSubscription dept={dept} />
                    ) : null}
                  </>
                ) : null}

                {Browser ? (
                  Browser.name! === 'ie' ? null : (
                    <div
                      style={{
                        backgroundColor: deptColor,
                        position: 'absolute',
                        color: 'white',
                        padding: '8px 10px 10px 10px',
                      }}
                      className="flex"
                    >
                      <PaintIcon
                        style={{
                          cursor: 'pointer',
                          fontSize: 28,
                          maxHeight: 24,
                        }}
                        onClick={() =>
                          this.setState({ showColorPicker: dept.id })
                        }
                      />
                      {/* <a href={"/documentation?deptId=" + dept.id} target="_blank" rel="noreferrer"> */}
                      <QuestionIcon
                        onClick={() => this.viewDocumentation(dept.id)}
                        style={{
                          cursor: 'pointer',
                          fontSize: 28,
                          maxHeight: 24,
                          textDecoration: 'none',
                          color: 'white',
                        }}
                      />
                      {/* </a> */}
                      <img
                        src={filteringApplied ? filterIcon : filterIconHollow}
                        alt="="
                        style={{
                          cursor: 'pointer',
                          maxWidth: 20,
                          marginBottom: 2,
                          marginLeft: 4,
                        }}
                        onClick={() => this.openDepartmentSettingsDialog(dept)}
                      />
                    </div>
                  )
                ) : null}

                <div
                  style={{
                    backgroundColor: deptColor,
                    position: 'absolute',
                    color: 'white',
                    padding: 10,
                    right: 0,
                  }}
                ></div>

                <div
                  id="deptName"
                  className="deptCard"
                  style={{
                    // marginTop: 3,
                    // borderRadius: 4,
                    // fontSize: 42,
                    textAlign: 'center',
                    color: 'white',
                    maxWidth: 258,
                    width: 257,
                    height: 350,
                    // position: "relative",
                    // backgroundColor: "#0096DB",
                    backgroundColor: deptColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ padding: 10 }}>
                    <div>
                      {/* <div>{dept.deptName}</div> */}
                      <Typography variant="h4" component="h2">
                        {dept.deptName}
                      </Typography>
                      {/* <div style={{ fontSize: 8 }}>{dept.id}</div> */}
                    </div>

                    <Dialog
                      // hideBackdrop={true}
                      open={this.state.showColorPicker === dept.id}
                      onClose={this.closeColorPicker}
                      BackdropProps={{
                        classes: {
                          root: classes.root,
                        },
                      }}
                      PaperProps={{
                        classes: {
                          root: classes.paper,
                        },
                      }}
                      PaperComponent={PaperComponent}
                    >
                      <div
                        style={
                          this.state.darkMode
                            ? {
                                backgroundColor: '#333333',
                                // border: "2px solid #666666",
                                color: 'white',
                              }
                            : {
                                backgroundColor: 'white',
                                // border: "2px solid lightgray",
                              }
                        }
                      >
                        <DialogTitle
                          style={{ cursor: 'move' }}
                          id="draggableArea"
                        >
                          Choose a new color for the Department
                        </DialogTitle>

                        <DialogContent>
                          <ChromePicker
                            // @ts-ignore
                            style={{ margin: 'auto' }}
                            width="100%"
                            disableAlpha={true}
                            color={
                              this.state.unsavedDeptColor
                                ? this.state.unsavedDeptColor
                                : deptColor
                            }
                            onChangeComplete={color =>
                              this.handleColorChange(color, dept)
                            }
                          />
                          {/* <ColorPicker deptColor={deptColor} dept={dept} /> */}
                          <Button
                            style={{ marginTop: 10 }}
                            onClick={() => this.saveDepartmentColor(null)}
                            color="primary"
                            variant="contained"
                          >
                            Save
                          </Button>
                          <Button
                            style={{ marginTop: 10, marginLeft: 10 }}
                            onClick={() => this.saveDepartmentColor(dept.id)}
                            variant="contained"
                          >
                            Reset to Default
                          </Button>
                        </DialogContent>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </div>
            );

            // if (deptRoomCount === 0) {
            // IF department has NO rooms, we need to render room card and patients card seperatly to keep them grouped to stay on same line.
            // console.log("HAS NO ROOMS")
            // rooms.push(
            //   <React.Fragment key={dept.id}>
            //     <div id="deptName" style={{ display: "flex" }}>
            //       {deptCard}

            //       {dept.isFrontDeskIntegration ? (
            //         <div style={{ position: "relative" }}>
            //           <PatientsCard
            //             // @ts-ignore
            //             roomColor={roomColor}
            //             department={dept}
            //             allDepts={allDepts}
            //             loggedInUserData={this.props.loggedInUserData}
            //             timeZone={timeZone}
            //             selectedHistoryTime={null}
            //             refetchFsData={this.manuallyRefetchFsData}
            //             serverTimeOffset={serverTimeOffset}
            //             toggleWaitingRoomProvider={(provider, visibleWaitingRoomProviders, deptId) => this.toggleWaitingRoomProvider(provider, visibleWaitingRoomProviders, deptId)}
            //             viewDeptSettings={this.state.viewDeptSettings}
            //           // allProviders={this.state.allProviders}
            //           />
            //         </div>
            //       ) : null}
            //       {!this.state.largeCards && !this.state.hideLargeCards ? (
            //         <>
            //           {/* <div style={{ backgroundColor: deptColor, height: 10, width: "100%", marginBottom: 5 }}></div> */}
            //           {/* @ts-ignore */}
            //           {dept.dailyData ? <VisitsFinishedCard providerReporting={dept} largeCards={this.state.largeCards} roomColor={roomColor} /> : null}
            //           {dept.dailyData ? <CycleTimesCard providerReporting={dept} largeCards={this.state.largeCards} roomColor={roomColor} /> : null}
            //         </>
            //       ) : null}
            //     </div>
            //   </React.Fragment>
            // );
            // } else {
            // IF department has rooms, go usual route.
            // console.log("going normal route!");

            rooms.push(
              <React.Fragment key={dept.id}>
                {/* Check if front desk is enabled for department, if so, combine dept card and patients card as one */}
                {dept.isFrontDeskIntegration ? (
                  <div id="deptName" style={{ display: 'flex' }}>
                    {deptCard}

                    <div
                      style={{ position: 'relative' }}
                      onClick={() =>
                        canDo(
                          ['Update Flowstation'],
                          this.props.loggedInUserData
                        )
                          ? null
                          : this.showReadOnlyAlert()
                      }
                    >
                      <PatientsCard
                        // @ts-ignore
                        roomColor={roomColor}
                        department={dept}
                        allDepts={allDepts}
                        // loadedSoundObjects={loadedSoundObjects}
                        loggedInUserData={this.props.loggedInUserData}
                        // providersToShow={this.state.providersToShow}
                        // filterByProvider={this.state.providerSwitch}
                        timeZone={timeZone}
                        selectedHistoryTime={this.state.selectedHistoryTime}
                        refetchFsData={this.manuallyRefetchFsData}
                        serverTimeOffset={serverTimeOffset}
                        toggleWaitingRoomProvider={(
                          provider,
                          visibleWaitingRoomProviders,
                          deptId
                        ) =>
                          this.toggleWaitingRoomProvider(
                            provider,
                            visibleWaitingRoomProviders,
                            deptId
                          )
                        }
                        viewDeptSettings={this.state.viewDeptSettings}
                        // allProviders={this.state.allProviders}
                      />
                    </div>
                  </div>
                ) : null}

                {deptRooms.map((room: any, i: any) => {
                  // console.log("room data", roomData);
                  // console.log(deptIndex, index);
                  // console.log(room.id + " and " + dept.id, index);
                  // console.log("dept settings!!", deptSettings);
                  if (
                    room.mongoDeptId === dept.id &&
                    (deptSettings?.hideNonPatientRooms
                      ? room.isPatientRoom
                      : true)
                  ) {
                    if (foundFirstRoomOfDept === null) {
                      // console.log("found fist?", foundFirstRoomOfDept);
                      // USE THIS TO IDENTIFY THE FIRST ROOM OF THE DEPT TO COMBINE THE DEPTNAME WITH THE ROOM CARD
                      foundFirstRoomOfDept = room.id;
                    }

                    // let deptColor = colorMap[allDepts.findIndex((x) => x.deptName === dept.deptName)].backgroundColor;

                    // console.log("Room data", room);

                    return (
                      <div id="deptName" key={i} style={{ display: 'flex' }}>
                        {/* If NOT front desk integration and IS the first room, then combine dept card with first room */}
                        {foundFirstRoomOfDept === room.id &&
                        !dept.isFrontDeskIntegration ? (
                          <>{deptCard}</>
                        ) : null}

                        {/* {dept.isFrontDeskIntegration && foundFirstRoomOfDept === room.id ? (
                            <div
                              style={{ position: "relative" }}
                              onClick={() => (canDo(["Update Flowstation"], this.props.loggedInUserData) ? null : this.showReadOnlyAlert())}
                            >
                              <PatientsCard
                                // @ts-ignore
                                roomColor={roomColor}
                                department={dept}
                                allDepts={allDepts}
                                // loadedSoundObjects={loadedSoundObjects}
                                loggedInUserData={this.props.loggedInUserData}
                                // providersToShow={this.state.providersToShow}
                                // filterByProvider={this.state.providerSwitch}
                                timeZone={timeZone}
                                selectedHistoryTime={this.state.selectedHistoryTime}
                                refetchFsData={this.manuallyRefetchFsData}
                                serverTimeOffset={serverTimeOffset}
                                toggleWaitingRoomProvider={(provider, visibleWaitingRoomProviders, deptId) => this.toggleWaitingRoomProvider(provider, visibleWaitingRoomProviders, deptId)}
                                viewDeptSettings={this.state.viewDeptSettings}
                              // allProviders={this.state.allProviders}
                              />
                            </div>
                          ) : null} */}

                        <span
                          style={{ maxWidth: 257 }}
                          onClick={() =>
                            canDo(
                              ['Update Flowstation'],
                              this.props.loggedInUserData
                            )
                              ? null
                              : this.showReadOnlyAlert()
                          }
                        >
                          <RoomItem
                            key={room.id}
                            loggedInUserData={this.props.loggedInUserData}
                            groupData={dept}
                            cardData={room}
                            authenticated={this.props.authenticated}
                            mute={this.state.mute}
                            mutedActions={mutedActions}
                            disableCards={false}
                            // cardInterval={this.state.cardInterval}
                            department={dept}
                            allDepts={allDepts}
                            roomColor={roomColor}
                            timeZone={timeZone}
                            loadedSoundObjects={loadedSoundObjects}
                            roomToFlash={this.state.roomToFlash}
                            deptSettings={
                              deptSettings
                                ? deptSettings
                                : JSON.parse(
                                    localStorage.getItem(dept.id) || '{}'
                                  )
                            }
                            selectedHistoryTime={this.state.selectedHistoryTime}
                            refetchFsData={this.manuallyRefetchFsData}
                            highlightMember={this.state.highlightMember}
                            serverTimeOffset={serverTimeOffset}
                            org={this.props.organization}
                            playConfetti={confettiTime =>
                              this.playConfetti(confettiTime)
                            }
                          />
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
                {!this.state.largeCards && !this.state.hideLargeCards ? (
                  <>
                    {/* <div style={{ backgroundColor: deptColor, height: 10, width: "100%", marginBottom: 5 }}></div> */}
                    {/* @ts-ignore */}
                    {dept.dailyData ? (
                      <VisitsFinishedCard
                        // @ts-ignore
                        providerReporting={dept}
                        largeCards={this.state.largeCards}
                        roomColor={roomColor}
                      />
                    ) : null}
                    {dept.dailyData ? (
                      <CycleTimesCard
                        providerReporting={dept}
                        largeCards={this.state.largeCards}
                        roomColor={roomColor}
                      />
                    ) : null}
                  </>
                ) : null}
              </React.Fragment>
            );
            // }
          }
        });
      }
    }

    if (Browser) {
      if (
        document.getElementById('fsMainContainer') &&
        Browser.name !== 'ie' &&
        this.state.autoZoom
      ) {
        // console.log("Window width", window.innerWidth);

        if (fsContainerHeight === null) {
          // Set initial fsContainerHeight
          // @ts-ignore
          fsContainerHeight =
            document.getElementById('fsMainContainer').clientHeight;
        }

        if (pageWidth === null) {
          // Set page initial width
          pageWidth = window.innerWidth;
        }

        // if (window.innerWidth > document.body.clientWidth) {
        if (document.body.scrollHeight >= document.body.clientHeight) {
          // Scrollbar is visible, resize it
          // console.log("SCROLLBAR VISIBLE");
          pageWidth = window.innerWidth;
          this.resizeFlowstation(allDepts);
        }

        // console.log(pageWidth, window.innerWidth);
        if (pageWidth < window.innerWidth) {
          // Width increased, resize it
          // console.log("WIDTH INCREASED");
          pageWidth = window.innerWidth;
          this.resizeFlowstation(allDepts);
        }
      }
    }

    // Initial resize
    if (Browser) {
      if (
        document.getElementById('fsMainContainer') &&
        document.getElementById('fsAppBar') &&
        zoom === null &&
        Browser.name !== 'ie' &&
        this.state.autoZoom
      ) {
        // console.log("FIRE INITIAL");
        this.resizeFlowstation(allDepts);
      }
    }

    // Force zoom 50 if screen width is less than 500
    if (window.innerWidth < 500) {
      zoom = 45;
    }

    // console.log("STATE", this.state);

    if (soundsLoaded === false) {
      // console.log("1");
      if (this.props.department) {
        // console.log("2");
        // console.log("GOT DEPARTMENTS", this.props.department.actionsList);

        if (this.props.department.actionsList) {
          let preppedSounds: any = [];

          this.props.department.actionsList.forEach(async sound => {
            if (sound.soundUrl) {
              preppedSounds.push(sound);
            }
          });

          // console.log("sibling available?", this.props.siblingDepts);
          allSounds = _.uniqBy(preppedSounds, 'soundUrl');
          // console.log("3");

          // console.log("4");
          allSounds.forEach(async (sound: any, i: any) => {
            loadedSoundObjects[i] = {
              soundUrl: sound.soundUrl,
              id: sound.id,
              sound: new Audio(sound.soundUrl),
            };
          });

          // console.log("loaded", allSounds);

          // console.log("5");
          soundsLoaded = true;
        }
      }
    }
    // else {
    //   console.log("GOOD TO GO", allSounds, loadedSoundObjects);
    // }

    // console.log("ALL SOUNDS", allSounds, loadedSoundObjects);

    // console.log("loaded and stored", loadedSoundObjects);

    // console.log("providersToSort", providersToSort);
    let isFrontDeskIntegration = _.some(allDepts, {
      isFrontDeskIntegration: true,
    });

    let allViews = [];
    let currentViewInfo: any = null;

    let userViews = [];
    let publishedViews = [];

    if (!sendToLogin && this.props.organization) {
      if (this.props.loggedInUserData.userSettings) {
        let savedSettings: any = {};
        savedSettings = JSON.parse(this.props.loggedInUserData.userSettings);
        if (savedSettings.userViews) {
          userViews = savedSettings.userViews;
        }
      }

      if (this.props.organization.publishedViews) {
        // console.log("check", this.props.organization);
        if (this.props.organization.publishedViews.length > 0) {
          publishedViews = JSON.parse(this.props.organization.publishedViews);
        }
      }

      // console.log("user views", userViews);

      allViews = userViews.concat(publishedViews);

      allViews.forEach((view: IView) => {
        if (view.viewId === getQueryVariable('viewId')) {
          currentViewInfo = view;
        }
      });
    }

    userViews.sort((a: any, b: any) => {
      return a.viewName < b.viewName ? -1 : a.viewName > b.viewName ? 1 : 0;
    });

    // console.log("current view info", currentViewInfo);

    // console.log("org info", this.props.organization);
    let deviceId = null;
    if (getQueryVariable('deviceId')) {
      deviceId = getQueryVariable('deviceId');
    }

    let isDev = false;

    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'dev.synctimes.com'
    ) {
      isDev = true;
    }

    let departmentList: any = [];
    allDepts.forEach((dept: IDepartment) => {
      if (this.state.fsDepartmentsToShow.includes(dept.id)) {
        departmentList.push(dept);
      }
    });

    let filteredActiveAlerts: any[] = [];

    this.state.activeAlerts.forEach((activeAlert: any) => {
      let showAction = true;

      let siteExists = false;
      this.state.fsDepartmentsToShow.forEach((deptIdToShow: string) => {
        if (
          this.props.groupData.id === deptIdToShow &&
          this.props.groupData.mongoSiteId === activeAlert.siteId
        ) {
          siteExists = true;
        } else {
          this.props.siblingDepts.forEach((siblingDept: any) => {
            if (
              siblingDept.id === deptIdToShow &&
              siblingDept.mongoSiteId === activeAlert.siteId
            ) {
              siteExists = true;
            }
          });
        }
      });

      if (activeAlert.isSubscribedAction === false && siteExists === false) {
        showAction = false;
      }

      if (showAction) {
        filteredActiveAlerts.push(activeAlert);
      }
    });

    // Updates tab title with number of active alerts
    if (filteredActiveAlerts.length === 0) {
      document.title = 'SyncTimes';
    }
    if (filteredActiveAlerts.length > 0) {
      document.title = '(' + filteredActiveAlerts.length + ') SyncTimes';
    }

    // Sends electron parent iframe the number of active alerts
    if (window.self !== window.top) {
      window.parent.postMessage(
        {
          event_id: 'update_notification_count',
          data: {
            badgeCount: filteredActiveAlerts.length,
          },
        },
        '*'
      );
    }

    let preFilterDeptOptions: IDepartment[] = [];
    this.props.organizationDepts.forEach((dept: IDepartment) => {
      if (dept.isActive) {
        preFilterDeptOptions.push(dept);
      }
    });
    // alphabetize options
    preFilterDeptOptions.sort((a, b) => {
      if (a.siteName + a.deptName < b.siteName + b.deptName) {
        return -1;
      }
      if (a.siteName + a.deptName > b.siteName + b.deptName) {
        return 1;
      }
      return 0;
    });

    let deptSearchIndex: any = [];

    preFilterDeptOptions.forEach(dept => {
      if (dept.isActive) {
        deptSearchIndex.push({
          name: 'Flowstation - ' + dept.siteName + ' ' + dept.deptName,
          type: 'Flowstation',
          url: '/?groupId=' + dept.id,
          description: '',
        });
      }
    });

    let activeSites: any[] = [];
    this.props.sites.forEach((site: ISite) => {
      if (site.isActive) {
        activeSites.push(site);
      }
    });

    // console.log('active sites', activeSites)

    if (this.state.selectedSiteFilter) {
      preFilterDeptOptions = preFilterDeptOptions.filter(
        (dept: IDepartment) => {
          return dept.mongoSiteId === this.state.selectedSiteFilter.id;
        }
      );
    }

    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;

    // console.log("playConfetti", this.state.playConfetti);

    let currentTime = moment().tz(timeZone).local();

    let isTeamsApp: boolean = false;

    if (
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      isTeamsApp = true;
    }

    let alphabetizedOrganizations = _.orderBy(
      this.props.organizations,
      'orgName',
      'asc'
    );

    let filteredAlphabetizedOrganizations = alphabetizedOrganizations.filter(
      (org: IOrganization) => {
        // check if orgName includes this.state.orgFilterText
        return org.orgName
          .toLowerCase()
          .includes(this.state.orgFilterText.toLowerCase());
      }
    );

    let isIos: boolean = false;
    if (
      Browser?.name === 'ios' ||
      Browser?.name === 'crios' ||
      Browser?.name === 'ios-webview'
    ) {
      isIos = true;
      // probably don't set ismobile = true because of ipads/tablets
      // isMobile = true;
    }

    if (!isIos || isOrientationChanged) {
      loadedPageWidth = window.innerWidth;
      isOrientationChanged = false;
    }

    return (
      <MuiThemeProvider theme={theme}>
        {canDo(['SyncAdmin'], this.props.loggedInUserData) ? (
          <div
            onClick={this.showOrganizations}
            style={{
              position: 'fixed',
              marginBottom: 10,
              marginLeft: 10,
              color: 'gray',
              bottom: 0,
              zIndex: 10000,
              fontSize: 11,
              cursor: 'pointer',
            }}
          >
            {this.props.organization.orgName}
          </div>
        ) : null}

        {this.state.showOrganizationList ? (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              marginLeft: 10,
              marginBottom: 25,
              zIndex: 10000,
              backgroundColor: 'white',
              border: '1px solid lightgray',
              padding: 10,
              fontSize: 14,
              borderRadius: 5,
              overflow: 'overlay',
              maxHeight: '70vh',
            }}
          >
            
            {alphabetizedOrganizations.length > 0 ? (
              <div>
                <ClickAwayListener
                  onClickAway={() =>
                    this.setState({ showOrganizationList: false })
                  }
                >
                  <div>
                    {filteredAlphabetizedOrganizations.map(
                      (org: IOrganization, i: number) => {
                        if (org.isActive) {
                          return (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={org.id}
                              className={classes.orgItem}
                              onClick={() => this.mockOrganization(org.id)}
                            >
                              {org.orgName}
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                </ClickAwayListener>
                <input
                  style={{ width: '97%' }}
                  autoFocus
                  value={this.state.orgFilterText}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.mockOrganization(
                        filteredAlphabetizedOrganizations[0].id
                      );
                    }
                  }}
                  onChange={e =>
                    this.setState({
                      orgFilterText: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <div>Loading Organizations...</div>
            )}
          </div>
        ) : null}

        {this.state.playConfetti && this.state.confettiTime ? (
          <>
            {currentTime.diff(this.state.confettiTime, 'seconds') < 30 ? (
              <Confetti
                run={this.state.playConfetti}
                width={windowWidth}
                height={windowHeight}
                recycle={false}
                numberOfPieces={200}
                onConfettiComplete={() =>
                  this.setState({
                    playConfetti: false,
                    confettiTime: null,
                  })
                }
              />
            ) : null}
          </>
        ) : null}

        <AppSearch
          showSearch={this.state.showAppSearch}
          searchData={[...deptSearchIndex]}
          closeSearch={() => this.setState({ showAppSearch: false })}
          openPowerReport={() => this.openPowerReport()}
        />

        {this.state.playCongratulationsConfetti ? (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              marginBottom: 10,
              marginLeft: 10,
              backgroundColor: '#4CB946',
              color: 'white',
              borderRadius: 5,
              padding: 10,
              width: 300,
              maxWidth: 300,
              fontSize: 25,
              zIndex: 1000,
            }}
          >
            <Confetti
              run={this.state.playCongratulationsConfetti}
              width={320}
              height={400}
              recycle={true}
              numberOfPieces={50}
            />
            <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
              {/* Header text here! */}
              {this.state.congratData?.congratulatoryHeader}
            </div>
            {this.state.congratData?.congratulatoryPost}
            {/* "I love my provider and care team! Also this is a very long response, I wonder how much text we can fit here." */}
          </div>
        ) : null}

        {/* <button onClick={this.testCongrats}>Test Congrats</button> */}

        {allowSignalR ? (
          <>
            {connection.state === 'Connected' && deviceId ? (
              <DeviceSubscription deviceId={deviceId} />
            ) : null}
            {this.props.loggedInUserData.id &&
            connection.state === 'Connected' ? (
              <UserSubscription userInfo={this.props.loggedInUserData} />
            ) : null}
          </>
        ) : null}

        <Snackbar
          className={classes.browserAlertSnackbar}
          style={{ height: '100%', background: 'transparent' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={
            Browser
              ? Browser.name === 'ie'
                ? this.state.installChromeMessage
                : false
              : false
          }
        >
          <SnackbarContent
            className={classes.updateSnack}
            // style={{ padding: 0 }}
            message={
              <div>
                <span className={classes.updateMessage}>
                  <strong>Notice</strong>
                  <div>
                    Internet Explorer is not supported by SyncTimes. Please use
                    Chrome or download the SyncTimes&nbsp;
                    <a href="https://cesiumstorage.blob.core.windows.net/desktopapplications/SyncTimes%20Setup.exe">
                      Desktop App
                    </a>
                    &nbsp;from the homepage for the best user experience!
                  </div>
                  <div style={{ marginTop: 15 }}>
                    <Button
                      onClick={this.closeInstallChromeMessage}
                      variant="contained"
                      color="primary"
                    >
                      Understood
                    </Button>
                  </div>
                </span>
              </div>
            }
          />
        </Snackbar>

        <div onClick={this.startInteraction}>
          <Dialog
            hideBackdrop={true}
            open={
              this.state.viewPowerReport &&
              this.state.reportEmbedDetails &&
              this.props.groupData
                ? true
                : false
            }
            style={{ width: '100%' }}
            transitionDuration={{ enter: 0, exit: 0 }}
            fullWidth={true}
            maxWidth={'xl'}
            fullScreen={true}
          >
            <div style={{ height: '90vh' }}>
              {/* {console.log("embeddata", this.state.reportEmbedDetails)} */}
              <PowerReport
                embedData={this.state.reportEmbedDetails}
                closeReport={() => this.setState({ viewPowerReport: false })}
                dept={this.props.groupData}
                loggedInUserData={this.props.loggedInUserData}
                isFrontDeskIntegration={isFrontDeskIntegration}
                timeZone={timeZone}
                org={this.props.organization}
              />
            </div>
          </Dialog>

          {/* <Dialog
            open={this.state.deptHistory.length > 0}
            BackdropProps={{
              classes: {
                root: classes.root,
              },
            }}
            PaperProps={{
              classes: {
                root: classes.paper,
              },
            }}
            PaperComponent={PaperComponent}
          >
            <div
              style={{
                backgroundColor: "white",
                border: "2px solid lightgray",
              }}
            >
              <DialogTitle style={{ cursor: "move" }} id="draggableArea">
                History (Past 10 Minutes)
              </DialogTitle>

              <DialogContent>
                <div>
                  <div style={{ marginBottom: 10 }}>
                    {this.state.selectedHistoryVal !== null ? moment.utc(this.state.selectedHistoryTime).tz(timeZone).format("MM/DD/YY, h:mm a") : null}
                  </div>

                  <div>
                    <Slider
                      style={{ width: "100%" }}
                      defaultValue={0}
                      aria-labelledby="discrete-slider"
                      step={1}
                      marks
                      min={0}
                      max={this.state.deptHistory.length}
                      onChange={(e, val) => this.selectHistory(e, val)}
                    />
                  </div>
                  <Button variant="contained" color="primary" onClick={this.returnFromHistory}>
                    Return To Live
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog> */}

          {this.props.errorLoggedInUser === false ? (
            <div>
              {this.state.missingView ? (
                <div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '45%',
                      left: '50%',
                      textAlign: 'center',
                      transform: 'translate(-50%, -50%)',
                      WebkitTransform: 'translate(-50%, -50%)',
                    }}
                  >
                    <img src={SynctimesIcon} style={{ height: 200 }} alt="#" />
                    <br />
                    <div
                      style={{
                        marginBottom: 10,
                        marginTop: 20,
                        fontSize: '1.5em',
                      }}
                    >
                      The view you are looking for doesn't exist.
                    </div>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        let newUrl = getQueryVariable('deviceId')
                          ? '/?deviceId=' + getQueryVariable('deviceId')
                          : '/';
                        window.location.replace(newUrl);
                      }}
                    >
                      Return Home
                    </Button>
                  </div>
                </div>
              ) : null}

              {/* TODO Add back once packages have been resolved */}
              {/* <LoadingBar
                style={{
                  backgroundColor: "#4CB946",
                  height: 5,
                  position: "fixed",
                  zIndex: 1,
                }}
              /> */}

              {/* {this.props.loggedInUserData.id && socket.connected ? <UserSubscription userInfo={this.props.loggedInUserData} /> : null} */}

              <Snackbar
                style={{ backgroundColor: '#FFFFFF', color: 'black' }}
                open={this.state.showPromptToSaveView}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <SnackbarContent
                  style={{ backgroundColor: '#FFFFFF', color: 'black' }}
                  message={
                    <span>
                      <div>
                        Would you like to save changes to your existing view?{' '}
                        <br />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            marginTop: 10,
                          }}
                        >
                          {currentViewInfo !== null ? (
                            <>
                              {currentViewInfo.publisherId ? (
                                <>
                                  {this.props.loggedInUserData.isAdmin ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        this.updateView(
                                          getQueryVariable('viewId')
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                  ) : null}
                                </>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    this.updateView(getQueryVariable('viewId'))
                                  }
                                >
                                  Save
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                onClick={() => {
                                  this.setState({
                                    saveAsNewView: true,
                                    openDeptDialog: false,
                                    showPromptToSaveView: false,
                                  });
                                }}
                              >
                                Save as new View
                              </Button>
                              <Button onClick={() => this.revertView(allDepts)}>
                                Revert
                              </Button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </span>
                  }
                />
              </Snackbar>

              {groupId && !this.state.mute ? (
                <Snackbar
                  style={{
                    backgroundColor: '#4CB946',
                    cursor: 'pointer',
                    top: 6,
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={this.state.promptToEnableSounds}
                >
                  <SnackbarContent
                    style={{ backgroundColor: '#4CB946' }}
                    message={
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <VolumeUpIcon
                          style={{
                            fontSize: 22,
                            opacity: 0.9,
                            marginRight: theme.spacing(),
                          }}
                        />
                        <span>To enable sounds, click here</span>
                      </span>
                    }
                  />
                </Snackbar>
              ) : null}

              <div>
                {/* {console.log("rooms", rooms)} */}
                {/* {console.log(this.props.groupData, this.props.groupData.rooms, allowedToViewFS)} */}
                {/* {console.log("Viewing", this.props.organization)} */}

                <div
                  className={classes.flowstationContainer}
                  style={
                    this.state.darkMode ? { backgroundColor: '#212121' } : {}
                  }
                  // style={this.props.organization.id !== "6481efc72341679eceb35144" ?this.state.darkMode ? {
                  //   // backgroundColor: "#212121",
                  //   backgroundImage: `url(${Christmasdark})`,
                  // } : {
                  //   backgroundImage: `url(${Christmaslight})`,
                  // } :this.state.darkMode ? {
                  //   backgroundColor: "#212121",
                  // } : {
                  // }}
                >
                  {this.props.loggedInUserData.resetPasswordNextLogin ? (
                    // @ts-ignore
                    <ResetPasswordDialog
                      email={this.props.loggedInUserData.userEmail}
                      open={true}
                    />
                  ) : (
                    <div />
                  )}
                  {sendToLogin && !this.state.orgId ? (
                    <Navigate
                      to={{
                        pathname: '/login',
                        //@ts-ignore
                        url: {
                          cameFrom: window.location.href,
                          username: getQueryVariable('username'),
                          password: getQueryVariable('password'),
                        },
                      }}
                    />
                  ) : (
                    <div />
                  )}
                  {this.state.fireLogin ? (
                    <Navigate
                      to={{
                        pathname: '/login',
                        //@ts-ignore
                        url: {
                          cameFrom: window.location.href,
                          username: getQueryVariable('username'),
                          password: getQueryVariable('password'),
                        },
                      }}
                    />
                  ) : (
                    <div />
                  )}

                  {this.props.groupData &&
                  this.props.groupData.rooms &&
                  allowedToViewFS ? (
                    <div
                      style={
                        this.state.darkMode &&
                        this.props.organization.id !==
                          '6481efc72341679eceb35144'
                          ? {
                              // backgroundColor: "#212121", // Festive comment out
                            }
                          : {}
                      }
                    >
                      <div className={classes.grow}>
                        {/* <AppBar position="static" style={{ backgroundColor: "#ffffff" }}> */}
                        <Toolbar
                          id="fsAppBar"
                          className={classes.appBar}
                          style={
                            this.state.darkMode
                              ? {
                                  backgroundColor: '#333333',
                                  color: '#ffffff !important',
                                }
                              : {}
                          }
                        >
                          {isTeamsApp ? null : (
                            <IconButton
                              edge="start"
                              className={classes.smallSyncLogo}
                              color="inherit"
                              aria-label="open drawer"
                              onClick={this.returnHome}
                            >
                              {/* <MenuIcon /> */}
                              <img
                                src={SynctimesIcon}
                                alt=""
                                style={{ maxHeight: 40 }}
                              />
                            </IconButton>
                          )}

                          {isTeamsApp ? null : (
                            <Typography
                              className={classes.title}
                              variant="h6"
                              noWrap
                            >
                              <img
                                style={
                                  getQueryVariable('username')
                                    ? {}
                                    : { cursor: 'pointer' }
                                }
                                className={classes.headerImage}
                                src={isDev ? synctimesLogoDev : synctimesLogo}
                                alt="SyncTimes"
                                onClick={this.returnHome}
                              />
                            </Typography>
                          )}

                          <div
                            className={classes.orgName}
                            style={
                              this.state.darkMode
                                ? { color: 'white' }
                                : { color: '#7e7e7e' }
                            }
                            onClick={this.handleView}
                          >
                            <div>
                              <PrettyData data={{
                                url: window.location.href
                              }} />
                            </div>
                            {this.state.viewName ? (
                              <Typography
                                className={classes.siteName}
                                style={
                                  allViews.length > 0
                                    ? { cursor: 'pointer' }
                                    : {}
                                }
                              >
                                {this.state.viewName}{' '}
                                {allViews.length > 0 ? (
                                  <KeyboardArrowDown />
                                ) : null}
                              </Typography>
                            ) : (
                              <Typography
                                className={classes.siteName}
                                style={
                                  allViews.length > 0
                                    ? { cursor: 'pointer' }
                                    : {}
                                }
                              >
                                {this.props.organization ? (
                                  <>
                                    {this.props.organization.orgName}{' '}
                                    {allViews.length > 0 ? (
                                      <KeyboardArrowDown />
                                    ) : null}
                                  </>
                                ) : null}
                              </Typography>
                            )}

                            <Typography
                              className={classes.siteTime}
                              style={
                                this.state.darkMode
                                  ? { color: 'white' }
                                  : { color: '#7e7e7e' }
                              }
                            >
                              <>
                                {moment()
                                  .add('milliseconds', serverTimeOffset)
                                  .tz(timeZone)
                                  .format('MM/DD/YY, h:mm a')}
                              </>
                              {/* {this.state.selectedHistoryTime ? (
                                <>{moment.utc(this.state.selectedHistoryTime).tz(timeZone).format("MM/DD/YY, h:mm a")}</>
                              ) : (
                                <>{moment().tz(timeZone).format("MM/DD/YY, h:mm a")}</>
                              )} */}
                            </Typography>
                          </div>
                          <div className={classes.grow} />

                          <ClickAwayListener
                            onClickAway={this.closeLocateStaff.bind(this)}
                          >
                            <div>
                              <LocateStaffPopoverContainer
                                openLocateStaff={this.openLocateStaff}
                                serverTimeOffset={serverTimeOffset}
                                onHighlightMember={staffMember => {
                                  this.highlightMember(staffMember);
                                  this.closeLocateStaff();
                                }}
                                onClose={this.closeLocateStaff}
                                open={this.state.locateStaffPopover}
                              />
                            </div>
                          </ClickAwayListener>

                          <div className={classes.sectionMobile}>
                            {/* {this.state.deptHistory.length > 0 ? null : <Button onClick={() => this.fetchHistoricalDept(timeZone)}>History</Button>} */}

                            {!isMobile &&
                              !isDesktop &&
                              !getQueryVariable('username') && (
                                <Tooltip
                                  title="Return home"
                                  placement="bottom"
                                  className={classes.homeIconContainer}
                                >
                                  <IconButton onClick={this.returnHome}>
                                    <HomeIcon
                                      style={
                                        this.state.darkMode
                                          ? { color: 'white' }
                                          : { color: 'black' }
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}

                            {/* Removed for debug */}

                            {/* <Tooltip title="Help me find..." placement="bottom">
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    showAppSearch: true,
                                  })
                                }
                              >
                                <img
                                  alt=""
                                  style={{ width: 25 }}
                                  src={darkMode ? binolight : binodark}
                                />
                              </IconButton>
                            </Tooltip> */}

                            {!isMobile && !isDesktop && (
                              <Tooltip
                                title="Reports"
                                placement="bottom"
                                className={classes.homeIconContainer}
                              >
                                <IconButton onClick={this.openPowerReport}>
                                  <BarChartIcon
                                    style={
                                      this.state.darkMode
                                        ? { color: 'white' }
                                        : { color: 'black' }
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Tooltip
                              title="Hide reporting cards"
                              placement="bottom"
                              className={classes.homeIconContainer}
                            >
                              <IconButton
                                onClick={() => this.hideLargeCards(allDepts)}
                              >
                                {this.state.hideLargeCards ? (
                                  <GridOffIcon
                                    style={
                                      this.state.darkMode
                                        ? { color: 'white' }
                                        : { color: 'black' }
                                    }
                                  />
                                ) : (
                                  <GridOnIcon
                                    style={
                                      this.state.darkMode
                                        ? { color: 'white' }
                                        : { color: 'black' }
                                    }
                                  />
                                )}
                              </IconButton>
                            </Tooltip>

                            {this.state.darkMode ? (
                              <Tooltip
                                title="Enable Light Mode"
                                placement="bottom"
                                className={classes.homeIconContainer}
                              >
                                <IconButton onClick={this.toggleDarkMode}>
                                  <LightModeIcon
                                    style={
                                      this.state.darkMode
                                        ? { color: 'white' }
                                        : { color: 'black' }
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Enable Dark Mode"
                                placement="bottom"
                                className={classes.homeIconContainer}
                              >
                                <IconButton onClick={this.toggleDarkMode}>
                                  <DarkModeIcon
                                    style={
                                      this.state.darkMode
                                        ? { color: 'white' }
                                        : { color: 'black' }
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            <div className={'step-mute'}>
                              {getQueryVariable('mute') ? null : (
                                <>
                                  {/* CHECK SHOW TOOL TIP IF BROWSER SOUNDS ARE NOT YET SUPPORTED */}
                                  {Browser ? (
                                    // Browser.name === "safari" ||
                                    // Browser.name === "ios" ||
                                    Browser.name === 'crios' ? (
                                      // || Browser.name === "ios-webview"
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          this.setState({
                                            showBrowserSoundError: false,
                                          })
                                        }
                                      >
                                        <Tooltip
                                          open={
                                            this.state.showBrowserSoundError
                                          }
                                          title="This browser currently doesn't support Flowstation sounds"
                                          placement="bottom"
                                        >
                                          <IconButton
                                            onClick={() =>
                                              this.setState({
                                                showBrowserSoundError: true,
                                              })
                                            }
                                          >
                                            <VolumeOffIcon
                                              style={
                                                this.state.darkMode
                                                  ? { color: 'white' }
                                                  : { color: 'black' }
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </ClickAwayListener>
                                    ) : (
                                      <Tooltip
                                        title="Sounds"
                                        placement="bottom"
                                      >
                                        <div
                                          // onMouseDown={this.toggleSound}
                                          style={{ cursor: 'pointer' }}
                                          // @ts-ignore
                                          ref={this.soundsRef}
                                          onClick={this.setSoundsAnchor}
                                        >
                                          <IconButton>
                                            {this.state.mute ? (
                                              <VolumeOffIcon
                                                style={
                                                  this.state.darkMode
                                                    ? { color: 'white' }
                                                    : { color: 'black' }
                                                }
                                              />
                                            ) : (
                                              <VolumeUpIcon
                                                style={
                                                  this.state.darkMode
                                                    ? { color: 'white' }
                                                    : { color: 'black' }
                                                }
                                              />
                                            )}
                                          </IconButton>
                                        </div>
                                      </Tooltip>
                                    )
                                  ) : null}
                                </>
                              )}
                            </div>

                            <Tooltip title="Active Alerts" placement="bottom">
                              <IconButton
                                // @ts-ignore
                                ref={this.alertRef}
                                onClick={this.setActiveAlertsAnchor}
                              >
                                <Badge
                                  classes={{ badge: classes.customBadge }}
                                  badgeContent={filteredActiveAlerts.length}
                                  color="primary"
                                >
                                  {localStorage.getItem(
                                    'disableNotificationPopover'
                                  ) === 'true' ? (
                                    <NotificationOffIcon
                                      style={
                                        this.state.darkMode
                                          ? { color: 'white' }
                                          : { color: 'black' }
                                      }
                                    />
                                  ) : (
                                    <NotificationIcon
                                      style={
                                        this.state.darkMode
                                          ? { color: 'white' }
                                          : { color: 'black' }
                                      }
                                    />
                                  )}

                                  {/* NotificationOffIcon */}
                                </Badge>
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Flowstation Tutorial"
                              placement="bottom"
                            >
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    run: true,
                                  })
                                }
                              >
                                <HelpIcon
                                  style={
                                    this.state.darkMode
                                      ? { color: 'white' }
                                      : { color: 'black' }
                                  }
                                />
                              </IconButton>
                            </Tooltip>

                            <div className="step-drawer">
                              <IconButton
                                color="inherit"
                                onClick={this.toggleDrawer(true)}
                              >
                                <MenuIcon
                                  style={
                                    this.state.darkMode
                                      ? { color: 'white' }
                                      : { color: 'black' }
                                  }
                                />
                              </IconButton>
                            </div>
                          </div>
                        </Toolbar>
                        {/* </AppBar> */}
                      </div>

                      <Menu
                        id="simple-menu"
                        // style={{ paddingRight: 5 }}
                        anchorEl={this.state.viewEl}
                        open={Boolean(this.state.viewEl)}
                        onClose={() => this.setState({ viewEl: null })}
                      >
                        <div>
                          {/* {publishedViews.length > 0 ? (
                            <div>
                              <div
                                style={{
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  fontSize: 24,
                                }}
                              >
                                Published Views
                              </div>
                              <div
                                style={{
                                  backgroundColor: "lightgray",
                                  height: 1,
                                  width: "90%",
                                  margin: "auto",
                                  marginTop: 5,
                                  marginBottom: 10,
                                }}
                              />
                            </div>
                          ) : null} */}

                          <Accordion style={{ margin: '20px 16px 0px 16px' }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Published Views</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                maxHeight: 150,
                                overflow: 'scroll',
                                overflowX: 'hidden',
                              }}
                            >
                              <div>
                                {publishedViews.map((view: any) => (
                                  <div
                                    key={view.viewId}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        paddingRight: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {this.state.renameView &&
                                      this.state.renameViewId ===
                                        view.viewId ? (
                                        <TextField
                                          onKeyPress={ev => {
                                            // console.log(`Pressed keyCode ${ev.key}`);
                                            if (ev.key === 'Enter') {
                                              // Do code here
                                              this.updateView(view.viewId);
                                              ev.preventDefault();
                                            }
                                          }}
                                          style={{ paddingLeft: 16 }}
                                          autoFocus
                                          value={this.state.renameViewValue}
                                          onChange={e => {
                                            this.setState({
                                              renameViewValue: e.target.value,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <div style={{ width: '100%' }}>
                                          <MenuItem
                                            style={{
                                              width: '100%',
                                              paddingRight: 32,
                                            }}
                                          >
                                            {this.props.loggedInUserData
                                              .isAdmin ? (
                                              <>
                                                {/* <UnpublishIcon
                                            style={{ paddingLeft: 5, cursor: "pointer", paddingRight: 10 }}
                                            onClick={() => this.unPublishView(view)}
                                          /> */}
                                              </>
                                            ) : null}
                                            <a
                                              href={
                                                getQueryVariable('deviceId')
                                                  ? '/?viewId=' +
                                                    view.viewId +
                                                    '&deviceId=' +
                                                    getQueryVariable('deviceId')
                                                  : '/?viewId=' + view.viewId
                                              }
                                              style={{
                                                textDecoration: 'none',
                                                color: 'black',
                                              }}
                                            >
                                              {view.viewName}
                                              <span
                                                style={{
                                                  color: 'gray',
                                                  marginLeft: '.4rem',
                                                }}
                                              >
                                                {' '}
                                                by {view.publisher}
                                              </span>
                                            </a>
                                          </MenuItem>
                                        </div>
                                      )}
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      {getQueryVariable('viewId') !==
                                      view.viewId ? (
                                        <>
                                          {this.props.loggedInUserData
                                            .isAdmin ? (
                                            <strong
                                              style={{
                                                color: 'red',
                                                float: 'right',
                                                cursor: 'pointer',
                                                fontSize: 17,
                                              }}
                                              // onClick={() => this.deleteSubbedAction(subbedAction)}
                                              onClick={() =>
                                                this.deleteView(view)
                                              }
                                            >
                                              X
                                            </strong>
                                          ) : null}
                                        </>
                                      ) : null}

                                      {getQueryVariable('viewId') ===
                                      view.viewId ? (
                                        <>
                                          {view.publisherId ? (
                                            <>
                                              {this.props.loggedInUserData
                                                .isAdmin ? (
                                                <EditIcon
                                                  style={{
                                                    cursor: 'pointer',
                                                    marginRight: 10,
                                                  }}
                                                  onClick={() => {
                                                    this.setState({
                                                      renameViewId: view.viewId,
                                                      renameView: true,
                                                      renameViewValue:
                                                        view.viewName,
                                                    });
                                                  }}
                                                />
                                              ) : null}
                                            </>
                                          ) : (
                                            <>
                                              <EditIcon
                                                style={{
                                                  cursor: 'pointer',
                                                  marginRight: 10,
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    renameViewId: view.viewId,
                                                    renameView: true,
                                                    renameViewValue:
                                                      view.viewName,
                                                  });
                                                }}
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion
                            style={{ margin: '20px 16px 0px 16px' }}
                            defaultExpanded={true}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Your Views</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                                {/* {userViews.length > 0 ? (
                                  <div>
                                    <div
                                      style={{
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        fontSize: 24,
                                        paddingTop: 10,
                                      }}
                                    >
                                      Your Views
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: "lightgray",
                                        height: 1,
                                        width: "90%",
                                        margin: "auto",
                                        marginTop: 5,
                                        marginBottom: 10,
                                      }}
                                    />
                                  </div>
                                ) : null} */}
                                {userViews.map((view: any) => (
                                  <div
                                    key={view.viewId}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        paddingRight: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {this.state.renameView &&
                                      this.state.renameViewId ===
                                        view.viewId ? (
                                        <TextField
                                          onKeyPress={ev => {
                                            // console.log(`Pressed keyCode ${ev.key}`);
                                            if (ev.key === 'Enter') {
                                              // Do code here
                                              this.updateView(view.viewId);
                                              ev.preventDefault();
                                            }
                                          }}
                                          style={{ paddingLeft: 16 }}
                                          autoFocus
                                          value={this.state.renameViewValue}
                                          onChange={e => {
                                            this.setState({
                                              renameViewValue: e.target.value,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <div style={{ width: '100%' }}>
                                          <MenuItem
                                            style={{
                                              width: '100%',
                                              paddingRight: 32,
                                            }}
                                          >
                                            {canDo(
                                              ['Publish Views'],
                                              this.props.loggedInUserData
                                            ) ? (
                                              <>
                                                <Tooltip
                                                  title="Publishing will allow anyone in your organization to view a read only copy of this view."
                                                  placement="bottom"
                                                >
                                                  <PublishIcon
                                                    style={{
                                                      paddingLeft: 5,
                                                      cursor: 'pointer',
                                                      paddingRight: 10,
                                                    }}
                                                    onClick={() =>
                                                      this.publishView(
                                                        view,
                                                        allDepts
                                                      )
                                                    }
                                                  />
                                                </Tooltip>
                                              </>
                                            ) : null}
                                            <a
                                              href={
                                                getQueryVariable('deviceId')
                                                  ? '/?viewId=' +
                                                    view.viewId +
                                                    '&deviceId=' +
                                                    getQueryVariable('deviceId')
                                                  : '/?viewId=' + view.viewId
                                              }
                                              style={{
                                                textDecoration: 'none',
                                                color: 'black',
                                                width: '100%',
                                              }}
                                            >
                                              {view.viewName}
                                            </a>
                                          </MenuItem>
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      style={{ position: 'absolute', right: 0 }}
                                    >
                                      {getQueryVariable('viewId') !==
                                      view.viewId ? (
                                        <strong
                                          style={{
                                            color: 'red',
                                            float: 'right',
                                            cursor: 'pointer',
                                            fontSize: 17,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                          // onClick={() => this.deleteSubbedAction(subbedAction)}
                                          onClick={() => this.deleteView(view)}
                                        >
                                          X
                                        </strong>
                                      ) : null}

                                      {getQueryVariable('viewId') ===
                                      view.viewId ? (
                                        <>
                                          {view.publisherId ? (
                                            <>
                                              {view.publisherId ===
                                              this.props.loggedInUserData.id ? (
                                                <EditIcon
                                                  style={{
                                                    cursor: 'pointer',
                                                    marginRight: 10,
                                                  }}
                                                  onClick={() => {
                                                    this.setState({
                                                      renameViewId: view.viewId,
                                                      renameView: true,
                                                      renameViewValue:
                                                        view.viewName,
                                                    });
                                                  }}
                                                />
                                              ) : null}
                                            </>
                                          ) : (
                                            <>
                                              <EditIcon
                                                style={{
                                                  cursor: 'pointer',
                                                  marginRight: 10,
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    renameViewId: view.viewId,
                                                    renameView: true,
                                                    renameViewValue:
                                                      view.viewName,
                                                  });
                                                }}
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </AccordionDetails>
                          </Accordion>

                          {groupId ? (
                            <Button
                              style={{ margin: '20px 16px 16px 16px' }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                this.goToCreateView(groupId ? groupId : '')
                              }
                            >
                              + Create New View
                            </Button>
                          ) : null}
                        </div>
                      </Menu>

                      <Dialog
                        open={this.state.saveAsNewView}
                        onClose={() =>
                          this.setState({
                            saveAsNewView: false,
                          })
                        }
                        BackdropProps={{
                          classes: {
                            root: classes.root,
                          },
                        }}
                        transitionDuration={{ enter: 0, exit: 0 }}
                      >
                        <div style={{ backgroundColor: 'white' }}>
                          <DialogContent>
                            <div>
                              New View Name
                              <br />
                              <TextField
                                id="standard-basic"
                                // label="Standard"
                                value={this.state.newViewName}
                                onChange={e =>
                                  this.setState({ newViewName: e.target.value })
                                }
                              />
                              <br />
                              <Button
                                disabled={this.state.newViewName.length === 0}
                                color="primary"
                                variant="contained"
                                style={{ marginTop: 10 }}
                                onClick={() =>
                                  this.saveAsNewView(currentViewInfo)
                                }
                              >
                                Create View
                              </Button>
                            </div>
                          </DialogContent>
                        </div>
                      </Dialog>

                      <Dialog
                        open={this.state.newViewDialog}
                        BackdropProps={{
                          classes: {
                            root: classes.root,
                          },
                        }}
                        transitionDuration={{ enter: 0, exit: 0 }}
                      >
                        <div style={{ backgroundColor: 'white' }}>
                          <DialogContent>
                            {this.state.newViewStep === 0 ? (
                              <div>
                                <div style={{ marginBottom: 10 }}>
                                  Which departments would you like to show?
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                  <Button
                                    style={{ marginRight: 10 }}
                                    onClick={() => window.location.replace('/')}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={
                                      this.state.preFilterDepartmentsToShow
                                        .length === 0
                                    }
                                    onClick={() =>
                                      this.setState({
                                        newViewStep: this.state.newViewStep + 1,
                                      })
                                    }
                                  >
                                    Next
                                  </Button>
                                </div>
                                {allDepts.map((dept, index) => (
                                  <div key={index}>
                                    {this.state.fsDepartmentsToShow.indexOf(
                                      dept.id
                                    ) > -1 ? (
                                      <div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            marginBottom: 10,
                                          }}
                                        >
                                          {this.state.preFilteringDeptSettings.map(
                                            preDept => {
                                              if (preDept.deptId === dept.id) {
                                                return (
                                                  <div
                                                    key={preDept.deptId}
                                                    style={{
                                                      marginTop: 'auto',
                                                      marginBottom: 'auto',
                                                      width: 20,
                                                    }}
                                                  >
                                                    {/* <Button onClick={() => this.handleDepartmentPreFiltering(dept)}> */}
                                                    <img
                                                      alt=""
                                                      onClick={() =>
                                                        this.handleDepartmentPreFiltering(
                                                          dept
                                                        )
                                                      }
                                                      style={{
                                                        cursor: 'pointer',
                                                        // maxWidth: 20,
                                                        // marginBottom: 2,
                                                        // marginLeft: 4,
                                                        height: 20,
                                                        filter: 'invert(1)',
                                                        verticalAlign: 'middle',
                                                      }}
                                                      src={filterIcon}
                                                    />
                                                    {/* </Button> */}
                                                  </div>
                                                );
                                              } else {
                                                return null;
                                              }
                                            }
                                          )}
                                          <Chip
                                            label={dept.deptName}
                                            onDelete={() =>
                                              this.togglePreFilterDepartment(
                                                dept.id,
                                                allDepts
                                              )
                                            }
                                          />
                                          <div style={{ marginLeft: 'auto' }}>
                                            <div>
                                              <IconButton
                                                aria-label="delete"
                                                onClick={() =>
                                                  this.moveDeptUp(
                                                    dept.id,
                                                    allDepts
                                                  )
                                                }
                                                size="small"
                                                style={{ padding: 0 }}
                                              >
                                                <ArrowUp fontSize="inherit" />
                                              </IconButton>
                                            </div>
                                            <div>
                                              <IconButton
                                                aria-label="delete"
                                                onClick={() =>
                                                  this.moveDeptDown(
                                                    dept.id,
                                                    allDepts
                                                  )
                                                }
                                                size="small"
                                                style={{ padding: 0 }}
                                              >
                                                <ArrowDown fontSize="inherit" />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}

                                <Autocomplete
                                  id="site-search"
                                  options={activeSites}
                                  getOptionLabel={(site: ISite) =>
                                    site.siteName
                                  }
                                  // @ts-ignore
                                  onChange={(
                                    event: any,
                                    site: ISite | null
                                  ) => {
                                    if (site) {
                                      this.setState({
                                        selectedSiteFilter: site,
                                      });
                                    }
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      autoFocus
                                      label="Search Site"
                                    />
                                  )}
                                />

                                {this.state.selectedSiteFilter ? (
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      this.setState({
                                        showNewViewDepartmentsSearch: false,
                                      })
                                    }
                                  >
                                    <div
                                      onClick={() => {
                                        if (
                                          !this.state
                                            .showNewViewDepartmentsSearch
                                        ) {
                                          this.setState({
                                            showNewViewDepartmentsSearch: true,
                                          });
                                        }
                                      }}
                                    >
                                      <Autocomplete
                                        id="department-search"
                                        open={
                                          this.state
                                            .showNewViewDepartmentsSearch
                                        }
                                        style={{ marginBottom: 10 }}
                                        key={this.state.departmentSearchKey}
                                        // autoHighlight
                                        // options={preFilterDeptOptions}
                                        options={
                                          // filter out this.state.fsDepartmentsToShow from preFilterDeptOptions
                                          preFilterDeptOptions.filter(
                                            (dept: IDepartment) =>
                                              this.state.fsDepartmentsToShow.indexOf(
                                                dept.id
                                              ) === -1
                                          )
                                        }
                                        getOptionLabel={(
                                          department: IDepartment
                                        ) =>
                                          department.siteName +
                                          ' ' +
                                          department.deptName
                                        }
                                        onChange={(
                                          event: any,
                                          department: IDepartment | string
                                        ) => {
                                          if (
                                            typeof department !== 'string' &&
                                            department?.id
                                          ) {
                                            this.togglePreFilterDepartment(
                                              department.id,
                                              allDepts
                                            );
                                            this.setState({
                                              departmentSearchKey:
                                                this.state.departmentSearchKey +
                                                1,
                                              showNewViewDepartmentsSearch:
                                                false,
                                            });
                                          }
                                        }}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label="Search Department"
                                          />
                                        )}
                                      />
                                    </div>
                                  </ClickAwayListener>
                                ) : null}
                              </div>
                            ) : null}
                            {this.state.newViewStep === 1 ? (
                              <div>
                                View Name
                                <br />
                                <TextField
                                  autoFocus
                                  style={{ width: '100%' }}
                                  id="standard-basic"
                                  // label="Standard"
                                  placeholder={
                                    'View created at ' +
                                    moment()
                                      .tz(timeZone)
                                      .format('MM/DD/YY, h:mm a')
                                  }
                                  value={this.state.newViewName}
                                  onChange={e =>
                                    this.setState({
                                      newViewName: e.target.value,
                                    })
                                  }
                                />
                                <br />
                                <Button
                                  style={{ marginTop: 10, marginRight: 10 }}
                                  onClick={() =>
                                    this.setState({
                                      newViewStep: this.state.newViewStep - 1,
                                    })
                                  }
                                >
                                  Back
                                </Button>
                                <Button
                                  style={{ marginTop: 10, marginRight: 10 }}
                                  onClick={() => window.location.replace('/')}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  // disabled={this.state.newViewName.length === 0}
                                  color="primary"
                                  variant="contained"
                                  style={{ marginTop: 10 }}
                                  onClick={() => this.createView()}
                                >
                                  Create View
                                </Button>
                              </div>
                            ) : null}
                          </DialogContent>
                        </div>
                      </Dialog>

                      <Draggable key={this.state.draggableKey}>
                        <Dialog
                          open={this.state.openDeptDialog}
                          // style={{ width: 600 }}
                          BackdropProps={{
                            classes: {
                              root: classes.root,
                            },
                          }}
                          transitionDuration={{ enter: 0, exit: 0 }}
                          onClose={() =>
                            this.setState({
                              openDeptDialog: false,
                              draggableKey: 0,
                              gotDeptProviders: false,
                              autoCompleteProvidersToView: [],
                            })
                          }
                        >
                          {this.state.deptSettingsToModify ? (
                            <div
                              style={
                                this.state.darkMode
                                  ? {
                                      // width: 500,
                                      backgroundColor: '#333333',
                                      border: '2px solid #666666',
                                      cursor: 'move',
                                      color: 'white',
                                    }
                                  : { backgroundColor: 'white', cursor: 'move' }
                              }
                            >
                              {/* <DialogTitle style={{ paddingBottom: 0, textAlign: "center" }}>Department Filtering</DialogTitle> */}
                              {getQueryVariable('newView') ? (
                                <div style={{ height: 35 }} />
                              ) : (
                                <div
                                  style={{
                                    textAlign: 'right',
                                    color: 'red',
                                    fontSize: 24,
                                    marginRight: 10,
                                    marginTop: 5,
                                  }}
                                >
                                  {/* <strong onClick={() => this.setState({ openDeptDialog: false, draggableKey: 0 })}>X</strong> */}
                                  <CloseButton
                                    onClick={() =>
                                      this.setState({
                                        openDeptDialog: false,
                                        draggableKey: 0,
                                        gotDeptProviders: false,
                                        autoCompleteProvidersToView: [],
                                      })
                                    }
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              )}

                              <h3
                                style={{
                                  textAlign: 'center',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  marginTop: 5,
                                }}
                              >
                                {this.state.deptSettingsToModify.deptName}{' '}
                                Filtering
                              </h3>
                              <DialogContent>
                                <div
                                  style={
                                    {
                                      // paddingLeft: 10,
                                      // width: 400,
                                      // maxWidth: "50%"
                                    }
                                  }
                                >
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <GreenRadio
                                          // @ts-ignore
                                          checked={deptFilterBy === 'room'}
                                          onChange={() =>
                                            this.handleRoomSwitch(
                                              allDepts,
                                              this.state.deptSettingsToModify.id
                                            )
                                          }
                                          // value="b"
                                          name="radio-button-demo"
                                          inputProps={{ 'aria-label': 'B' }}
                                        />
                                      }
                                      label="Filter by Room"
                                      labelPlacement="end"
                                    />
                                  </div>

                                  <div>
                                    <FormControlLabel
                                      control={
                                        <GreenRadio
                                          // @ts-ignore
                                          checked={deptFilterBy === 'provider'}
                                          onChange={() =>
                                            this.handleProviderSwitch(
                                              allDepts,
                                              this.state.deptSettingsToModify.id
                                            )
                                          }
                                          // value="b"
                                          name="radio-button-demo"
                                          inputProps={{ 'aria-label': 'B' }}
                                        />
                                      }
                                      label="Filter by User"
                                      labelPlacement="end"
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <GreenRadio
                                          // @ts-ignore
                                          checked={deptFilterBy === 'action'}
                                          onChange={() =>
                                            this.handleActionSwitch(
                                              allDepts,
                                              this.state.deptSettingsToModify.id
                                            )
                                          }
                                          // value="b"
                                          name="radio-button-demo"
                                          inputProps={{ 'aria-label': 'B' }}
                                        />
                                      }
                                      label="Filter by Action"
                                      labelPlacement="end"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      height: 1,
                                      backgroundColor: '#949494',
                                    }}
                                  />
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          classes={{
                                            root: classes.sortCheckbox,
                                            checked: classes.checked,
                                          }}
                                          // @ts-ignore
                                          checked={hideNonPatientRooms === true}
                                          onChange={() =>
                                            this.handleHideNonPatientRoomsSwitch(
                                              allDepts,
                                              this.state.deptSettingsToModify
                                                .id,
                                              hideNonPatientRooms
                                            )
                                          }
                                        />
                                      }
                                      label="Hide Non-Patient Rooms"
                                      labelPlacement="end"
                                    />
                                  </div>
                                </div>

                                {deptFilterBy === 'provider' ? (
                                  <div>
                                    <div>
                                      {/* DISPLAY LIST OF PROVIDERS */}
                                      <h3>Providers</h3>

                                      <div
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {this.state.allProviders.map(
                                          (
                                            provider: IProvider,
                                            key: number
                                          ) => (
                                            <div key={key}>
                                              {this.state.providersToShow.includes(
                                                provider.id
                                              ) ? (
                                                <div>
                                                  {/* {console.log("found", provider)} */}
                                                  <Chip
                                                    label={provider.fullName}
                                                    style={{
                                                      margin: '5px 5px 5px 0px',
                                                    }}
                                                    onDelete={() =>
                                                      this.toggleProvider(
                                                        provider.id,
                                                        this.state
                                                          .deptSettingsToModify
                                                          .id,
                                                        allDepts
                                                      )
                                                    }
                                                    avatar={
                                                      <Avatar
                                                        src={
                                                          provider.userImageUrl
                                                        }
                                                      />
                                                    }
                                                  />
                                                </div>
                                              ) : null}
                                            </div>
                                          )
                                        )}
                                      </div>
                                      {this.state.gotDeptProviders ? (
                                        <Autocomplete
                                          id="provider-search"
                                          // style={{ backgroundColor: 'red' }}
                                          key={this.state.providerSearchKey}
                                          autoHighlight
                                          // options={this.state.allProviders}
                                          options={this.state.allProviders.filter(
                                            (provider: IProvider) => {
                                              return !this.state.providersToShow.includes(
                                                provider.id
                                              );
                                            }
                                          )}
                                          getOptionLabel={(
                                            provider: IProvider
                                          ) => provider.fullName}
                                          onChange={(
                                            event: any,
                                            provider: IProvider | string
                                          ) => {
                                            if (
                                              provider &&
                                              typeof provider !== 'string'
                                            ) {
                                              console.log('Handle', provider);
                                              this.toggleProvider(
                                                provider,
                                                this.state.deptSettingsToModify
                                                  .id,
                                                allDepts
                                              );
                                              this.setState({
                                                providerSearchKey:
                                                  this.state.providerSearchKey +
                                                  1,
                                              });
                                            }
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              autoFocus
                                              label="Search User"
                                            />
                                          )}
                                        />
                                      ) : (
                                        'Loading Providers...'
                                      )}

                                      {/* END */}
                                    </div>
                                  </div>
                                ) : null}

                                {deptFilterBy === 'room' ? (
                                  <div>
                                    <h3
                                      style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                      }}
                                    >
                                      Rooms
                                    </h3>

                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                      }}
                                    >
                                      {allRooms.map((room, roomIndex) => (
                                        <div key={roomIndex}>
                                          {room.mongoDeptId ===
                                          this.state.deptSettingsToModify.id ? (
                                            <div>
                                              {preFilteringDeptSettings ? (
                                                preFilteringDeptSettings.hiddenRooms.indexOf(
                                                  room.id
                                                ) > -1 ? (
                                                  false
                                                ) : (
                                                  <Chip
                                                    style={{
                                                      margin: '5px 5px 5px 0px',
                                                    }}
                                                    label={room.roomName}
                                                    onDelete={() =>
                                                      this.toggleRoom(
                                                        room.id,
                                                        this.state
                                                          .deptSettingsToModify
                                                          .id,
                                                        allDepts,
                                                        true
                                                      )
                                                    }
                                                  />
                                                )
                                              ) : // View Check
                                              this.state.viewName ? (
                                                this.state.hiddenRooms.indexOf(
                                                  room.id
                                                ) > -1 ? (
                                                  false
                                                ) : (
                                                  <Chip
                                                    style={{
                                                      margin: '5px 5px 5px 0px',
                                                    }}
                                                    label={room.roomName}
                                                    onDelete={() =>
                                                      this.toggleRoom(
                                                        room.id,
                                                        this.state
                                                          .deptSettingsToModify
                                                          .id,
                                                        allDepts,
                                                        true
                                                      )
                                                    }
                                                  />
                                                )
                                              ) : // Standard fs check
                                              JSON.parse(
                                                  localStorage.getItem(
                                                    this.state
                                                      .deptSettingsToModify.id
                                                  ) || '{}'
                                                ).hiddenRooms.indexOf(room.id) >
                                                -1 ? (
                                                false
                                              ) : true ? (
                                                <Chip
                                                  style={{
                                                    margin: '5px 5px 5px 0px',
                                                  }}
                                                  label={room.roomName}
                                                  onDelete={() =>
                                                    this.toggleRoom(
                                                      room.id,
                                                      this.state
                                                        .deptSettingsToModify
                                                        .id,
                                                      allDepts,
                                                      true
                                                    )
                                                  }
                                                />
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      ))}
                                    </div>

                                    <Autocomplete
                                      id="room-search"
                                      key={this.state.roomSearchKey}
                                      autoHighlight
                                      // @ts-ignore
                                      // options={allRooms.filter((room) => {
                                      //   return room.mongoDeptId === this.state.deptSettingsToModify.id;
                                      // })}
                                      options={allRooms.filter(
                                        (room: IRoom) => {
                                          if (preFilteringDeptSettings) {
                                            return (
                                              preFilteringDeptSettings.hiddenRooms.indexOf(
                                                room.id
                                              ) > -1
                                            );
                                          } else {
                                            return (
                                              room.mongoDeptId ===
                                                this.state.deptSettingsToModify
                                                  .id &&
                                              this.state.hiddenRooms.indexOf(
                                                room.id
                                              ) !== -1
                                            );
                                          }
                                        }
                                      )}
                                      getOptionLabel={(room: IRoom) =>
                                        room.roomName
                                      }
                                      onChange={(
                                        event: any,
                                        room: IRoom | string
                                      ) => {
                                        // console.log("room?", room);
                                        if (room && typeof room !== 'string') {
                                          console.log('GOT ROOM', room);
                                          // this.toggleProvider(provider.id);
                                          // this.selectProvider(provider.id);
                                          this.toggleRoom(
                                            room.id,
                                            this.state.deptSettingsToModify.id,
                                            allDepts,
                                            false
                                          );
                                          this.setState({
                                            roomSearchKey:
                                              this.state.roomSearchKey + 1,
                                          });
                                        }
                                      }}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          autoFocus
                                          label="Search Room"
                                        />
                                      )}
                                    />

                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      style={{ marginTop: 10 }}
                                      onClick={() =>
                                        this.viewAllRooms(
                                          allRooms,
                                          this.state.deptSettingsToModify.id,
                                          allDepts
                                        )
                                      }
                                    >
                                      Show All Rooms
                                    </Button>

                                    <Button
                                      color="primary"
                                      style={{ marginTop: 10 }}
                                      onClick={() =>
                                        this.hideAllRooms(
                                          allRooms,
                                          this.state.deptSettingsToModify.id,
                                          allDepts
                                        )
                                      }
                                    >
                                      Hide All Rooms
                                    </Button>
                                  </div>
                                ) : null}

                                {deptFilterBy === 'action' ? (
                                  <div>
                                    <div>
                                      <h3
                                        style={{
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        Actions
                                      </h3>
                                      <div>
                                        {/* {console.log("views all dept", this.state.viewDeptSettings)} */}
                                        {/* {console.log("selected dept", this.state.deptSettingsToModify)} */}

                                        {/* editing existing view */}
                                        {this.state.viewName ? (
                                          <div>
                                            {this.state.viewDeptSettings.map(
                                              (
                                                deptSetting: IDepartmentSetting,
                                                index: number
                                              ) => (
                                                <div key={index}>
                                                  {deptSetting.deptId ===
                                                  this.state
                                                    .deptSettingsToModify.id ? (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        maxWidth: 400,
                                                        flexWrap: 'wrap',
                                                        justifyContent:
                                                          'center',
                                                      }}
                                                    >
                                                      {this.state.deptSettingsToModify.actionsList.map(
                                                        (
                                                          action: IAction,
                                                          actionIndex: number
                                                        ) => (
                                                          <div
                                                            key={actionIndex}
                                                          >
                                                            <img
                                                              src={
                                                                action.actionImageUrl
                                                              }
                                                              alt=""
                                                              style={{
                                                                width: 50,
                                                                margin: 5,
                                                                cursor:
                                                                  'pointer',
                                                              }}
                                                              onClick={this.toggleAction(
                                                                action.id,
                                                                this.state
                                                                  .deptSettingsToModify
                                                                  .id,
                                                                allDepts
                                                              )}
                                                              className={
                                                                this.state.viewDeptSettings[
                                                                  index
                                                                ].actionsToShow.indexOf(
                                                                  action.id
                                                                ) > -1
                                                                  ? null
                                                                  : classes.fadedAction
                                                              }
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <div />
                                        )}

                                        {!this.state.viewName ? (
                                          <div
                                            style={{
                                              display: 'flex',
                                              maxWidth: 400,
                                              flexWrap: 'wrap',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            {this.state.deptSettingsToModify.actionsList.map(
                                              (action: IAction) => (
                                                <div key={action.id}>
                                                  <img
                                                    style={{
                                                      width: 50,
                                                      margin: 5,
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={this.toggleAction(
                                                      action.id,
                                                      this.state
                                                        .deptSettingsToModify
                                                        .id,
                                                      allDepts
                                                    )}
                                                    className={
                                                      preFilteringDeptSettings
                                                        ? preFilteringDeptSettings.actionsToShow.indexOf(
                                                            action.id
                                                          ) > -1
                                                          ? null
                                                          : classes.fadedAction
                                                        : JSON.parse(
                                                            localStorage.getItem(
                                                              this.state
                                                                .deptSettingsToModify
                                                                .id
                                                            ) || '{}'
                                                          ).actionsToShow.indexOf(
                                                            action.id
                                                          ) > -1
                                                        ? null
                                                        : classes.fadedAction
                                                    }
                                                    src={action.actionImageUrl}
                                                    alt=""
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div
                                  style={{ textAlign: 'right', marginTop: 25 }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      this.setState({
                                        openDeptDialog: false,
                                        draggableKey: 0,
                                        gotDeptProviders: false,
                                        autoCompleteProvidersToView: [],
                                      })
                                    }
                                  >
                                    Ok
                                  </Button>
                                </div>
                              </DialogContent>
                            </div>
                          ) : null}
                        </Dialog>
                      </Draggable>

                      {!allowSignalR ? (
                        <div className={classes.socketDisconnected}>
                          <Error style={{ paddingRight: 10 }} /> Connection may
                          be delayed
                        </div>
                      ) : null}

                      {allowSignalR ? (
                        <>
                          {connection.state !== 'Connected' ? (
                            <div className={classes.socketDisconnected}>
                              <Error style={{ paddingRight: 10 }} /> Connection
                              may be delayed
                            </div>
                          ) : null}
                        </>
                      ) : null}

                      {this.state.showReadOnlyAlert ? (
                        <div className={classes.socketDisconnected}>
                          <Error style={{ paddingRight: 10 }} /> You do not have
                          permission to make changes
                        </div>
                      ) : null}

                      {/* @ts-ignore*/}
                      {/* {console.log("check ref", this.alertRef.current)} */}

                      <Popover
                        open={this.state.showSounds}
                        onClose={() =>
                          this.setState({
                            showSounds: false,
                          })
                        }
                        // @ts-ignore
                        anchorEl={this.soundsRef.current}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div
                          style={
                            this.state.darkMode
                              ? {
                                  backgroundColor: '#333333',
                                  color: 'white',
                                  maxWidth: 500,
                                }
                              : {
                                  backgroundColor: 'white',
                                  maxWidth: 500,
                                  width: 500,
                                }
                          }
                        >
                          <Container
                            style={{ paddingBottom: 25, paddingTop: 20 }}
                          >
                            <div style={{ marginBottom: 10 }}>
                              <Tooltip
                                title="Toggle mute that affects the entire Flowstation"
                                placement="bottom"
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.toggleSound}
                                >
                                  {this.state.mute
                                    ? 'Unmute Flowstation'
                                    : 'Mute Flowstation'}
                                </Button>
                              </Tooltip>
                            </div>

                            <Typography
                              variant="h5"
                              style={{
                                marginTop: 10,
                                color: 'gray',
                                marginBottom: 10,
                              }}
                            >
                              Muted Actions
                              <Button
                                onClick={this.removeAllMutedActions}
                                variant="outlined"
                                style={{ marginLeft: 10 }}
                              >
                                Remove all
                              </Button>
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {mutedActions.length === 0 ? (
                                <div style={{ color: 'gray' }}>None</div>
                              ) : null}
                              {this.props.distinctOrgActions.map(
                                (action: IAction) => (
                                  <div key={action.id}>
                                    {mutedActions.includes(action.id) ? (
                                      <img
                                        onClick={() =>
                                          this.toggleActionSound(action)
                                        }
                                        alt=""
                                        src={action.actionImageUrl}
                                        style={{
                                          width: 50,
                                          height: 50,
                                          cursor: 'pointer',
                                          marginRight: 5,
                                        }}
                                      />
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                )
                              )}
                            </div>

                            <Typography
                              variant="h5"
                              style={{
                                marginTop: 20,
                                color: 'gray',
                                marginBottom: 10,
                              }}
                            >
                              Unmuted Actions
                              <Button
                                onClick={this.muteAllActions}
                                variant="outlined"
                                style={{ marginLeft: 10 }}
                              >
                                Add all
                              </Button>
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {this.props.distinctOrgActions.map(
                                (action: IAction) => (
                                  <div key={action.id}>
                                    {!mutedActions.includes(action.id) ? (
                                      <img
                                        onClick={() =>
                                          this.toggleActionSound(action)
                                        }
                                        alt=""
                                        src={action.actionImageUrl}
                                        style={{
                                          width: 50,
                                          height: 50,
                                          cursor: 'pointer',
                                          marginRight: 5,
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                )
                              )}
                            </div>
                          </Container>
                        </div>
                      </Popover>

                      <Modal
                        open={this.state.showUserNotifications}
                        onClose={() =>
                          this.setState({ showUserNotifications: false })
                        }
                      >
                        <div className={classes.newAlertModal}>
                          <Typography gutterBottom variant="h4" component="h2">
                            Notifications
                          </Typography>
                          <Notifications
                            loggedInUserData={this.props.loggedInUserData}
                            modifyingOtherUsers={false}
                          />
                        </div>
                      </Modal>

                      <Popover
                        open={this.state.showActiveAlerts}
                        // @ts-ignore
                        anchorEl={this.alertRef.current}
                        onClose={() =>
                          this.setState({ showActiveAlerts: false })
                        }
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <div
                          style={
                            this.state.darkMode
                              ? { backgroundColor: '#333333', color: 'white' }
                              : { backgroundColor: 'white' }
                          }
                        >
                          <Container
                            style={{ paddingBottom: 25, paddingTop: 20 }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                marginBottom: 10,
                              }}
                            >
                              {/* {console.log("CHECK", allRooms)}
                              {console.log("OTHER", this.props.rooms)} */}
                              <Autocomplete
                                style={{ width: '50%' }}
                                id="room-search"
                                // key={this.state.departmentSearchKey}
                                autoHighlight
                                // options={this.props.rooms}
                                options={this.props.rooms.filter(room => {
                                  return room.isActive === true;
                                })}
                                // filterOptions={(givenOptions) => {
                                //   let options: IDepartment[] = [];
                                //   givenOptions.forEach((dept: IDepartment) => {
                                //     // if (dept.isActive) {
                                //     //   options.push(dept);
                                //     // }
                                //     if (!_.find(this.state.fsDepartmentsToShow, (deptId: string) => deptId === dept.id)) {
                                //       options.push(dept);
                                //     }
                                //   });
                                //   return options;
                                // }}
                                getOptionLabel={(room: any) => {
                                  return (
                                    room.siteDeptName + ' ' + room.roomName
                                  );
                                }}
                                value={
                                  this.state.selectedRoom
                                    ? this.state.selectedRoom
                                    : null
                                }
                                onChange={(event, room) => {
                                  this.selectRoom(room);
                                }}
                                // onChange={(event: any, department: IDepartment | null) => {
                                //   if (department) {
                                //     this.toggleDepartment(department.id, true);
                                //     this.setState({
                                //       departmentSearchKey: this.state.departmentSearchKey + 1,
                                //     });
                                //   }
                                // }}
                                renderInput={params => (
                                  <TextField {...params} label="Search Room" />
                                )}
                              />
                              <Button
                                variant="outlined"
                                // onClick={() => this.setState({ showActionsPopover: true })}
                                onClick={this.openActionsPopover}
                                disabled={
                                  this.state.selectedRoom ? false : true
                                }
                              >
                                Activate Action in Room
                              </Button>
                            </div>

                            <Dialog
                              fullWidth={true}
                              maxWidth={'xs'}
                              // maxWidth={"245px"}
                              hideBackdrop={true}
                              open={this.state.showActionsPopover}
                              style={{ textAlign: 'center' }}
                              BackdropProps={{
                                classes: {
                                  root: classes.root,
                                },
                              }}
                              PaperProps={{
                                classes: {
                                  root: classes.paper,
                                },
                              }}
                              transitionDuration={{ enter: 0, exit: 0 }}
                              onClose={() =>
                                this.setState({
                                  showActionsPopover: false,
                                })
                              }
                            >
                              <div>
                                <div
                                  style={
                                    this.state.darkMode
                                      ? {
                                          backgroundColor: '#333333',
                                          color: 'white',
                                        }
                                      : { backgroundColor: 'white' }
                                  }
                                >
                                  {/* <div
                                    onClick={() =>
                                      this.setState({
                                        showActionsPopover: false,
                                      })
                                    }
                                    style={{ color: "red", textAlign: "right", fontSize: 32, cursor: "pointer", padding: "10px 10px 0px 0px" }}
                                  >
                                    X
                                  </div> */}
                                  <Typography
                                    variant="h5"
                                    style={{
                                      color: 'gray',
                                      paddingBottom: 10,
                                      paddingTop: 20,
                                    }}
                                  >
                                    {this.state.selectedRoom.siteDeptName}{' '}
                                    {this.state.selectedRoom.roomName}
                                  </Typography>
                                  <DialogContent>
                                    {/* {console.log("Available actions", this.state.availableActionsToSelect)} */}
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-start',
                                      }}
                                    >
                                      {this.state.availableActionsToSelect.map(
                                        (action: any) => (
                                          <div key={action.id}>
                                            {action.id !==
                                            '6018823c060fd59ddb764e0f' ? (
                                              <div>
                                                <img
                                                  src={action.actionImageUrl}
                                                  className={
                                                    action.isDisabled
                                                      ? classes.popOverActionsGray
                                                      : classes.popOverActions
                                                  }
                                                  alt=""
                                                  onClick={() =>
                                                    this.addAction(action)
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </DialogContent>
                                </div>
                              </div>
                            </Dialog>

                            {this.state.twoStepAction ? (
                              <Modal
                                open={this.state.showTwoStep}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                              >
                                <div className={classes.twoStep}>
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      this.setState({ showTwoStep: false })
                                    }
                                  >
                                    <div className={classes.twoStepContent}>
                                      <div style={{ textAlign: 'center' }}>
                                        <img
                                          style={{ width: 75 }}
                                          alt=""
                                          src={
                                            this.state.twoStepAction
                                              .actionImageUrl
                                          }
                                        />
                                      </div>
                                      <div>
                                        Are you sure you want to add{' '}
                                        <strong>
                                          {this.state.twoStepAction.actionName}
                                        </strong>
                                        ?
                                      </div>
                                      <br />
                                      <TextField
                                        autoFocus
                                        style={{ marginBottom: 25 }}
                                        label='Type "yes" to confirm'
                                        value={
                                          this.state.doubleConfirmationText
                                        }
                                        onChange={e =>
                                          this.handleDoubleConfirmationText(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div>
                                        <Button
                                          color="default"
                                          variant="contained"
                                          onClick={() =>
                                            this.setState({
                                              showTwoStep: false,
                                            })
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                    </div>
                                  </ClickAwayListener>
                                </div>
                              </Modal>
                            ) : null}

                            <div style={{ height: 20 }} />

                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                marginBottom: 10,
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{
                                  marginTop: 10,
                                  color: 'gray',
                                  marginRight: 20,
                                }}
                              >
                                Active Notifications
                              </Typography>
                              <br />
                              <Button
                                variant="contained"
                                color="primary"
                                // onClick={this.sendToManageNotifications}
                                style={{ marginRight: 10 }}
                                onClick={() => this.handleManageNotifications()}
                              >
                                Manage Notifications
                              </Button>

                              {localStorage.getItem(
                                'disableNotificationPopover'
                              ) === 'true' ? (
                                // <Tooltip title="Enable Notifications Popup" placement="bottom">
                                //   <VisibilityIcon style={darkMode ? { color: "white" } : { color: "black" }} />
                                // </Tooltip>
                                <Button
                                  variant="contained"
                                  onClick={
                                    this.toggleDisableNotificationPopover
                                  }
                                >
                                  Unmute
                                </Button>
                              ) : (
                                // <Tooltip title="Disable Notifications Popup" placement="bottom">
                                //   <VisibilityOffIcon style={darkMode ? { color: "white" } : { color: "black" }} />
                                // </Tooltip>
                                <Button
                                  variant="contained"
                                  onClick={
                                    this.toggleDisableNotificationPopover
                                  }
                                >
                                  Mute
                                </Button>
                              )}
                            </div>
                            {/* <Button variant="outlined">Disable Notification Popup</Button> */}
                            {filteredActiveAlerts.length === 0 ? (
                              <div
                                style={{
                                  color: 'gray',
                                  fontSize: 12,
                                  textAlign: 'center',
                                  marginTop: 20,
                                }}
                              >
                                No alerts to show...
                              </div>
                            ) : null}
                            {filteredActiveAlerts.map(
                              (activeAlert: IAlert, i: number) => (
                                <div key={i} style={{ paddingTop: 10 }}>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      border: '1px solid lightgray',
                                      borderRadius: '10px',
                                      marginBottom: 5,
                                      padding: '5px 10px 5px 10px',
                                    }}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={2}>
                                      <img
                                        style={{ width: 50, height: 50 }}
                                        alt=""
                                        src={activeAlert.actionImageUrl}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={10}
                                      style={{ width: 500 }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          flexWrap: 'wrap',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <h3 style={{ margin: '0px' }}>
                                          {activeAlert.actionName +
                                            ' ' +
                                            activeAlert.roomSiteDeptName}
                                        </h3>
                                        {activeAlert.roomSiteDeptName ===
                                        'Waiting Room' ? null : (
                                          <h3 style={{ margin: '0px' }}>
                                            {moment()
                                              .add(
                                                'milliseconds',
                                                serverTimeOffset
                                              )
                                              .tz(timeZone)
                                              .local()
                                              .diff(
                                                moment
                                                  .utc(
                                                    activeAlert.actionStartTime
                                                  )
                                                  .local(),
                                                'minutes'
                                              )}{' '}
                                            minutes
                                          </h3>
                                        )}
                                        {/* <h3 style={{ color: "red", fontWeight: "bold", margin: 0 }}>X</h3> */}
                                      </div>

                                      <div style={{ marginLeft: 20 }}>
                                        {activeAlert.patientsInRoom.map(
                                          (
                                            patient: IPatient,
                                            patientIndex: number
                                          ) => (
                                            <ul
                                              key={patientIndex}
                                              style={{
                                                marginTop: 0,
                                                paddingLeft: 0,
                                                marginBottom: 0,
                                              }}
                                            >
                                              <li>
                                                {patient.fsPatientInfoString}
                                              </li>
                                            </ul>
                                          )
                                        )}
                                      </div>
                                      {activeAlert.actionId !==
                                      '6092bf9a394a8d85bfc7e50c' ? (
                                        <Button
                                          onClick={() =>
                                            this.clearActiveAlert(activeAlert)
                                          }
                                          style={{
                                            backgroundColor: '#50b848',
                                            color: '#FFFFFF',
                                            textDecoration: 'none',
                                            marginTop: 10,
                                            // marginBottom: 10,
                                          }}
                                          variant="contained"
                                        >
                                          Remove Action from Room
                                        </Button>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </div>
                              )
                            )}
                          </Container>
                        </div>
                      </Popover>

                      <Drawer
                        BackdropProps={{ invisible: true }}
                        anchor="right"
                        open={this.state.right}
                        onClose={this.toggleDrawer(false)}
                      >
                        <div
                          style={
                            this.state.darkMode
                              ? {
                                  backgroundColor: '#333333',
                                  height: '100%',
                                  color: 'white',
                                }
                              : {}
                          }
                        >
                          <div
                            className={classes.closeButton}
                            onClick={this.toggleDrawer(false)}
                          >
                            <CloseButton />
                          </div>

                          {/* {console.log("ALL DEPTS", allDepts)} */}

                          <div>
                            <Accordion
                              className={
                                classes.expansionPanel + ' step-departments'
                              }
                              style={
                                this.state.darkMode
                                  ? {
                                      backgroundColor: '#333333',
                                      color: 'white',
                                    }
                                  : {}
                              }
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon
                                    style={
                                      this.state.darkMode
                                        ? { color: 'white' }
                                        : {}
                                    }
                                  />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  Departments
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                className={classes.expansionPanelDetails}
                              >
                                {allDepts.map((dept, index) => (
                                  <div key={index}>
                                    {this.state.fsDepartmentsToShow.indexOf(
                                      dept.id
                                    ) > -1 ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          marginBottom: 10,
                                        }}
                                      >
                                        <Chip
                                          label={dept.deptName}
                                          onDelete={() =>
                                            this.toggleDepartment(
                                              dept.id,
                                              false
                                            )
                                          }
                                        />
                                        <div style={{ marginLeft: 'auto' }}>
                                          <div>
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() =>
                                                this.moveDeptUp(
                                                  dept.id,
                                                  allDepts
                                                )
                                              }
                                              size="small"
                                              style={{ padding: 0 }}
                                            >
                                              <ArrowUp
                                                style={
                                                  this.state.darkMode
                                                    ? { color: 'white' }
                                                    : {}
                                                }
                                                fontSize="inherit"
                                              />
                                            </IconButton>
                                          </div>
                                          <div>
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() =>
                                                this.moveDeptDown(
                                                  dept.id,
                                                  allDepts
                                                )
                                              }
                                              size="small"
                                              style={{ padding: 0 }}
                                            >
                                              <ArrowDown
                                                style={
                                                  this.state.darkMode
                                                    ? { color: 'white' }
                                                    : {}
                                                }
                                                fontSize="inherit"
                                              />
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}

                                <Autocomplete
                                  id="site-search-drawer"
                                  options={activeSites}
                                  getOptionLabel={(site: ISite) =>
                                    site.siteName
                                  }
                                  onChange={(
                                    event: any,
                                    site: ISite | string
                                  ) => {
                                    if (site && typeof site !== 'string') {
                                      this.setState({
                                        selectedSiteFilter: site,
                                      });
                                    }
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      autoFocus
                                      label="Search Site"
                                    />
                                  )}
                                />

                                {this.state.selectedSiteFilter ? (
                                  <Autocomplete
                                    id="department-search"
                                    key={this.state.departmentSearchKey}
                                    autoHighlight
                                    // options={preFilterDeptOptions}
                                    options={
                                      // filter out this.state.fsDepartmentsToShow from preFilterDeptOptions
                                      preFilterDeptOptions.filter(
                                        (dept: IDepartment) =>
                                          this.state.fsDepartmentsToShow.indexOf(
                                            dept.id
                                          ) === -1
                                      )
                                    }
                                    getOptionLabel={(department: IDepartment) =>
                                      department.siteName +
                                      ' ' +
                                      department.deptName
                                    }
                                    onChange={(
                                      event: any,
                                      department: IDepartment | string
                                    ) => {
                                      if (
                                        department &&
                                        typeof department !== 'string'
                                      ) {
                                        this.toggleDepartment(
                                          department.id,
                                          true
                                        );
                                        this.setState({
                                          departmentSearchKey:
                                            this.state.departmentSearchKey + 1,
                                        });
                                      }
                                    }}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        label="Search Department"
                                      />
                                    )}
                                  />
                                ) : null}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                          <br />
                          <div
                            style={{
                              color: 'gray',
                              fontSize: 12,
                              padding: 10,
                              textAlign: 'center',
                            }}
                          >
                            Note: Filtering has been moved
                            <br />
                            to the department name card.
                          </div>

                          <br />
                          {Browser ? (
                            Browser.name === 'chrome' ||
                            Browser.name === 'chromium-webview' ||
                            Browser.name === 'safari' ||
                            // Browser.name === "ios-webview" ||
                            Browser.name === 'edge-chromium' ||
                            Browser.name === 'firefox' ? (
                              // Browser.name === "ios" ||
                              // Browser.name === "crios"
                              <div
                                onClick={this.handleAutoZoom}
                                style={{ cursor: 'pointer' }}
                              >
                                <Checkbox
                                  classes={{
                                    root: classes.sortCheckbox,
                                    checked: classes.checked,
                                  }}
                                  checked={this.state.autoZoom}
                                />
                                Automatic Card Zoom
                              </div>
                            ) : null
                          ) : null}

                          <br />
                          {Browser ? (
                            (!this.state.autoZoom &&
                              (Browser.name === 'chrome' ||
                                Browser.name === 'firefox' ||
                                Browser.name === 'chromium-webview' ||
                                // Browser.name === "ios-webview" ||
                                // Browser.name === "ios" ||
                                // Browser.name === "crios" ||
                                Browser.name === 'edge-chromium')) ||
                            (Browser.name !== 'chrome' &&
                              Browser.name !== 'firefox' &&
                              Browser.name !== 'chromium-webview' &&
                              // Browser.name !== "ios-webview" &&
                              // Browser.name !== "ios" &&
                              // Browser.name !== "crios" &&
                              Browser.name !== 'edge-chromium') ? (
                              <div className={classes.zoomContainer}>
                                <TextField
                                  className={classes.zoomInput}
                                  type="number"
                                  label="Zoom %"
                                  value={this.state.zoom}
                                  onChange={this.handleZoom()}
                                  variant="outlined"
                                />
                                <Button
                                  onClick={this.handleZoomIncrement}
                                  className={classes.zoomButtons}
                                >
                                  +
                                </Button>
                                <Button
                                  onClick={this.handleZoomDecrement}
                                  className={classes.zoomButtons}
                                >
                                  -
                                </Button>
                              </div>
                            ) : null
                          ) : null}

                          <div
                            onClick={this.showConnectionInfo}
                            className={classes.drawerSupport}
                          >
                            Support Number: 385-422-2113
                          </div>
                        </div>
                      </Drawer>
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        open={this.state.showConnectionInfo}
                        onClose={this.hideConnectionInfo}
                        TransitionComponent={Fade}
                        ContentProps={{
                          'aria-describedby': 'message-id',
                        }}
                        message={
                          <span id="message-id">
                            <strong>USER:</strong>{' '}
                            {localStorage.getItem('email')}
                            <br />
                            {/* <strong>SOCKET:</strong> {REACT_APP_API_SOCKETURL}
                            <br /> */}
                            <strong>API:</strong> {REACT_APP_API_BASE_URL}
                            <br />
                            {/* @ts-ignore */}
                            <strong>BROWSER:</strong> {Browser.name}
                            <br />
                            {/* @ts-ignore */}
                            <strong>BROWSER VERSION:</strong> {Browser.version}
                            <br />
                            {/* @ts-ignore */}
                            <strong>BROWSER TYPE:</strong> {Browser.type}
                            <br />
                            {/* @ts-ignore */}
                            <strong>BROWSER OS:</strong> {Browser.os}
                            <br />
                            <strong>Is IOS: {isIos ? 'True' : 'False'}</strong>
                            <br />
                            <strong>
                              Is Mobile: {isMobile ? 'True' : 'False'}
                            </strong>
                            <br />
                            <strong>
                              Window orientation: {window.orientation}
                            </strong>
                            <br />
                            <div style={{ fontSize: 32 }}>
                              <strong>BUILD NUMBER:</strong>{' '}
                              <span onClick={() => window.location.reload()}>
                                <strong>{metaData.build}</strong>
                              </span>
                            </div>
                          </span>
                        }
                      />

                      <div style={{ height: 0 }}>
                        {/* @ts-ignore */}
                        {/* {console.log("BROWSER", Browser.name)} */}
                        <div
                          id="fsMainContainer"
                          style={
                            isIos
                              ? {
                                  // ios styling
                                  transform: this.state.zoom
                                    ? 'scale(' + this.state.zoom / 100 + ')'
                                    : 'scale(1)',
                                  transformOrigin: '0 0',
                                  // in iOS we have to use a loadedPageWidth because pinch zooming keeps changing window.innerWidth to a bad value
                                  width: loadedPageWidth
                                    ? loadedPageWidth / (this.state.zoom / 100)
                                    : '100%',
                                }
                              : this.state.autoZoom
                              ? {
                                  // Auto zooming
                                  transform: firefoxZoom
                                    ? 'scale(' + firefoxZoom + ')'
                                    : 'scale(1)',
                                  transformOrigin: '0 0',
                                  width: firefoxWidth ? firefoxWidth : '100%',
                                  maxHeight: '100vh',
                                }
                              : {
                                  // Manual Zooming
                                  transform: this.state.zoom
                                    ? 'scale(' + this.state.zoom / 100 + ')'
                                    : 'scale(1)',
                                  transformOrigin: '0 0',
                                  width:
                                    loadedPageWidth / (this.state.zoom / 100),
                                  maxWidth:
                                    loadedPageWidth / (this.state.zoom / 100),
                                }
                          }
                        >
                          <div
                            id="flowstation"
                            className="flowstationFlex"
                            style={{
                              // @ts-ignore
                              zoom:
                                Browser.name === 'ie'
                                  ? `${this.state.zoom}%`
                                  : '',
                            }}
                          >
                            {/* {console.log("state zoom", this.state.zoom)} */}
                            {/* zoom: {this.state.zoom} */}

                            {rooms}
                          </div>
                        </div>
                      </div>

                      <Joyride
                        run={this.state.startViewTour}
                        steps={this.state.viewTourSteps}
                        callback={this.handleViewTourCallback}
                        tooltipComponent={JoyrideTooltip}
                        disableScrolling
                        showSkipButton
                        hideBackButton
                        continuous
                        spotlightClicks
                        disableScrollParentFix
                        floaterProps={{ disableAnimation: true }}
                        styles={{
                          options: {
                            primaryColor: '#50B848',
                            zIndex: 2000,
                          },
                        }}
                      />

                      <Joyride
                        run={this.state.run}
                        steps={this.state.steps}
                        callback={this.handleJoyrideCallback}
                        tooltipComponent={JoyrideTooltip}
                        disableScrolling
                        disableOverlayClose
                        showSkipButton
                        hideBackButton
                        continuous
                        spotlightClicks
                        disableScrollParentFix
                        floaterProps={{ disableAnimation: true }}
                        styles={{
                          options: {
                            // arrowColor: "#e3ffeb",
                            // backgroundColor: "#e3ffeb",
                            // overlayColor: "rgba(79, 26, 0, 0.4)",
                            primaryColor: '#50B848',
                            // primaryColor: "#0595DA",
                            // textColor: "#004a14",
                            // width: 900,
                            zIndex: 2000,
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      {this.props.loggedInUserData.mongoOrganizationId ||
                      this.state.orgId ? (
                        <div>
                          {!groupId && !getQueryVariable('viewId') ? (
                            <Navigation
                              activeAlerts={filteredActiveAlerts}
                              serverTimeOffset={serverTimeOffset}
                              timeZone={timeZone}
                            />
                          ) : (
                            <div />
                          )}
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              textAlign: 'center',
                              transform: 'translate(-50%, -50%)',
                              WebkitTransform: 'translate(-50%, -50%)',
                            }}
                          >
                            <img
                              className="flashing"
                              src={loadingIcon}
                              style={{ width: '50%' }}
                              alt="#"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* {this.state.smallFsNotification ? <FsNotificationSnack successSnack={true} contentData={this.state.smallFsNotificationDetails} /> : null} */}
            </div>
          ) : (
            <div>
              <div
                style={{
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  textAlign: 'center',
                  transform: 'translate(-50%, -50%)',
                  WebkitTransform: 'translate(-50%, -50%)',
                }}
              >
                <img src={loadingIcon} style={{ height: 300 }} alt="#" />
                <br />
                <div style={{ marginBottom: 10, fontSize: '1.5em' }}>
                  Oops! An error has occured in the middle of fetching important
                  data, please check your internet connection and refresh your
                  page!
                </div>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.reloadPage}
                >
                  Refresh
                </Button>
              </div>
            </div>
          )}
        </div>
        {this.state.viewSavedSuccess ? (
          <SuccessSnack successSnack={true} successMessage="View updated!" />
        ) : null}
        {this.state.actionAddedSuccess ? (
          <SuccessSnack successSnack={true} successMessage="Action Added!" />
        ) : null}

        {this.state.highlightMemberLocation ? (
          <InfoSnack
            successSnack={true}
            successMessage={
              this.state.highlightMemberLocation.fullName +
              ': ' +
              this.state.highlightMemberLocation.lastDeviceLocationName
            }
          />
        ) : null}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  groupData: state.groupData.groupData,
  loggedInUserData: state.adminData.loggedInUserData,
  department: state.adminData.department,
  siblingDepts: state.groupData.siblingDepts,
  // staffLocations: state.groupData.staffLocations,
  site: state.adminData.site,
  errorLoggedInUser: state.adminData.errorLoggedInUser,
  organization: state.adminData.organization,
  organizationDepts: state.groupData.organizationDepts,
  rooms: state.adminData.rooms,
  distinctOrgActions: state.adminData.distinctOrgActions,
  organizations: state.adminData.organizations,
  sites: state.adminData.sites,
});

export default connect(mapStateToProps, {
  fetchGroup,
  updateGroup,
  fetchLoggedInUser,
  fetchDepartment,
  updateSiblingDepts,
  fetchSiblingDepts,
  fetchStaffLocations,
  logAction,
  logout,
  setDeptHistory,
  fetchVisibleFsDepartments,
  fetchAllFsDepartments,
  fetchRoomsByOrgId,
  fetchDistinctOrgActions,
  fetchSites,
  fetchOrganizations,
})(withStyles(styles)(Flowstation));
