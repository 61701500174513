import { useRef, useState } from 'react';

import { IOrganization, ISite } from '../../../interfaces/interfaces';

import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { Control, Controller, FieldErrors, FieldPath } from 'react-hook-form';

import { useOrganizations } from '../../../hooks/useOrganizations';
// import { useSites } from '../../../hooks/useSites';

export type TOrganizationOption = {
  label: string;
  value: string;
  organization: IOrganization;
};

interface TProps<TFieldValues extends Record<string, any>> {
  disabled?: boolean;
  onSelect?: (departmentOption: TOrganizationOption) => void;
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  errors?: FieldErrors<TFieldValues>;
  errorMessage?: string;
  clearOnSelect?: boolean;
  site?: ISite;
  onSelectAllOrganizations?: (departmentOptions: TOrganizationOption[]) => void;
  className?: string;
}

const OrganizationField = <TFieldValues extends Record<string, any>>(
  props: TProps<TFieldValues>
) => {
  const {
    onSelect = null,
    name,
    disabled,
    control,
    // errors = {},
    // errorMessage,
    clearOnSelect = false,
    site = null,
    onSelectAllOrganizations = null,
    className = '',
  } = props;

  const [siteSearchKey, setSiteSearchKey] = useState(0);

  const { organizations = [] } = useOrganizations();

  const inputRef = useRef<HTMLInputElement>(null);

  const organizationOptions: TOrganizationOption[] = organizations
    .filter(organization => organization?.isActive)
    .map(organization => {
      return {
        label: organization.orgName,
        value: organization?.id,
        organization: organization,
      };
    });

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disabled={disabled}
            key={siteSearchKey}
            id="organizationInput"
            autoHighlight
            className={`${className}`}
            options={organizationOptions}
            value={
              value
                ? organizationOptions.find(option => option.value === value) ||
                  null
                : null
            }
            getOptionLabel={(organization: TOrganizationOption) =>
              organization.label
            }
            onChange={(event: any, departmentOption: any) => {
              if (onSelect) {
                onSelect(departmentOption as TOrganizationOption);
              } else {
                onChange(departmentOption as TOrganizationOption);
              }

              if (clearOnSelect) {
                new Promise(resolve => {
                  setSiteSearchKey(siteSearchKey + 1);

                  resolve(null);
                }).then(() => {
                  // siteInputRef.focus();
                  inputRef.current?.focus();
                });
              }
            }}
            renderInput={params => (
              <TextField
                value={value}
                {...params}
                autoFocus
                ref={inputRef}
                label={disabled ? null : 'Select Organization'}
              />
            )}
          />
        )}
      />

      {/* {errors.isActive && <div style={{ color: 'red' }}>{errorMessage}</div>} */}

      {!isEmpty(onSelectAllOrganizations) && (
        <div>
          <Button
            disabled={isEmpty(site)}
            onClick={() => onSelectAllOrganizations(organizationOptions)}
            color="primary"
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            Select All
          </Button>
        </div>
      )}
    </div>
  );
};

export default OrganizationField;
