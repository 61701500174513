import { useRef, useState } from 'react';

import { IDepartment, ISite } from '../../../interfaces/interfaces';

import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { Control, Controller, FieldErrors, FieldPath } from 'react-hook-form';
import { useDepartments } from '../../../hooks/department/useDepartments';
import { useSites } from '../../../hooks/useSites';

export type TDepartmentOption = {
  label: string;
  value: string;
  department: IDepartment;
  site: ISite;
};

interface TProps<TFieldValues extends Record<string, any>> {
  disabled?: boolean;
  orgId: string;
  onSelect?: (departmentOption: TDepartmentOption) => void;
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  errors?: FieldErrors<TFieldValues>;
  errorMessage?: string;
  clearOnSelect?: boolean;
  site?: ISite;
  onSelectAllDepartments?: (departmentOptions: TDepartmentOption[]) => void;
  className?: string;
}

const DepartmentField = <TFieldValues extends Record<string, any>>(
  props: TProps<TFieldValues>
) => {
  const {
    disabled = false,
    orgId,
    onSelect = null,
    name,
    control,
    errors = {} as any,
    errorMessage,
    clearOnSelect = false,
    site = null,
    onSelectAllDepartments = null,
    className = '',
  } = props;

  const [siteSearchKey, setSiteSearchKey] = useState(0);

  const { sites = [] } = useSites(orgId);
  const { departments = [] } = useDepartments({ orgId });

  const inputRef = useRef<HTMLInputElement>(null);

  console.log('DepartmentField', { departments });

  const departmentOptions: TDepartmentOption[] = departments
    .filter(department => department?.isActive)
    .map(department => {
      const site = sites.find(site => site.id === department.mongoSiteId);
      return {
        label: `${site?.siteName || ''} ${department.deptName}`,
        value: department?.id,
        department: department,
        site: site || ({} as ISite),
      };
    });

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disabled={disabled}
            key={siteSearchKey}
            id="departmentInput"
            autoHighlight
            className={`${className}`}
            options={departmentOptions}
            value={
              value
                ? departmentOptions.find(option => option.value === value) ||
                  null
                : null
            }
            getOptionLabel={(department: TDepartmentOption) => department.label}
            onChange={(event: any, departmentOption: any) => {
              if (onSelect) {
                onSelect(departmentOption as TDepartmentOption);
              } else {
                onChange(departmentOption?.value);
              }

              if (clearOnSelect) {
                new Promise(resolve => {
                  setSiteSearchKey(siteSearchKey + 1);

                  resolve(null);
                }).then(() => {
                  // siteInputRef.focus();
                  inputRef.current?.focus();
                });
              }
            }}
            renderInput={params => (
              <TextField
                value={value}
                {...params}
                autoFocus
                ref={inputRef}
                
                label={disabled ? null : "Select Department"}
              />
            )}
          />
        )}
      />

      {errors.isActive && <div style={{ color: 'red' }}>{errorMessage}</div>}

      {!isEmpty(onSelectAllDepartments) && (
        <div>
          <Button
            disabled={isEmpty(site)}
            onClick={() => onSelectAllDepartments(departmentOptions)}
            color="primary"
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            Select All
          </Button>
        </div>
      )}
    </div>
  );
};

export default DepartmentField;
