import { FC, useState } from 'react';
import { useNotify } from '../../../../../../common/notify/useNotify';
import { useDevice } from '../../../../../../hooks/useDevice';
import {
  IDevice,
  IOrganization,
} from '../../../../../../interfaces/interfaces';
import DeviceLocationForm, { TFormSchema } from './DeviceLocationForm';
import { Button } from '@material-ui/core';

type TProps = {
  device: IDevice;
  organizations: IOrganization[];
};

const DeviceLocationContainer: FC<TProps> = props => {
  const { device } = props;

  const { updatePatch } = useDevice();
  const { notify } = useNotify();
  const [formDisabled, setFormDisabled] = useState(true);

  const onSubmit = async (values: TFormSchema) => {
    const { orgId, departmentId, roomId } = values;

    return updatePatch({
      deviceId: device.deviceId,
      data: {
        orgId,
        mongoDeptId: departmentId,
        mongoRoomId: roomId,
      },
    })
      .then(() => {
        notify({ message: `Device updated` });
        setFormDisabled(true);
      })
      .catch(() => {
        notify({ message: `Error updating device`, variant: 'error' });
      });
    // api call
    // reset - update the form values
  };

  return (
    <div className="w-full">
      {formDisabled ? (
        <div className="flex items-center justify-between">
          <div>
            <strong>Location:</strong>
            <br />
            {device?.deviceLocation}
          </div>
          <div>
            <Button onClick={() => setFormDisabled(false)} variant="outlined">
              Assign Room
            </Button>
          </div>
        </div>
      ) : (
        <DeviceLocationForm
          onSubmit={onSubmit}
          formDisabled={formDisabled}
          setFormDisabled={setFormDisabled}
          initialValues={{
            orgId: device.orgId,
            departmentId: device.mongoDeptId,
            roomId: device.mongoRoomId,
          }}
        />
      )}
    </div>
  );
};

export default DeviceLocationContainer;
