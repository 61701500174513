import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { z } from 'zod';
import DepartmentField from '../../../../../../common/fields/department/DepartmentField';

import { Button } from '@material-ui/core';
import OrganizationField from '../../../../../../common/fields/organization/OrganizationField';
import RoomField from '../../../../../../common/fields/room/RoomField';
import { schema } from './schema';

export type TFormSchema = z.infer<typeof schema>;

type TProps = {
  onSubmit: (values: TFormSchema) => Promise<any>;
  initialValues: any;
  formDisabled: boolean;
  setFormDisabled: (value: boolean) => void;
};

const DeviceLocationForm: FC<TProps> = props => {
  const { onSubmit, initialValues = {}, formDisabled, setFormDisabled } = props;

  const { handleSubmit, control, formState, watch, setValue, reset } =
    useForm<TFormSchema>({
      defaultValues: {
        ...initialValues,
      },
    });

  const errors = formState?.errors;
  const orgId = watch('orgId');
  const departmentId = watch('departmentId');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full">
        <div className="flex-1">
          <OrganizationField
            control={control}
            name={'orgId'}
            onSelect={option => {
              setValue('departmentId', '');
              setValue('roomId', '');
              setValue('orgId', option.value);
            }}
            errors={errors}
            disabled={formDisabled}
          />
        </div>
        <div className="flex-1">
          <DepartmentField
            orgId={orgId}
            onSelect={option => {
              setValue('roomId', '');
              setValue('departmentId', option.value);
            }}
            control={control}
            name={'departmentId'}
            errors={errors}
            disabled={formDisabled}
          />
        </div>
        <div className="flex-1">
          <RoomField
            orgId={orgId}
            departmentId={departmentId}
            control={control}
            name={'roomId'}
            errors={errors}
            disabled={formDisabled}
          />
        </div>

        <div className="mt-5 flex justify-end gap-2">
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              setFormDisabled(!formDisabled);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default DeviceLocationForm;
