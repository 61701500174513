import { useState, useCallback } from 'react';
import { API } from '../apiconfig';
import { checkRefreshToken } from '../utils/utils';
import axios from 'axios';
import { IDevice, IDeviceHistory } from '../interfaces/interfaces';

type TDeviceUpdatePatchInput = {
  deviceId: string;
  data: {
    [key: string]: any;
  };
};

interface DeviceHookResult {
  delete: (deviceId: string) => Promise<void>;
  update: (deviceData: any) => Promise<IDevice>;
  updatePatch: (input: TDeviceUpdatePatchInput) => Promise<IDevice>;
  updateFlowstationParameters: (
    deviceId: string,
    flowstationName: string,
    flowstationUrl: string
  ) => Promise<IDevice>;
  deviceHistory: (deviceId: string) => Promise<IDeviceHistory[]>;
  isLoading: boolean;
  error: Error | null;
  actionExecuted: boolean;
  actionExecutedMessage: string;
}

export const useDevice = (): DeviceHookResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [actionExecuted, setActionExecuted] = useState(false);
  const [actionExecutedMessage, setActionExecutedMessage] = useState('');

  const deleteDevice = async (deviceId: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    setActionExecuted(false);
    setActionExecutedMessage('');

    const payload = { deviceId };
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(API.REACT_APP_API_DELETEDEVICE, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('DELETE DEVICE', deviceId);
      console.log('RES!', { data });

      setActionExecuted(true);
      setActionExecutedMessage('Device Deleted');
    } catch (err) {
      console.log('err', err);
      setError(
        err instanceof Error ? err : new Error('An unknown error occurred')
      );
      if (err instanceof Error && err.message.includes('401')) {
        checkRefreshToken();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateDevice = async (deviceData: IDevice): Promise<IDevice> => {
    setIsLoading(true);
    setError(null);
    setActionExecuted(false);
    setActionExecutedMessage('');

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(API.REACT_APP_API_UPDATEDEVICE, {
        method: 'PUT',
        body: JSON.stringify(deviceData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('UPDATE DEVICE', deviceData.deviceId);
      console.log('RES!', { data });

      setActionExecuted(true);
      setActionExecutedMessage('Device Updated');

      // Fetch updated device details
      const URL = `${API.REACT_APP_API_GETDEVICEDETAIL}${deviceData.deviceId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        Pragma: 'no-cache',
      };

      const updatedDeviceResponse = await axios.get(URL, { headers });
      console.log('Updated device data:', updatedDeviceResponse.data.data);

      return updatedDeviceResponse.data.data;
    } catch (err) {
      console.log('Error updating device ', err);
      setError(
        err instanceof Error ? err : new Error('An unknown error occurred')
      );
      if (err instanceof Error && err.message.includes('401')) {
        checkRefreshToken();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateFlowstationParameters = async (
    deviceId: string,
    fsName: string,
    fsUrl: string
  ): Promise<IDevice> => {
    setIsLoading(true);
    setError(null);
    setActionExecuted(false);
    setActionExecutedMessage('');

    const token = localStorage.getItem('token');

    try {
      const url = `${API.REACT_APP_API_UPDATEDEVICE}/${encodeURIComponent(
        deviceId
      )}`;
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({ fsName, fsUrl }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('UPDATE FLOWSTATION PARAMETERS', deviceId);
      console.log('RES!', { data });

      setActionExecuted(true);
      setActionExecutedMessage('Flowstation Parameters Updated');

      // Fetch updated device details
      const URL = `${API.REACT_APP_API_GETDEVICEDETAIL}${deviceId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        Pragma: 'no-cache',
      };

      const updatedDeviceResponse = await axios.get(URL, { headers });
      console.log('Updated device data:', updatedDeviceResponse.data.data);

      return updatedDeviceResponse.data.data;
    } catch (err) {
      console.log('Error updating flowstation parameters ', err);
      setError(
        err instanceof Error ? err : new Error('An unknown error occurred')
      );
      if (err instanceof Error && err.message.includes('401')) {
        checkRefreshToken();
      }
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updatePatch = async (
    input: TDeviceUpdatePatchInput
  ): Promise<IDevice> => {
    setIsLoading(true);
    setError(null);
    setActionExecuted(false);
    setActionExecutedMessage('');

    const { deviceId, data: deviceUpdateData } = input;

    const token = localStorage.getItem('token');

    try {
      const url = `${API.REACT_APP_API_UPDATEDEVICE}/${encodeURIComponent(
        deviceId
      )}`;
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({ ...deviceUpdateData }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();

      setActionExecuted(true);
      setActionExecutedMessage('Flowstation Parameters Updated');

      // Fetch updated device details
      const URL = `${API.REACT_APP_API_GETDEVICEDETAIL}${deviceId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        Pragma: 'no-cache',
      };

      const updatedDeviceResponse = await axios.get(URL, { headers });
      console.log('Updated device data:', updatedDeviceResponse.data.data);

      return updatedDeviceResponse.data.data;
    } catch (err) {
      console.log('Error updating flowstation parameters ', err);
      setError(
        err instanceof Error ? err : new Error('An unknown error occurred')
      );
      if (err instanceof Error && err.message.includes('401')) {
        checkRefreshToken();
      }
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deviceHistory = useCallback(
    async (deviceId: string): Promise<IDeviceHistory[]> => {
      setIsLoading(true);
      setError(null);
      setActionExecuted(false);
      setActionExecutedMessage('');

      const token = localStorage.getItem('token');

      try {
        const url = `${API.REACT_APP_API_GETDEVICELOG}${encodeURIComponent(
          deviceId
        )}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('DEVICE HISTORY', deviceId);
        console.log('RES!', response.data.data);

        setActionExecuted(true);
        setActionExecutedMessage('Device History Fetched');

        return response.data.data as IDeviceHistory[];
      } catch (err) {
        console.log('Error fetching device history ', err);
        setError(
          err instanceof Error ? err : new Error('An unknown error occurred')
        );
        if (err instanceof Error && err.message.includes('401')) {
          checkRefreshToken();
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    delete: deleteDevice,
    update: updateDevice,
    updatePatch,
    updateFlowstationParameters,
    deviceHistory,
    isLoading,
    error,
    actionExecuted,
    actionExecutedMessage,
  };
};
