import { useRef, useState } from 'react';

import { IRoom, ISite } from '../../../interfaces/interfaces';

import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { Control, Controller, FieldErrors, FieldPath } from 'react-hook-form';

import { useRooms } from '../../../hooks/useRooms';

export type TRoomOption = {
  label: string;
  value: string;
  room: IRoom;
};

interface TProps<TFieldValues extends Record<string, any>> {
  disabled?: boolean;
  onSelect?: (roomOption: TRoomOption) => void;
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  errors?: FieldErrors<TFieldValues>;
  errorMessage?: string;
  clearOnSelect?: boolean;
  site?: ISite;
  onSelectAllRooms?: (roomOptions: TRoomOption[]) => void;
  className?: string;
  orgId: string;
  departmentId: string;
}

const RoomField = <TFieldValues extends Record<string, any>>(
  props: TProps<TFieldValues>
) => {
  const {
    onSelect = null,
    name,
    disabled,
    control,
    // errors = {},
    // errorMessage,
    clearOnSelect = false,
    site = null,
    onSelectAllRooms = null,
    className = '',
    orgId,
    departmentId,
  } = props;

  const [siteSearchKey, setSiteSearchKey] = useState(0);

  const { rooms = [] } = useRooms(orgId);

  const filteredRooms = rooms.filter(
    room => room?.mongoDeptId === departmentId
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const roomOptions: TRoomOption[] = filteredRooms
    .filter(room => room?.isActive)
    .map(room => {
      return {
        label: `${room.siteDeptName} ${room.roomName}`,
        value: room?.id,
        room,
      };
    });

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disabled={disabled}
            key={siteSearchKey}
            id="roomInput"
            autoHighlight
            className={`${className}`}
            options={roomOptions}
            value={
              value
                ? roomOptions.find(option => option.value === value) || null
                : null
            }
            getOptionLabel={(room: TRoomOption) => room.label}
            onChange={(event: any, roomOption: any) => {
              if (onSelect) {
                onSelect(roomOption as TRoomOption);
              } else {
                onChange(roomOption?.value);
              }

              if (clearOnSelect) {
                new Promise(resolve => {
                  setSiteSearchKey(siteSearchKey + 1);

                  resolve(null);
                }).then(() => {
                  // siteInputRef.focus();
                  inputRef.current?.focus();
                });
              }
            }}
            renderInput={params => (
              <TextField
                value={value}
                {...params}
                autoFocus
                ref={inputRef}
                label={disabled ? null : 'Select Room'}
              />
            )}
          />
        )}
      />

      {/* {errors.isActive && <div style={{ color: 'red' }}>{errorMessage}</div>} */}

      {!isEmpty(onSelectAllRooms) && (
        <div>
          <Button
            disabled={isEmpty(site)}
            onClick={() => onSelectAllRooms(roomOptions)}
            color="primary"
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            Select All
          </Button>
        </div>
      )}
    </div>
  );
};

export default RoomField;
