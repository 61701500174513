import { detect } from 'detect-browser';
import { FC, useEffect } from 'react';
import noSound from './sounds/static.mp3';

type TProps = {};

let blankSound = new Audio(noSound);

const BlankSound: FC<TProps> = props => {
  useEffect(() => {
    // Keeps browser awake to stay connected to signalr
    let keepBrowserAwake = () => {
      console.log('Static sound', 'playing!!');
      blankSound.volume = 0.5;

      let isIos = false;
      const Browser = detect();
      if (
        Browser?.name === 'ios' ||
        Browser?.name === 'crios' ||
        Browser?.name === 'ios-webview'
      ) {
        isIos = true;
      }

      if (isIos) {
        // console.log("Localhost or iOS, don't play sound")
        return;
      } else {
        let sound = blankSound.play();
        if (sound) {
          sound.catch(err =>
            console.log('Browser refused to play sound.', err)
          );
        }
      }
    };

    // Keeps browser awake to stay connected to signalr
    // let dontKeepBrowserAwake = () => {
    //   console.log('Static sound', 'not playing');
    // };

    let userEmail = localStorage.getItem('email');
    console.log('Static sound email found', userEmail);
    const secondsInterval = 20000;
    if (userEmail != null && userEmail === 'testemail@synctimes.com') {
      setInterval(keepBrowserAwake, 1000 * 60 * 60); // 1 hour
    } else {
      setInterval(keepBrowserAwake, secondsInterval);
    }
  }, []);

  return null;
};

export default BlankSound;
