import { isEmpty } from "lodash";
import React, { FC } from "react";

type TProps = {
  data: any;
};

const PrettyData: FC<TProps> = (props) => {
  const { data } = props;

  if (isEmpty(data)) return null;

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

export default PrettyData;
