import { makeStyles, Slider, withStyles } from "@material-ui/core";

export const CustomSlider = withStyles({
  root: {
    color: "#4FAC48",
    // height: 3,
    // padding: "13px 0",
  },
  // track: {
  // height: 4,
  //   borderRadius: 2,
  // },
  thumb: {
    // height: 20,
    // width: 20,
    backgroundColor: "#4FAC48",
    // border: "1px solid currentColor",
    // marginTop: -9,
    // marginLeft: -11,
    boxShadow: "#4FAC48 0 2px 2px",
    "&:focus, &:hover, &:active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: "#4FAC48",
  },
})(Slider);

export const styles: any = {
  timeStamp: {
    color: "gray",
  },
  textDetails: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  textField: {
    width: "100%",
  },
  autoMargin: {
    margin: "auto",
  },
  logButton: {
    marginBottom: 20,
  },
  confirmText: {
    fontSize: "1.5em",
    textAlign: "center",
  },
  displayInline: {
    display: "inline-block",
    cursor: "pointer",
    marginRight: 30,
    paddingBottom: 10,
  },
  actionButton: {
    cursor: "pointer",
    fontSize: 16,
  },
  executeButton: {
    marginTop: 10,
  },
  actionsListContainer: {
    marginBottom: 10,
  },
  deviceControlContainer: {
    justifyContent: "center",
    display: "block",
    textAlign: "center",
  },
  loading: {
    width: 600,
  },
  snackbar: {
    backgroundColor: "#4FAC48",
    color: "white",
  },
  wrapIcon: {
    color: "white",
    verticalAlign: "middle",
    display: "inline-flex",
  },
  deleteAction: {
    marginRight: 10,
    float: "left",
    color: "red",
    fontWeight: "bold",
    cursor: "pointer",
  },
  closeButton: {
    float: "right",
  },
  colorSwitchBase: {
    color: "#4FAC48",
    "&$colorChecked": {
      color: "#4FAC48",
      "& + $colorBar": {
        backgroundColor: "#4FAC48",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  submitButton: {
    backgroundColor: "#F0F1F9",
  },
  roomPopper: {
    position: "absolute",
    zIndex: 10,
    width: 350,
  },
  roomSelectOption: {
    cursor: "pointer",
    paddding: 10,
  },
  firstRoom: {
    // fontWeight: "bold"
  },
  checkBox: {
    color: "#50b848",
    "&$checked": {
      color: "#50b848",
    },
    "&:hover": {
      background: "#fff",
    },
  },
  checked: {
    "&:hover": {
      background: "#fff",
    },
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControl: {
    minWidth: 250,
    maxWidth: 250,
  },
  addIconSquare: {
    margin: ".2rem",
    textAlign: "center",
    cursor: "pointer",
    color: "#bdbdbd",
    border: "1px dashed #bdbdbd",
    height: 50,
    width: 50,
  },
  addIconStyle: {
    fontSize: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
};



export const useStyles = makeStyles(styles);