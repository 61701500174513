import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { TextField } from '../../../../../../common';
import DepartmentField from '../../../../../../common/fields/department/DepartmentField';
import { TDepartmentOption } from '../../../../../../interfaces/interfaces';
import { useStyles } from '../styles';
import { TFormSchema } from './schema';

type TProps = {
  formMethods: UseFormReturn<TFormSchema>;
  orgId: string;
  deviceId: string;
  updateFlowstationParameters: (fsName: string, fsUrl: string) => void;
};

const FlowStationUrlGeneratorForm: FC<TProps> = props => {
  const classes = useStyles();
  const { formMethods, orgId, updateFlowstationParameters } = props;

  // Commented out until we fix the useDevice hook
  // const { updateFlowstationParameters } = useDevice();

  const [showUrlGenerator, setShowUrlGenerator] = useState(false);
  const { handleSubmit, control, formState, watch, setValue } = formMethods;

  const errors = formState.errors;

  const baseUrl = `https://app.synctimes.com`;

  const organizationId = watch('organizationId');
  const flowStationToken = watch('flowStationToken');

  const onSelectDepartment = (option: TDepartmentOption) => {
    setValue(
      'fsUrl',
      `${baseUrl}?groupId=${option?.department.id}&flowStationToken=${flowStationToken}`
    );
  };

  useEffect(() => {
    setValue('organizationId', orgId);
  }, [orgId, setValue]);

  const onSubmit: SubmitHandler<TFormSchema> = async data => {
    updateFlowstationParameters(data.fsName, data.fsUrl);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <TextField
          control={control}
          required={false}
          label="Flowstation Name"
          margin="normal"
          name={'fsName'}
          errors={errors}
        />

        <TextField
          control={control}
          required={false}
          label="Flowstation URL"
          margin="normal"
          name={'fsUrl'}
          errors={errors}
        />

        <Button
          variant="contained"
          onClick={() => {
            setShowUrlGenerator(!showUrlGenerator);
          }}
        >
          GENERATE FS URL
        </Button>

        {showUrlGenerator && (
          <div className="w-full mt-5">
            <div className="flex justify-between items-center w-full">
              <div>Flowstation URL Generator</div>
              <div>
                <Button onClick={() => setShowUrlGenerator(false)}>
                  <CloseIcon />
                </Button>
              </div>
            </div>

            <div className="flex gap-5 items-end">
              <div className="w-1/2">
                <DepartmentField
                  orgId={organizationId}
                  name="departmentId"
                  control={control}
                  onSelect={onSelectDepartment}
                  errors={errors}
                  className="m-0 p-0"
                />
              </div>

              <div className="w-1/2">
                <TextField
                  control={control}
                  required={false}
                  label="Flowstation Token"
                  disabled={true}
                  margin="none"
                  name="flowStationToken"
                  errors={errors}
                  className="m-0 p-0"
                />
              </div>
            </div>
          </div>
        )}
        <div style={{ paddingTop: '10px' }}>
          <Button
            className={classes.submitButton}
            color="primary"
            type="submit"
          >
            Save Flowstation URL
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FlowStationUrlGeneratorForm;
