import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { API } from '../../../../apiconfig';

import { checkRefreshToken, getQueryVariable } from '../../../../utils/utils';

import AppChart from '../charting/AppChart';
import ConnectivityChart from '../charting/ConnectivityChart';
import WatchdogChart from '../charting/WatchdogChart';
import DeviceDetails from './details/DeviceDetails';

import ErrorSnack from '../../../../utils/ErrorSnack';
import SuccessSnack from '../../../../utils/snack/SuccessSnack';

import axios from 'axios';

// Material
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import StorageIcon from '@material-ui/icons/Storage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { useDevices } from '../../../../hooks/useDevices';
import { IApp, IDevice } from '../../../../interfaces/interfaces';
import SiteConnectivityChart from '../charting/SiteConnectivityChart';
import { ROWS_PER_PAGE_OPTIONS } from './constants';
import DeviceActions from './deviceActions/DeviceActions';
import { theme, useStyles } from './styles';
import { getAppGroups } from './utils';
// import { useAppContext } from '../../../../AppContext';

const _ = require('lodash');

type TProps = {};

const Devices: FC<TProps> = () => {
  const {
    devices: deviceList,
    refetch: refetchDevices,
    apps,
    actionTypes,
  } = useDevices();
  // const { disconnectedDevices = [], refetch: refetchDisconnectedDevices } =
  //   useDisconnectedDevices();

  
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const classes = useStyles();
  const [showSnoozedDevices, setShowSnoozedDevices] = useState(false);
  const [refreshedDevices, setRefreshedDevices] = useState(false);
  const [sortBy, setSortBy] = useState('minutesSinceLastConnection');
  const [filterString, setFilterString] = useState('');
  const [sortDescending, setSortDescending] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [viewDemoDevices, setViewDemoDevices] = useState(false);

  const [showFlowstations, setShowFlowstations] = useState(true);
  const [showExamRooms, setShowExamRooms] = useState(true);
  const [showDisconnectedDevices, setShowDisconnectedDevices] = useState(false);
  const [newDeviceCreated, setNewDeviceCreated] = useState(false);
  const [newDeviceCreatedError, setNewDeviceCreatedError] = useState(false);
  const [nonAndroidTablets, setNonAndroidTablets] = useState(false);
  const [newDeviceIsAndroidTablet, setNewDeviceIsAndroidTablet] =
    useState(true);
  const [showMissingAppDevices, setShowMissingAppDevices] = useState(false);
  const [showAllDevices, setShowAllDevices] = useState(false);
  // const [fileInput, setFileInput] = useState(null);
  // const fileInput = createRef()
  const [newDeviceDrawerOpen, setNewDeviceDrawerOpen] = useState(false);
  const [showMissingWatchDogDevices, setShowMissingWatchDogDevices] =
    useState(false);
  const [appToFilterTo, setAppToFilterTo] = useState('');
  const [watchdogAppToFilterTo, setWatchdogAppToFilterTo] = useState('');
  const [deviceToQuickEdit, setDeviceToQuickEdit] = useState('');
  const [updatedHostName, setUpdatedHostName] = useState('');
  const [updatedHostPort, setUpdatedHostPort] = useState('');
  const [updatedSwitchPort, setUpdatedSwitchPort] = useState('');
  const [deviceQuickUpdated, setDeviceQuickUpdated] = useState(false);
  const [invalidDevices, setInvalidDevices] = useState([]);
  const [showInvalidIgnoredDevices, setShowInvalidIgnoredDevices] =
    useState(false);
  const [paginationPage, setPaginationPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [fetchingPortMappings, setFetchingPortMappings] = useState(false);

  const newDeviceId = getQueryVariable('newDeviceId');

  const [newDeviceInput, setNewDeviceInput] = useState('');

  useEffect(() => {
    let urlDeviceId: string = '';
    let filterString: string = '';

    if (newDeviceId) {
      if (newDeviceId) {
        urlDeviceId = newDeviceId.toUpperCase();
        filterString = newDeviceId;
      }

      let token = localStorage.getItem('token');
      let URL = API.REACT_APP_API_ALLDEVICEDETAILS;
      let headers = {
        headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
      };

      axios
        .get(URL, headers)
        .then(response => {
          setFilterString(filterString);

          console.log('ALL devices', response.data);
          if (response.data.devices) {
            let deviceFound = false;
            response.data.devices.forEach((device: IDevice) => {
              if (device.deviceId === urlDeviceId) {
                console.log('Device exists', device);
                deviceFound = true;
              }
            });

            if (!deviceFound) {
              console.log('create new device automatically');
              let newDevice = {
                deviceId: urlDeviceId,
                isAndroidTablet: true,
              };

              fetch(API.REACT_APP_API_ADDDEVICE, {
                method: 'POST',
                body: JSON.stringify(newDevice),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + token,
                },
              })
                .then(res => {
                  res.json().then(data => {
                    console.log('NEW DEVICE DATA', data);
                    if (data.data === 'success') {
                      console.log('New Device Success', data);
                      setNewDeviceCreated(true);
                      setNewDeviceCreatedError(false);
                      setDrawerOpen(true);
                      setSelectedDeviceId(urlDeviceId);
                      setShowAllDevices(true);

                      window.history.pushState('Devices', '', '/admin?devices');
                      refetchDevices();
                    } else {
                      console.log('New Device Failed', data);
                      setNewDeviceCreated(false);
                      setNewDeviceCreatedError(true);
                    }
                  });
                })
                .catch(err => {
                  console.log('ERR', err);
                  if (err.response?.status === 401) {
                    checkRefreshToken();
                  }
                });
            } else {
              console.log('went here');
              setDrawerOpen(true);
              setSelectedDeviceId(urlDeviceId);

              window.history.pushState('Devices', '', '/admin?devices');
            }
          }
        })
        .catch(err => {
          console.log('Error: ', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
    // eslint-disable-next-line
  }, [newDeviceId]);

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      refetchDevices();
    }, 120000); // 120000 ms = 2 minutes

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, []);

  const improvedSearch = (devices: IDevice[], searchString: string) => {
    const searchTerms = searchString.toLowerCase().split(' ');
  
    return devices.filter((device: IDevice) => {
      const combinedFields = [
        device.macAddress,
        device.deviceLocation,
        device.deviceId
      ].filter(Boolean).join(' ').toLowerCase();
  
      return searchTerms.every(term => combinedFields.includes(term));
    });
  };

  const onHandleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterString(event.target.value);
  };

  const onOpenDrawer = (device: any) => {
    setDrawerOpen(true);
    setSelectedDeviceId(device.deviceId);
    setDeviceToQuickEdit('');
    setUpdatedHostName('');
    setUpdatedHostPort('');
    setUpdatedSwitchPort('');
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setNewDeviceDrawerOpen(false);
    setNewDeviceCreated(false);
    setNewDeviceCreatedError(false);

    // if (this.state.disconnectedDevices) {
    //   this.props.fetchAllDisconnectedDevices();
    // } else {
    //   this.props.fetchAllDeviceDetails();
    // }
    refetchDevices();
  };

  const onSortBy = (sortValue: string) => {
    if (sortValue === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortBy(sortValue);
      setSortDescending(false);
    }
  };

  const onHandleClose = () => {
    setRefreshedDevices(false);
  };

  const onToggleDisconnectedDevices = () => {
    refetchDevices();

    // clearInterval(this.interval);
    setShowDisconnectedDevices(!showDisconnectedDevices);
  };

  const onToggleAndroidDevices = () => {
    setNonAndroidTablets(!nonAndroidTablets);
  };

  const onHandleRefresh = () => {
    // this.state.disconnectedDevices ? this.props.fetchAllDisconnectedDevices() : this.props.fetchAllDeviceDetails();

    // if (showDisconnectedDevices) {
    // } else {
    refetchDevices();
    // }
  };

  const onRefreshPortMappings = () => {
    // make post request to REACT_APP_API_REFRESHAUTOMATICPORTMAPPINGS
    let token = localStorage.getItem('token');
    let URL = API.REACT_APP_API_REFRESHAUTOMATICPORTMAPPINGS;
    // let headers = { headers: { Authorization: "Bearer " + token, Pragma: "no-cache" } };

    setFetchingPortMappings(true);

    fetch(URL, {
      method: 'POST',
      // body: JSON.stringify(newDevice),
      body: null,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        console.log('res!', res);
        setFetchingPortMappings(false);
      })
      .catch(err => {
        console.log('Err in refresh port mappings', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });
  };

  const onFetchInvalidDevices = () => {
    let token = localStorage.getItem('token');
    let URL = API.REACT_APP_API_INVALIDDEVICES;
    let headers = {
      headers: { Authorization: 'Bearer ' + token, Pragma: 'no-cache' },
    };

    axios.get(URL, headers).then(res => {
      console.log('Invalid Devices', res.data.data);
      setInvalidDevices(res.data.data);
    });
  };

  const onNewDevice = () => {
    console.log('new device');

    setNewDeviceDrawerOpen(true);
    setNewDeviceCreated(false);
    setNewDeviceCreatedError(false);

    onFetchInvalidDevices();
  };

  const onHandleChange = (event: any) => {
    setNewDeviceInput(event.target.value);
  };

  const onSaveNewDevice = () => {
    console.log('save new device');
    let token = localStorage.getItem('token');

    let newDevice = {
      deviceId: newDeviceInput ? newDeviceInput : newDeviceId,
      isAndroidTablet: newDeviceIsAndroidTablet,
    };

    console.log('new device', newDevice);
    if (newDevice?.deviceId?.length > 0) {
      console.log('Create new device: ', newDeviceId);
      fetch(API.REACT_APP_API_ADDDEVICE, {
        method: 'POST',
        body: JSON.stringify(newDevice),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          res.json().then(data => {
            console.log('New device created', data);
            if (data.data === 'success') {
              // console.log("New Device Success", data);

              onFetchInvalidDevices();

              setNewDeviceCreated(true);
              setNewDeviceCreatedError(false);
              setDrawerOpen(true);
              setSelectedDeviceId(newDevice.deviceId.toUpperCase());
            } else {
              console.log('New Device Failed', data);
              setNewDeviceCreated(false);
              setNewDeviceCreatedError(true);
            }
          });
        })
        .catch(err => {
          console.log('ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const onSelectAppToFilterTo = (e: any) => {
    if (e.target.value) {
      console.log('here', e.target.value);
      setAppToFilterTo(e.target.value);
    }
  };

  const onSelectWatchdogAppToFilterTo = (e: any) => {
    if (e.target.value) {
      console.log('here', e.target.value);
      setWatchdogAppToFilterTo(e.target.value);
    }
  };

  const onToggleColumn = (columnToToggle: string) => {
    let deviceColumns = localStorage.getItem('deviceColumns');
    if (deviceColumns) {
      // Storage array exists. Modify it
      let columnsToModify = JSON.parse(
        localStorage.getItem('deviceColumns') || '[]'
      );
      if (columnsToModify.includes(columnToToggle)) {
        // remove it
        let updatedArr = _.pull(columnsToModify, columnToToggle);
        // console.log("Removed", updatedArr);
        localStorage.setItem('deviceColumns', JSON.stringify(updatedArr));
        forceUpdate();
      } else {
        // add it
        let updatedArr = [...columnsToModify, columnToToggle];
        // console.log("added", updatedArr);
        localStorage.setItem('deviceColumns', JSON.stringify(updatedArr));
        forceUpdate();
      }
    } else {
      // Storage array doesnt exist, create it
      let columnArr = JSON.stringify([columnToToggle]);
      localStorage.setItem('deviceColumns', columnArr);
      forceUpdate();
    }
  };

  const onUpdateDevice = (device: IDevice) => {
    let deviceToEdit = { ...device };

    // if (updatedHostName) {
    deviceToEdit.hostName = updatedHostName;
    // }

    // if (updatedHostPort) {
    deviceToEdit.hostPort = updatedHostPort;
    // }

    // if (updatedSwitchPort) {
    deviceToEdit.switchPort = updatedSwitchPort;
    // }

    // console.log("all updated!", deviceToEdit);

    let token = localStorage.getItem('token');

    fetch(API.REACT_APP_API_UPDATEDEVICE, {
      method: 'PUT',
      body: JSON.stringify(deviceToEdit),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log("Good", data);

        setDeviceQuickUpdated(true);
        refetchDevices();

        setTimeout(() => {
          setDeviceQuickUpdated(false);
        }, 2000);
      })
      .catch(err => {
        console.log('Error quick updating device', err);
        if (err.response?.status === 401) {
          checkRefreshToken();
        }
      });

    setDeviceToQuickEdit('');
  };

  const onToggleInvalidDevice = (invalidDevice: IDevice) => {
    // put request same endput we got when fetching invalid device
    let token = localStorage.getItem('token');
    let payload = { ...invalidDevice };
    payload.isIgnored = !payload.isIgnored;

    console.log('onToggleInvalidDevice payload', payload);
    fetch(API.REACT_APP_API_INVALIDDEVICES, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('Good!', data);
        refetchDevices();
        onFetchInvalidDevices();
      });
    // console.log("toggle", invalidDevice);
  };
  const onCreateMissingDevice = (invalidDevice: IDevice) => {
    // new device endpoint
    // console.log("create", invalidDevice);

    let token = localStorage.getItem('token');

    let newDevice = {
      deviceId: invalidDevice.deviceId,
      isAndroidTablet: invalidDevice.isAndroidTablet,
    };

    console.log('new device', newDevice);

    if (newDevice.deviceId.length > 0) {
      fetch(API.REACT_APP_API_ADDDEVICE, {
        method: 'POST',
        body: JSON.stringify(newDevice),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          res.json().then(data => {
            console.log('NEW DEVICE DATA', data);
            if (data.data === 'success') {
              console.log('New Device Success', data);

              refetchDevices();

              onFetchInvalidDevices();
              setDrawerOpen(true);
              setSelectedDeviceId(newDevice.deviceId);
            } else {
              console.log('New Device Failed', data);
              setNewDeviceCreated(false);
              setNewDeviceCreatedError(true);
            }
          });
        })
        .catch(err => {
          console.log('ERR', err);
          if (err.response?.status === 401) {
            checkRefreshToken();
          }
        });
    }
  };

  const onHandleChangePage = (event: any, newPage: number) => {
    setPaginationPage(newPage);
  };

  const onHandleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPaginationPage(0);
  };

  const onToggleSnooze = (device: IDevice) => {
    // check if device.snooze is true or false
    let token = localStorage.getItem('token');
    let payload = JSON.stringify({ ...device });
    // console.log("Toggle snooze for ", device)
    if (!device.isSnoozed) {
      // REACT_APP_API_SNOOZEDEVICE
      fetch(API.REACT_APP_API_SNOOZEDEVICE, {
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }).then(res => {
        // console.log("snoozed res!", res)
        refetchDevices();
      });
    } else {
      fetch(API.REACT_APP_API_UNSNOOZEDEVICE, {
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }).then(res => {
        // console.log("un snoozed res!", res)
        refetchDevices();
      });
    }
  };

  let columnsToView = JSON.parse(localStorage.getItem('deviceColumns') || '[]');

  let filteredDevices: IDevice[] = [];
  let allInstalledApps: IApp[] = [];
  let allWatchApps: IApp[] = [];

  if (deviceList) {
    const appGroupResponse = getAppGroups({
      deviceList,
      apps,
    });

    allInstalledApps = appGroupResponse.allInstalledApps;
    allWatchApps = appGroupResponse.allWatchApps;

    let filter = sortBy;
    // Sort by Select

    filteredDevices = deviceList.sort((a: any, b: any) => {
      if (sortDescending) {
        // @ts-ignore
        return (
          // @ts-ignore
          (a[filter] === null) - (b[filter] === null) ||
          -(a[filter] > b[filter]) ||
          +(a[filter] < b[filter])
        );

        // // DESCENDING
        // if (a[filter] > b[filter]) {
        //   return -1;
        // }
        // if (a[filter] < b[filter]) {
        //   return 1;
        // }
        // return 0;
      } else {
        // @ts-ignore
        return (
          // @ts-ignore
          (a[filter] === null) - (b[filter] === null) ||
          +(a[filter] > b[filter]) ||
          -(a[filter] < b[filter])
        );
        // // ASCENDING
        // if (a[filter] < b[filter]) {
        //   return -1;
        // }
        // if (a[filter] > b[filter]) {
        //   return 1;
        // }
        // return 0;
      }
    });

    // Filter out by search input
    // let keyword = filterString;
    // filteredDevices = deviceList.filter((obj: IDevice) => {
    //   let showObj = false;
    //   if (obj['macAddress']) {
    //     if (obj['macAddress'].toUpperCase().includes(keyword.toUpperCase())) {
    //       showObj = true;
    //     }
    //   }
    //   if (obj['deviceLocation']) {
    //     if (
    //       obj['deviceLocation'].toUpperCase().includes(keyword.toUpperCase())
    //     ) {
    //       showObj = true;
    //     }
    //   }

    //   if (obj['deviceId'] && obj['deviceLocation']) {
    //     if (
    //       obj['deviceLocation'].toUpperCase().includes(keyword.toUpperCase()) ||
    //       obj['deviceId'].toUpperCase().includes(keyword.toUpperCase())
    //     ) {
    //       showObj = true;
    //     }
    //   }

    //   if (obj['deviceId']) {
    //     if (obj['deviceId'].toUpperCase().includes(keyword.toUpperCase())) {
    //       showObj = true;
    //     }
    //   }

    //   return showObj;
    // });

    filteredDevices = improvedSearch(deviceList, filterString);

    if (!showAllDevices) {
      // Filter to specific app
      if (appToFilterTo) {
        let devices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.mongoLastLoggedAppId === appToFilterTo) {
            devices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = devices;
      }

      // Filter to specific watchdog app
      if (watchdogAppToFilterTo) {
        let devices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.mongoLastLoggedWatchdogAppId === watchdogAppToFilterTo) {
            devices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = devices;
      }

      if (viewDemoDevices) {
        // Filter to ONLY view demo devices
        let customerDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isCustomerDevice === false) {
            customerDevices.push(device);
          }
          return null;
        });
        filteredDevices = customerDevices;
      } else {
        // Filter to ONLY view customer devices
        let customerDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isCustomerDevice === true) {
            customerDevices.push(device);
          }
          return null;
        });
        filteredDevices = customerDevices;
      }

      if (nonAndroidTablets) {
        let devices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isAndroidTablet === false) {
            devices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = devices;
      } else {
        let devices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isAndroidTablet === true) {
            devices.push(device);
            return null;
          }
          return null;
        });
        // console.log("filtered", devices);
        filteredDevices = devices;
      }

      if (showExamRooms && !showFlowstations) {
        // console.log("show ex");
        let examRooms: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isFs === false) {
            examRooms.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = examRooms;
      } else if (!showExamRooms && showFlowstations) {
        // console.log("show fs");
        let flowstations: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isFs === true) {
            flowstations.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = flowstations;
      }

      if (showMissingAppDevices) {
        // console.log('show devices with old app')
        let missingAppDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.mongoAppId !== device.mongoLastLoggedAppId) {
            missingAppDevices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = missingAppDevices;
      }

      if (showMissingWatchDogDevices) {
        let missingWatchDogDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (
            device.mongoWatchdogAppId !== device.mongoLastLoggedWatchdogAppId
          ) {
            missingWatchDogDevices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = missingWatchDogDevices;
      }

      if (showDisconnectedDevices) {
        console.log('only show disconnected devices');
        let disconnectedDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (!device.isConnectedToHub) {
            disconnectedDevices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = disconnectedDevices;
      }

      if (showSnoozedDevices) {
        // console.log("only show snoozed devices");
        let snoozedDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (device.isSnoozed) {
            snoozedDevices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = snoozedDevices;
      } else {
        // console.log("dont show snoozed devices");
        let snoozedDevices: IDevice[] = [];
        filteredDevices.filter((device: IDevice) => {
          if (!device.isSnoozed) {
            snoozedDevices.push(device);
            return null;
          }
          return null;
        });
        filteredDevices = snoozedDevices;
      }
    }
  }

  // console.log("filtered devices", filteredDevices);
  // console.log("all installed", allInstalledApps);

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Typography variant="h4" gutterBottom component="h2">
          Room Devices
          <Tooltip
            title="Refresh Device List"
            aria-label="Refresh"
            onClick={() => onHandleRefresh()}
          >
            <Fab className={classes.refreshButton}>
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="New Device"
            aria-label="New Device"
            onClick={() => onNewDevice()}
          >
            <Fab className={classes.newDeviceButton}>
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="Refresh Port Mappings"
            aria-label="Refresh Port Mappings"
            onClick={() => onRefreshPortMappings()}
          >
            <Fab
              className={classes.refreshPortMappingsButton}
              disabled={fetchingPortMappings}
            >
              <StorageIcon />
            </Fab>
          </Tooltip>
          <CircularProgress
            className={classes.portMappingsProgress}
            style={{
              visibility: fetchingPortMappings ? 'visible' : 'hidden',
            }}
          />
        </Typography>

        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginRight: 25 }}>
            <TextField
              label="Search"
              value={filterString}
              className={classes.sortField}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onHandleFilter(event)
              }
            />
            <br />

            <div>
              <div
                onClick={() => {
                  setShowAllDevices(!showAllDevices);
                }}
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  marginTop: 10,
                }}
              >
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={showAllDevices}
                />
                Show all Devices
              </div>
            </div>

            {showAllDevices ? null : (
              <>
                <div>
                  <div
                    onClick={() => {
                      setViewDemoDevices(!viewDemoDevices);
                    }}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={viewDemoDevices}
                    />
                    Demo Devices
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => onToggleDisconnectedDevices()}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={showDisconnectedDevices}
                    />
                    Disconnected Devices
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => onToggleAndroidDevices()}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={nonAndroidTablets}
                    />
                    Non MDM Devices
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => {
                      setShowFlowstations(!showFlowstations);
                    }}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={showFlowstations}
                    />
                    Show Flowstations
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => {
                      setShowExamRooms(!showExamRooms);
                    }}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={showExamRooms}
                    />
                    Show Exam Rooms
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => {
                      setShowMissingAppDevices(!showMissingAppDevices);
                    }}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={showMissingAppDevices}
                    />
                    Missing Current App
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => {
                      setShowMissingWatchDogDevices(
                        !showMissingWatchDogDevices
                      );
                    }}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={showMissingWatchDogDevices}
                    />
                    Missing Current Watchdog
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => {
                      setShowSnoozedDevices(!showSnoozedDevices);
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      marginBottom: 10,
                    }}
                  >
                    <Checkbox
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      checked={showSnoozedDevices}
                    />
                    Snoozed Devices
                  </div>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <FormControl style={{ minWidth: '250px', maxWidth: '250px' }}>
                    <InputLabel>Current App</InputLabel>
                    <Select
                      value={appToFilterTo}
                      // onChange={handleChange}
                      onChange={e => onSelectAppToFilterTo(e)}
                    >
                      {allInstalledApps.map((app: IApp) => (
                        <MenuItem key={app.id} value={app.appVersion}>
                          {app.appVersion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    style={{ marginTop: 14 }}
                    onClick={() => setAppToFilterTo('')}
                  >
                    Clear
                  </Button>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <FormControl style={{ minWidth: '250px', maxWidth: '250px' }}>
                    <InputLabel>Current Watchdog App</InputLabel>
                    <Select
                      value={watchdogAppToFilterTo}
                      // onChange={handleChange}
                      onChange={e => onSelectWatchdogAppToFilterTo(e)}
                    >
                      {allWatchApps.map((app: IApp) => (
                        <MenuItem key={app.id} value={app.appVersion}>
                          {app.appVersion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    style={{ marginTop: 14 }}
                    onClick={() => setWatchdogAppToFilterTo('')}
                  >
                    Clear
                  </Button>
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 75 }}>
              <ConnectivityChart devices={filteredDevices} />
            </div>
            <SiteConnectivityChart devices={filteredDevices} />
            <AppChart devices={filteredDevices} />
            <WatchdogChart devices={filteredDevices} />
          </div>
        </div>

        {!nonAndroidTablets ? (
          <Accordion className={classes.groupActionExpansionPanel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Group Actions</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.globalActionsContainer}>
              <div className={classes.warningContainer}>
                <strong className={classes.warningText}>WARNING:</strong> This
                applies to all devices listed below.
              </div>

              <DeviceActions
                actionTypes={actionTypes}
                filteredDevices={filteredDevices}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}

        {/* Section to toggle column to view */}
        <br />
        <div
          onClick={() => onToggleColumn('Screenshot Time')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Screenshot Time')}
          />
          Screenshot Time
        </div>
        <div
          onClick={() => onToggleColumn('Last Image')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Last Image')}
          />
          Last Image
        </div>
        <div
          onClick={() => onToggleColumn('DeviceId')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('DeviceId')}
          />
          DeviceId
        </div>
        <div
          onClick={() => onToggleColumn('Has Current App')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Has Current App')}
          />
          Has Current App
        </div>
        <div
          onClick={() => onToggleColumn('Assigned App')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Assigned App')}
          />
          Assigned App
        </div>
        <div
          onClick={() => onToggleColumn('Current App')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Current App')}
          />
          Current App
        </div>
        <div
          onClick={() => onToggleColumn('Watchdog App')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Watchdog App')}
          />
          Watchdog App
        </div>
        <div
          onClick={() => onToggleColumn('Minutes Seen Last')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Minutes Seen Last')}
          />
          Minutes Seen Last
        </div>
        <div
          onClick={() => onToggleColumn('Host Name')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Host Name')}
          />
          Host Name
        </div>
        <div
          onClick={() => onToggleColumn('Host Port')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Host Port')}
          />
          Switch Brand
        </div>
        <div
          onClick={() => onToggleColumn('Switch Port')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Switch Port')}
          />
          Switch Port
        </div>
        <div
          onClick={() => onToggleColumn('Mac Address')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Mac Address')}
          />
          Mac Address
        </div>
        <div
          onClick={() => onToggleColumn('Is Using Wifi')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Is Using Wifi')}
          />
          Is Using Wifi
        </div>
        <div
          onClick={() => onToggleColumn('Is Gateway')}
          style={{ cursor: 'pointer', display: 'inline-block', marginTop: 10 }}
        >
          <Checkbox
            style={{ paddingTop: 0, paddingBottom: 0 }}
            classes={{
              root: classes.checkBox,
              checked: classes.checked,
            }}
            checked={columnsToView.includes('Is Gateway')}
          />
          Is Gateway
        </div>

        <Paper className={classes.root}>
          {/* @ts-ignore */}
          <TablePagination
            component="div"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            count={filteredDevices.length}
            page={paginationPage}
            rowsPerPage={rowsPerPage}
            onPageChange={onHandleChangePage}
            onRowsPerPageChange={onHandleChangeRowsPerPage}
          />
          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell style={{ width: 0 }}></TableCell>
                  {columnsToView.includes('Host Name') ||
                  columnsToView.includes('Host Port') ||
                  columnsToView.includes('Switch Port') ? (
                    <TableCell />
                  ) : null}
                  <TableCell
                    className={classes.sortSelect}
                    onClick={() => onSortBy('deviceLocation')}
                  >
                    {sortBy === 'deviceLocation' ? (
                      <div>
                        Device Location {sortDescending ? <>▼</> : <>▲</>}
                      </div>
                    ) : (
                      <div>Device Location</div>
                    )}
                  </TableCell>

                  {columnsToView.includes('Screenshot Time') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('minutesSinceLastScreenshot')}
                    >
                      {sortBy === 'minutesSinceLastScreenshot' ? (
                        <div>
                          Screenshot Time {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Screenshot Time</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Last Image') ? (
                    <TableCell className={classes.sortSelect}>
                      <div>Last Image</div>
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('DeviceId') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('deviceId')}
                    >
                      {sortBy === 'deviceId' ? (
                        <div>DeviceId {sortDescending ? <>▼</> : <>▲</>}</div>
                      ) : (
                        <div>DeviceId</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Has Current App') ? (
                    <TableCell className={classes.sortSelect}>
                      <div>Has Current App</div>
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Assigned App') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('mongoAppId')}
                    >
                      {sortBy === 'mongoAppId' ? (
                        <div>
                          Assigned App {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Assigned App</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Current App') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('mongoLastLoggedAppId')}
                    >
                      {sortBy === 'mongoLastLoggedAppId' ? (
                        <div>
                          Current App {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Current App</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Watchdog App') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('mongoLastLoggedWatchdogAppId')}
                    >
                      {sortBy === 'mongoLastLoggedWatchdogAppId' ? (
                        <div>
                          Watchdog App {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Watchdog App</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Minutes Seen Last') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('minutesSinceLastConnection')}
                    >
                      {sortBy === 'minutesSinceLastConnection' ? (
                        <div>
                          Minutes Seen Last {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Minutes Seen Last</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Host Name') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('hostName')}
                    >
                      {sortBy === 'hostName' ? (
                        <div>Host Name {sortDescending ? <>▼</> : <>▲</>}</div>
                      ) : (
                        <div>Host Name</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Host Port') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('hostPort')}
                    >
                      {sortBy === 'hostPort' ? (
                        <div>
                          Switch Brand {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Switch Brand</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Switch Port') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('switchPort')}
                    >
                      {sortBy === 'switchPort' ? (
                        <div>
                          Switch Port {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Switch Port</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Mac Address') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('macAddress')}
                    >
                      {sortBy === 'macAddress' ? (
                        <div>
                          Mac Address {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Mac Address</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Is Using Wifi') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('isUsingWifi')}
                    >
                      {sortBy === 'isUsingWifi' ? (
                        <div>
                          Is Using Wifi {sortDescending ? <>▼</> : <>▲</>}
                        </div>
                      ) : (
                        <div>Is Using Wifi</div>
                      )}
                    </TableCell>
                  ) : null}

                  {columnsToView.includes('Is Gateway') ? (
                    <TableCell
                      className={classes.sortSelect}
                      onClick={() => onSortBy('isGateway')}
                    >
                      {sortBy === 'isGateway' ? (
                        <div>Is Gateway {sortDescending ? <>▼</> : <>▲</>}</div>
                      ) : (
                        <div>Is Gateway</div>
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDevices
                  .slice(
                    paginationPage * rowsPerPage,
                    paginationPage * rowsPerPage + rowsPerPage
                  )
                  .map((device: IDevice) => (
                    <TableRow
                      key={device.deviceId}
                      className={classes.tableRow}
                      style={
                        // Set background color of device whether the minutes last seen is >10 or >20
                        device.minutesSinceLastConnection > 10 &&
                        device.minutesSinceLastConnection <= 20
                          ? { backgroundColor: '#ffffb2' }
                          : device.minutesSinceLastConnection > 20
                          ? { backgroundColor: '#ffb2b2' }
                          : {}
                      }
                    >
                      <TableCell
                        onClick={() => onToggleSnooze(device)}
                        style={{ paddingRight: 0, paddingLeft: 10 }}
                      >
                        {device.isSnoozed ? (
                          <Tooltip title="Un-Snooze Device">
                            <VisibilityOffIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Snooze Device">
                            <VisibilityIcon />
                          </Tooltip>
                        )}
                      </TableCell>
                      {columnsToView.includes('Host Name') ||
                      columnsToView.includes('Host Port') ||
                      columnsToView.includes('Switch Port') ? (
                        <TableCell>
                          {device.id === deviceToQuickEdit ? (
                            // <Button color="primary" variant="contained" onClick={() => this.updateDevice(device)}>
                            //   Save
                            // </Button>
                            <>
                              <SaveIcon
                                onClick={() => onUpdateDevice(device)}
                              />
                              <CancelIcon
                                onClick={() => {
                                  setDeviceToQuickEdit('');
                                  setUpdatedHostName('');
                                  setUpdatedHostPort('');
                                  setUpdatedSwitchPort('');
                                }}
                              />
                            </>
                          ) : (
                            <EditIcon
                              onClick={() => {
                                setDeviceToQuickEdit(device.id);
                                setUpdatedHostName(device.hostName);
                                setUpdatedHostPort(device.hostPort);
                                setUpdatedSwitchPort(device.switchPort);
                              }}
                            />
                          )}
                        </TableCell>
                      ) : null}

                      <TableCell
                        onClick={() => onOpenDrawer(device)}
                        className={classes.tableCell}
                      >
                        {device.deviceLocation}
                      </TableCell>

                      {columnsToView.includes('Screenshot Time') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.minutesSinceLastScreenshot !== null ? (
                            <div>
                              {device.minutesSinceLastScreenshot} Minutes Ago
                            </div>
                          ) : (
                            <div />
                          )}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Last Image') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.screenshotUrl !== null ? (
                            <img
                              className="smallImage"
                              src={device.screenshotUrl}
                              alt=""
                            />
                          ) : (
                            <div />
                          )}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('DeviceId') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.deviceId}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Has Current App') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.mongoAppId === device.mongoLastLoggedAppId ? (
                            <CheckIcon />
                          ) : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Assigned App') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.mongoAppId}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Current App') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.mongoLastLoggedAppId}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Watchdog App') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.mongoLastLoggedWatchdogAppId}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Minutes Seen Last') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.minutesSinceLastConnection}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Host Name') ? (
                        <TableCell className={classes.tableCell}>
                          {deviceToQuickEdit === device.id ? (
                            <input
                              value={updatedHostName}
                              onChange={e => {
                                console.log('e', e.target.value);
                                setUpdatedHostName(e.target.value);
                              }}
                            />
                          ) : (
                            <>{device.hostName}</>
                          )}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Host Port') ? (
                        <TableCell className={classes.tableCell}>
                          {/* <input
                            // label="Host Port"
                            value={device.hostPort}
                            // className={classes.sortField}
                            // onChange={this.handleFilter()}
                          /> */}
                          {deviceToQuickEdit === device.id ? (
                            <input
                              value={updatedHostPort}
                              onChange={e => {
                                // console.log("e", e.target.value);
                                setUpdatedHostPort(e.target.value);
                              }}
                            />
                          ) : (
                            <>{device.hostPort}</>
                          )}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Switch Port') ? (
                        <TableCell className={classes.tableCell}>
                          {/* <input
                            // label="Switch Port"
                            value={device.switchPort}
                            // className={classes.sortField}
                            // onChange={(e) => {
                            //   console.log("e", e.target.value);
                            // }}
                          /> */}
                          {deviceToQuickEdit === device.id ? (
                            <input
                              value={updatedSwitchPort}
                              onChange={e => {
                                console.log('e', e.target.value);
                                setUpdatedSwitchPort(e.target.value);
                              }}
                            />
                          ) : (
                            <>{device.switchPort}</>
                          )}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Mac Address') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.macAddress}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Is Using Wifi') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.isUsingWifi ? <CheckIcon /> : null}
                        </TableCell>
                      ) : null}

                      {columnsToView.includes('Is Gateway') ? (
                        <TableCell
                          onClick={() => onOpenDrawer(device)}
                          className={classes.tableCell}
                        >
                          {device.isGateway ? <CheckIcon /> : null}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          {/* <TablePagination
              component="div"
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              count={filteredDevices.length}
              page={paginationPage}
              rowsPerPage={rowsPerPage}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}
        </Paper>

        <Paper
          style={{ position: 'fixed', bottom: 0, right: 0, marginRight: 84 }}
        >
          <TablePagination
            component="div"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            count={filteredDevices.length}
            page={paginationPage}
            rowsPerPage={rowsPerPage}
            onPageChange={onHandleChangePage}
            onRowsPerPageChange={onHandleChangeRowsPerPage}
          />
        </Paper>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => onCloseDrawer()}
        >
          <div className={classes.drawer}>
            <DeviceDetails
              key={selectedDeviceId}
              deviceId={selectedDeviceId}
              onClose={() => onCloseDrawer()}
              actionTypes={actionTypes}
            />
          </div>
        </Drawer>
        <Drawer
          anchor="right"
          open={newDeviceDrawerOpen}
          onClose={() => onCloseDrawer()}
        >
          <div className={classes.drawer}>
            <Grid container>
              <Grid item xs={10}>
                <h2>New Device</h2>
              </Grid>
              <Grid item xs={2}>
                <h2
                  onClick={() => onCloseDrawer()}
                  className={classes.closeButton}
                  style={{ cursor: 'pointer' }}
                >
                  X
                </h2>
              </Grid>
            </Grid>

            <TextField
              required
              label="Device Id"
              className={classes.textField}
              value={newDeviceId}
              onChange={event => onHandleChange(event)}
              margin="normal"
            />

            <br />
            <div
              onClick={() => {
                setNewDeviceIsAndroidTablet(!newDeviceIsAndroidTablet);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Checkbox
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={newDeviceIsAndroidTablet}
              />
              Android or Raspi
            </div>

            <Button
              className={classes.saveNewDeviceButton}
              onClick={() => onSaveNewDevice()}
              color="primary"
              disabled={!newDeviceInput && !newDeviceId}
            >
              Create
            </Button>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h2 style={{ marginRight: 10 }}>Invalid Devices</h2>
              <Tooltip
                title="Refresh Invalid Devices"
                aria-label="Refresh"
                onClick={onFetchInvalidDevices}
              >
                <Fab style={{ backgroundColor: '#4FAC48' }}>
                  <RefreshIcon />
                </Fab>
              </Tooltip>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowInvalidIgnoredDevices(!showInvalidIgnoredDevices);
              }}
            >
              <Checkbox
                classes={{
                  root: classes.checkBox,
                  checked: classes.checked,
                }}
                checked={showInvalidIgnoredDevices}
              />
              Ignored Devices
            </div>

            {invalidDevices.map((invalidDevice: IDevice, i: number) => (
              <div key={i} style={{ marginLeft: 12, marginBottom: 10 }}>
                {invalidDevice.isIgnored === showInvalidIgnoredDevices ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    • {invalidDevice.deviceType} - {invalidDevice.deviceId} -{' '}
                    {invalidDevice.minutesLastSeen} mins
                    <div>
                      {invalidDevice.isIgnored ? (
                        <Button
                          style={{ marginLeft: 10 }}
                          variant="contained"
                          onClick={() => onToggleInvalidDevice(invalidDevice)}
                        >
                          Show Device
                        </Button>
                      ) : (
                        <Button
                          style={{ marginLeft: 10 }}
                          variant="contained"
                          onClick={() => onToggleInvalidDevice(invalidDevice)}
                        >
                          Ignore Device
                        </Button>
                      )}
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        variant="contained"
                        onClick={() => onCreateMissingDevice(invalidDevice)}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </Drawer>
        {deviceQuickUpdated ? (
          <SuccessSnack
            autoHideDuration={2000}
            successSnack={true}
            successMessage="Device Quick Updated"
          />
        ) : null}
        {newDeviceCreated ? (
          <SuccessSnack
            autoHideDuration={2000}
            successSnack={true}
            successMessage="New Device Created"
          />
        ) : null}
        {newDeviceCreatedError ? (
          <ErrorSnack errorSnack={true} errorMessage="Error Creating Device" />
        ) : null}
        <Snackbar
          className={classes.snackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={refreshedDevices}
          onClose={onHandleClose}
          autoHideDuration={2000}
          TransitionComponent={Fade}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Action Sent</span>}
        >
          <SnackbarContent
            className={classes.snackbar}
            message={
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                <InfoIcon />{' '}
                <span style={{ paddingLeft: 10 }}>Refreshing Devices</span>
              </Typography>
            }
          />
        </Snackbar>
      </MuiThemeProvider>
    </div>
  );
};

export default Devices;
